import React from 'react';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { FormFieldDateTimeWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepTwo.module.scss';

const RegisterAuditorFormStepTwo = () => (
  <FormLayout subtitle="1 of 3" title="Basics details">
    <span className={styles.titleQuestion}>
      {'Your must submit your full name as on your formal ID'}
    </span>
    <FormFieldWrapper
      required
      name="first_name"
      placeholder="First Name"
    />
    <FormFieldWrapper
      name="middle_name"
      placeholder="Middle Name"
    />
    <FormFieldWrapper
      required
      name="last_name"
      placeholder="Last Name"
    />
    <span className={styles.titleQuestion}>
      {'What\'s your date of birth?'}
    </span>
    <Form.Field width={16}>
      <FormFieldDateTimeWrapper
        className={styles.birthDateInput}
        format={DateTimeFormat.DAY_MONTH_YEAR}
        name="date_of_birth"
        time={false}
      />
    </Form.Field>
  </FormLayout>
);

export default RegisterAuditorFormStepTwo;
