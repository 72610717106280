import classnames from 'classnames';
import { Field, useField } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../constants';
import FieldStatus from '../../../fieldLogic/fieldStatus';
import timeDiffToString from '../../../utils/timeDiffToString';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage.component';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldDateTimeWrapper = ({
  dataCy,
  date,
  format,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  max,
  min,
  name,
  onChange,
  onStateHandler,
  readOnly,
  required,
  time,
  timeToStringFormat,
  valueStyle,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  let displayFormat = format;
  if (!displayFormat) {
    displayFormat = date && time
      ? DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H
      : date
        ? DateTimeFormat.DAY_MONTH_YEAR
        : DateTimeFormat.HOUR_MINUTES_24H;
  }

  const readOnlyField = () => (
    <span className={classnames({ [valueStyle]: valueStyle })}>
      {timeToStringFormat
        ? timeDiffToString(field.value, timeToStringFormat)
        : moment(field.value).format(displayFormat)
      }
    </span>
  );

  const editableField = () => (
    <Form.Field required={required} {...rest}>
      <Field
        as={DateTimePicker}
        data-cy={`${dataCyPrefix}-field`}
        date={date}
        format={displayFormat}
        includeTime={time}
        max={max}
        min={min}
        name={name}
        required={required}
        step={5}
        time={time}
        timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
        value={field.value ? new Date(field.value) : null}
        onChange={value => {
          const update = newValue => {
            helpers.setValue(newValue);
            if (onChange) {
              onChange(newValue);
            }
          };

          if (value === null) {
            return update(null);
          }
          if (!time) {
            const currentTimeZoneOffset = value.getTimezoneOffset() * 60 * 1000;
            const formattedDateValue = new Date(value - currentTimeZoneOffset);
            return update(formattedDateValue.toISOString());
          }

          return update(value.toISOString());
        }}
      />
      {meta.error ? (
        <FormikErrorMessage>
          {meta.error}
        </FormikErrorMessage>
      ) : null}
    </Form.Field>
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldDateTimeWrapper.defaultProps = {
  dataCy: null,
  date: true,
  format: null,
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  max: undefined,
  min: undefined,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  time: true,
  timeToStringFormat: null,
  valueStyle: null,
};

FormFieldDateTimeWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  date: PropTypes.bool,
  format: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  time: PropTypes.bool,
  timeToStringFormat: PropTypes.string,
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldDateTimeWrapper;
