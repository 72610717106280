import React from 'react';
import { useSelector } from 'react-redux';

import { featureFlagsSelector } from '../../../../../state/constants/selectors';
import { FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepNine.module.scss';

const RegisterAuditorFormStepNine = () => {
  const { termAddressMandatory } = useSelector(featureFlagsSelector);

  return (
    <FormLayout subtitle="2 of 3" title="Extra information">
      <span className={styles.titleQuestion}>
        {'What is your secondary address?'}
      </span>
      {termAddressMandatory ? (
        <>

          <span className={styles.titleQuestion}>
            {'For students, this is your term-time address. If you don’t have it, you can skip this screen'}
          </span>
          <span className={styles.note}>
            {'If you don\'t have it, you can skip this screen.'}
          </span>
        </>

      ) : (
        <>
          <span className={styles.titleQuestion}>
            {'For students, this is your term-time address.'}
          </span>
          <span className={styles.note}>
            {'If you do not have a secondary address, please re-enter your primary address'}
          </span>
        </>

      )}

      <FormFieldWrapper
        required
        name="term_address_1"
        placeholder="Address 1"
      />
      <FormFieldWrapper
        required
        name="term_address_2"
        placeholder="Address 2"
      />
      <FormFieldWrapper
        required
        name="term_city"
        placeholder="City"
      />
      <FormFieldWrapper
        required
        name="term_post_code"
        placeholder="Postcode/Eircode"
      />
      <FormFieldWrapper
        required
        name="term_county"
        placeholder="County"
      />
    </FormLayout>
  );
};

export default RegisterAuditorFormStepNine;
