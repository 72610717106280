import PropTypes from 'prop-types';
import React from 'react';

// import { Button } from 'semantic-ui-react';
import { errorAPI } from '../../../api';
import CustomError from '../../../utils/customError';
import Sentry from '../../../utils/sentry';
// import style from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    Sentry.captureException(error instanceof Error ? error : new CustomError(error));
    errorAPI.sendError(error, errorInfo);
  }

  render() {
    // const refreshPage = () => window.location.reload();
    // if (this.state.error) {
    //   return <div className={style.root} data-cy="error-boundary" >
    //     <div className={style.container}>
    //       <span className={style.iconError} data-cy={'error-boundary-icon'}/>
    //       <h1>
    //         {'OOPS!'}
    //       </h1>
    //       <h2>
    //         {'Something went wrong.'}
    //       </h2>
    //       <p>
    //         {'Try reloading the page now or try again later.'}
    //       </p>
    //       <Button primary onClick={refreshPage}>
    //         {'Reload'}
    //       </Button>
    //     </div>
    //   </div>;
    // }

    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  children: <></>,
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
