import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchEmailSettings = () => axios.get(
  '/ui/settings/emailSettings',
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching email settings'))
  .catch(logError);

export const updateEmailSettings = payload => axios.put(
  '/ui/settings/emailSettings',
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating email settings'))
  .catch(logError);
