/* eslint-disable max-len */
import './styles/index.scss';
import 'semantic-ui-less/semantic.less';
import 'react-widgets/lib/less/react-widgets.less';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-sortable-tree/style.css';

import { CaptureConsole, ExtraErrorData, HttpClient } from '@sentry/integrations';
import * as SentrySDK from '@sentry/react';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { AlertTemplate, NotificationTemplate, WaitPlaceholder } from './components/common';
import App from './components/layout/App/App.component';
import ErrorBoundary from './components/pages/ErrorBoundary/ErrorBoundary.component';
import { alertsConfig, notificationAlertsConfig, queryConfig } from './config';
import { ROOT_ELEMENT_ID } from './constants';
import { NotificationAlertContext } from './context';
import history from './history';
import persistence from './persistence';
import registerSW from './register-service-worker';
import routes from './routes/routes';
import { persistor, store } from './state/store';
import isMobile from './utils/isMobile';
import Sentry from './utils/sentry';

if (process.env.REACT_APP_SENTRY_ENVIRONMENT !== 'development') {
  const cookieStorage = persistence('cookieStorage');
  const routesPaths = Object.values(routes);

  SentrySDK.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}@${process.env.REACT_APP_HASH}`,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.05,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.3,
    integrations: [
      new SentrySDK.Replay({ maskAllText: true }),
      new SentrySDK.BrowserTracing({
        routingInstrumentation: SentrySDK.reactRouterV5Instrumentation(history, routesPaths, matchPath),
      }),
      new ExtraErrorData(),
      new CaptureConsole({ levels: ['error'] }),
      new HttpClient({
        failedRequestStatusCodes: [[400, 599]],
      }),
    ],
    sendDefaultPii: true,
    tracesSampleRate: 0.2,
    tunnel: '/ui/sentry',
    ignoreErrors: [/^Audit [a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12} because its status is [a-z]+$/],
  });

  const username = cookieStorage.getUsername();
  if (username) {
    Sentry.setUser({ id: username });
  }
}

const queryClient = new QueryClient(queryConfig);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<WaitPlaceholder/>} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AlertProvider template={AlertTemplate} {...alertsConfig}>
            <AlertProvider
              context={NotificationAlertContext}
              template={NotificationTemplate}
              {...notificationAlertsConfig}
            >
              <App />
            </AlertProvider>
          </AlertProvider>
          {window.Cypress ? null : <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById(ROOT_ELEMENT_ID), // Do not change 'root' id!
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

if (isMobile) {
  registerSW();
}
