import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Form, Icon, Image } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage.component';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldImageUploadWrapper = ({
  accept,
  dataCy,
  help,
  inline,
  label,
  labelTooltip,
  name,
  onStateHandler,
  readOnly,
  required,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);

  // eslint-disable-next-line no-unused-vars
  const [fileField, fileMeta, fileHelpers] = useField(`${name}_file`);

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const readOnlyField = () => (
    <Image data-cy={`${dataCyPrefix}-image`} size="small" src={field.value} />
  );

  const editableField = () => (
    field.value ? (
      <div>
        <Image data-cy={`${dataCyPrefix}-image`} size="small" src={field.value} />
        <Icon name="trash" onClick={() => {
          helpers.setValue(null);
          fileHelpers.setValue(null);
        }}
        />
      </div>
    ) : (
      <>
        <input
          ref={fileInputRef}
          hidden
          accept={accept}
          type="file"
          onChange={async e => {
            const file = e.target?.files?.[0] || null;
            if (file) {
              fileHelpers.setValue(file);
              setSelectedFile(file);
            } else {
              fileHelpers.setValue(null);
            }
          }}
        />
        <Form.Input
          readOnly
          action={
            <Button
              data-cy={`${dataCyPrefix}-button`}
              onClick={() => fileInputRef.current.click()}
            >
              {'Upload'}
            </Button>
          }
          data-cy={`${dataCyPrefix}-field`}
          label={inline ? null : label}
          placeholder="Select file..."
          value={selectedFile?.name}
        />
        {fileMeta.error ? (
          <FormikErrorMessage>
            {fileMeta.error}
          </FormikErrorMessage>
        ) : null}
      </>
    ));

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldImageUploadWrapper.defaultProps = {
  accept: 'image/*',
  dataCy: null,
  help: null,
  inline: false,
  labelTooltip: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
};

FormFieldImageUploadWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onStateHandler: PropTypes.func,
};

export default FormFieldImageUploadWrapper;
