import { Auth } from 'aws-amplify';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Dimmer, Divider, Form, Grid, Label, Loader } from 'semantic-ui-react';

import { entitiesAPI, errorAPI, multipartAPI, usersAPI } from '../../../../api';
import { CognitoConst, CognitoUserChallengeNames, Entity, UserRole } from '../../../../constants';
import getUserFieldStatus from '../../../../fieldLogic/user';
import history from '../../../../history';
import { useUserRole } from '../../../../hooks';
import { fetchUserInfo } from '../../../../state/constants/actionCreators';
import { enumOptionsSelector, featureFlagsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import {
  FormFieldCheckboxWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldImageUploadWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  HierarchicalTree,
} from '../../../common';
import { RoleChecker } from '../../../layout/Checkers';
import { userValidationSchema } from '../helpers';
import UserDetailModal from '../UsersDetailsModal/UserDetailsModal.component';
import UserMfaModal from '../UsersMfaModal/UserMfaModal.component';

const DATA_CY = 'user-details';

const statusOptionsSelector = state => enumOptionsSelector(state, 'status_type');
const userRoleOptionsSelector = state => enumOptionsSelector(state, 'user_role_type');

const UserDetails = ({
  data,
  submitFormRef,
  refetch,
}) => {
  const { entity, entityId } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [mfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaChecked, setMfaChecked] = useState(data.preferred_mfa === CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA);
  const [allowChangePassword, setAllowChangePassword] = useState(false);
  const alert = useAlert();
  const role = useUserRole();
  const entityDetails = { entity, entityId };
  const entityPath = pathname.split('/')[1];

  const featureFlags = useSelector(featureFlagsSelector);
  const statusOptions = useSelector(statusOptionsSelector);
  const userRoleOptions = useSelector(userRoleOptionsSelector);

  const fetchUserInfoCallback = useCallback(
    () => dispatch(fetchUserInfo({ role })),
    [dispatch, role],
  );

  const noAuditorUserRoleOptions = userRoleOptions.filter(item => item.value !== UserRole.AUDITOR);

  const mfaRoles = [
    UserRole.DATA,
    UserRole.CLIENT,
    ...(featureFlags.mfa4amcs ? [UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER] : []),
  ];

  const isOwnProfile = !entity;

  const isValidationEmailDomainEnabled = featureFlags.validationEmailRegexpEnabled;
  const validationEmailDomainRegExpString = featureFlags.validationEmailRegexpValue;

  const buildClientSearchOptions = clients => clients.map(client => ({
    text: client.name,
    value: client.id,
  }));

  const [filteredClients, setFilteredClients] = useState(data?.role === UserRole.CLIENT
    ? [{
      text: data.client_name,
      value: data.client_id,
    }]
    : []);

  const searchClients = wildcard => {
    entitiesAPI.fetchAll(Entity.CLIENTS, { name: wildcard })
      .then(clients => setFilteredClients(buildClientSearchOptions(clients.items)))
      .catch(error => {
        alert.error(`Error fetching clients: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const multiPartMutation = {
    account: {
      query: multipartAPI.update,
      entityId: 'me',
      entity: 'users',
    },
    users: {
      [entityId]: {
        query: multipartAPI.update,
        entityId,
        entity,
      },
      undefined: {
        query: multipartAPI.create,
        entityId,
        entity,
      },
    },
  };

  const mutationProps = multiPartMutation[entityPath][entityId] || multiPartMutation[entityPath];
  const {
    mutate: userMutation,
    isLoading: isUserMutationLoading,
  } = useMutation(mutationProps.query, {
    onSuccess: () => {
      alert.success(`User ${mutationProps.entityId ? 'updated' : 'created'}`);
      history.push(`/${entityPath}`);
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error ${mutationProps.entityId ? 'updating' : 'creating'} user: ${error.message}`);
    } });

  const {
    mutate: resetPasswordMutation,
    isLoading: isResetPasswordMutationLoading,
  } = useMutation(usersAPI.resetPassword, {
    onSuccess: () => alert.success('Password reset email sent to user'),
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error resetting user's password: ${error.message}`);
    },
  });

  const {
    mutate: validateEmailMutation,
    isLoading: isValidateEmailMutationLoading,
  } = useMutation(usersAPI.validateEmail, {
    onSuccess: () => {
      alert.success('User\'s email validated');
      if (refetch) refetch();
    },
    onError: error => {
      alert.error(`Error validating user's email: ${error.message}`);
      Sentry.captureException(new CustomError(error));
    },
  });

  const handleMFAOnChange = async checked => {
    if (checked) {
      setMfaModalOpen(true);
    } else {
      setMfaChecked(false);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, CognitoUserChallengeNames.NOMFA);
      fetchUserInfoCallback({ role });
    }
  };

  const onSubmit = values => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    userMutation({
      entity: mutationProps.entity,
      entityId: mutationProps.entityId,
      payload: formData,
    });
  };

  useEffect(() => {
    const handleDisplayCPButton = async () => {
      const { email } = data;
      const user = await Auth.currentAuthenticatedUser();
      setAllowChangePassword(user?.attributes?.email === email);
    };
    handleDisplayCPButton();
  }, [data]);

  return isUserMutationLoading || isResetPasswordMutationLoading || isValidateEmailMutationLoading ? (
    <>
      <Dimmer active inverted>
        <Loader size="large">
          {'Updating user'}
        </Loader>
      </Dimmer>
    </>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={data || {}}
        validationSchema={userValidationSchema(validationEmailDomainRegExpString, isValidationEmailDomainEnabled)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors, isValid, values }) => {
          const submit = () => {
            if (!isValid) {
              Object.entries(errors).forEach(([key, value]) => {
                alert.error(`${key}: ${value}`);
              });
            } else {
              handleSubmit();
            }
          };

          return (
            <Form noValidate data-cy={`${DATA_CY}-form`}>
              <button ref={submitFormRef} hidden type="submit" onClick={submit} />

              <FormFieldWrapper
                inline
                required
                help="A valid e-mail address. All e-mails from the system will be sent to this address. The e-mail address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by e-mail."
                label="Email Address"
                name="email"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <FormFieldSelectWrapper
                inline
                required
                label="Status"
                name="status"
                options={statusOptions}
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <RoleChecker
                allowedRoles={[UserRole.AREA_MANAGER, UserRole.CLIENT, UserRole.CLIENT_SERVICES, UserRole.DATA]}
              >
                <FormFieldSelectWrapper
                  inline
                  required
                  label="Role"
                  name="role"
                  options={values.role === UserRole.AUDITOR ? userRoleOptions : noAuditorUserRoleOptions}
                  readOnly={values.role === UserRole.AUDITOR}
                  onStateHandler={getUserFieldStatus(role, entityDetails)}
                />
              </RoleChecker>

              {values.role === UserRole.CLIENT && (
                <FormFieldDynamicSearchWrapper
                  inline
                  required
                  label="Client"
                  name="client_id"
                  options={filteredClients}
                  onSearchChange={searchClients}
                  onStateHandler={getUserFieldStatus(role, entityDetails)}
                />
              )}

              <FormFieldCheckboxWrapper
                inline
                toggle
                label="Notify user"
                name="notify_user"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              {values.role === UserRole.CLIENT && (
                <RoleChecker allowedRoles={[UserRole.DATA]}>
                  <FormFieldCheckboxWrapper
                    inline
                    toggle
                    label="Allow view archives"
                    name="allow_archive"
                    onStateHandler={getUserFieldStatus(role, entityDetails)}
                  />
                </RoleChecker>
              )}

              <Divider />

              <FormFieldWrapper
                inline
                required
                label="First Name"
                name="first_name"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <FormFieldWrapper
                inline
                label="Middle Name"
                name="middle_name"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <FormFieldWrapper
                inline
                required
                label="Last Name"
                name="last_name"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <FormFieldImageUploadWrapper
                inline
                label="Image"
                name="image"
                onStateHandler={getUserFieldStatus(role, entityDetails)}
              />

              <Divider />

              {data?.id && (
                <Form.Group>
                  <Form.Field width={3} />
                  <Form.Field width={9}>
                    <Grid>
                      {allowChangePassword && (
                        <Grid.Row>
                          <Button primary onClick={() => setModalOpen(true)}>
                            {'Change password'}
                          </Button>
                        </Grid.Row>
                      )}

                      {isOwnProfile && (
                        <RoleChecker allowedRoles={mfaRoles}>
                          <Divider />
                          <Grid.Row>
                            <Field
                              as={Form.Checkbox}
                              checked={mfaChecked}
                              label="Set Multi-Factor Authentication"
                              toggle={true}
                              onChange={(_e, { checked }) => handleMFAOnChange(checked)}
                            />
                          </Grid.Row>
                        </RoleChecker>
                      )}

                      {!isOwnProfile && data.preferred_mfa === CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA && (
                        <RoleChecker allowedRoles={[UserRole.DATA]}>
                          <Grid.Row>
                            <Label>
                              {'MFA set up'}
                            </Label>
                          </Grid.Row>
                        </RoleChecker>
                      )}

                      <RoleChecker allowedRoles={[UserRole.DATA]}>
                        <Grid.Row>
                          {data.cognito_user_status === CognitoConst.userStatus.FORCE_CHANGE_PASSWORD ? (
                            <Button secondary onClick={() => resetPasswordMutation(entityId)}>
                              {'Reset initial password'}
                            </Button>
                          ) : (
                            <Label>
                              {'First login done'}
                            </Label>
                          )}
                        </Grid.Row>
                      </RoleChecker>

                      <RoleChecker allowedRoles={[UserRole.DATA]}>
                        {data.cognito_user_status === CognitoConst.userStatus.CONFIRMED && (
                          <Grid.Row>
                            {data.cognito_email_verified ? (
                              <Label>
                                {'Email verified'}
                              </Label>
                            ) : (
                              <Button secondary onClick={() => validateEmailMutation(entityId)}>
                                {'Validate user\'s email'}
                              </Button>
                            )}
                          </Grid.Row>
                        )}
                      </RoleChecker>
                    </Grid>
                  </Form.Field>
                </Form.Group>
              )}
              {data?.role === UserRole.CLIENT ? (
                <>
                  <Divider />
                  <HierarchicalTree data={data?.client_org_level} id={data?.client_org_level_ids} />
                </>) : null}
            </Form>
          );
        }}
      </Formik>
      <UserDetailModal
        email={data.email}
        modalOpen={modalOpen}
        onAccept={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      />
      <UserMfaModal
        modalOpen={mfaModalOpen}
        setMfaChecked={setMfaChecked}
        onAccept={() => {
          fetchUserInfoCallback({ role });
          setMfaModalOpen(false);
        }}
        onCancel={() => setMfaModalOpen(false)}
      />
    </>
  );
};

UserDetails.defaultProps = {
  data: {},
  refetch: null,
};

UserDetails.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  data: PropTypes.object, // TODO: userType
  refetch: PropTypes.func,
};

export default UserDetails;
