import React from 'react';
import { Form } from 'semantic-ui-react';

import { FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSeven.module.scss';

const RegisterForm = () => (
  <FormLayout subtitle="2 of 2" title="Upload images">
    <span className={styles.titleQuestion}>
      {'A scanned copy of your ID and one selfie'}
    </span>
    <p className={styles.noteQuestion}>
      {'A scanned copy of the form of ID you usually carry. For example, a UK Driving Licence, UK Passport or a form of ID which is stamped with the PASS hologram.'}
    </p>
    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        required
        name="identification"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png, .gif'}
    </span>
    <p className={styles.noteQuestion}>
      {'The photo must be a head and shoulder shot in your normal clothes, straight to the camera, clear background, from a distance of no more than 1 meter and date-stamped.'}
    </p>
    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        required
        name="image"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png, .gif'}
    </span>
  </FormLayout>
);

export default RegisterForm;
