import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { entitiesAPI } from '../../../../../api';
import { EntityRevisionsContext } from '../../../../../context';
import CustomError from '../../../../../utils/customError';
import Sentry from '../../../../../utils/sentry';
import { SLTable, SLTableCellToggleFormatter } from '../../../../common';
import styles from './EntityRevision.module.scss';

const CellFormatter = ({ cell }) => {
  const { entity, entityId } = useParams();

  return (
    <>
      <Link className={styles.link} to={`/${entity}/${entityId}/revisions/${cell.row.original.id}`}>
        {`${moment(cell.row.original.created_at)}`}
      </Link>
      {' by '}
      <Link className={styles.link} to={`/users/${cell.row.original.user_id}`}>
        {cell.row.original.user_name}
      </Link>
    </>
  );
};

CellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

const columns = activateRevision => [
  {
    accessor: 'revision',
    label: 'Revision',
    Cell: CellFormatter,
    sortable: false,
  },
  {
    accessor: 'active',
    label: 'Active',
    Cell: SLTableCellToggleFormatter({ onToggle: activateRevision }),
    sortable: false,
  },
];

const EntityRevisions = ({ data, refetch }) => {
  const { entity, entityId } = useParams();
  const alert = useAlert();
  const {
    selectedRevisions,
    setSelectedRevisions,
  } = useContext(EntityRevisionsContext);

  const { mutate } = useMutation(entitiesAPI.activateRevision, {
    onSuccess: () => {
      alert.success('Revision activated');
      refetch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error activate revision: ${error.message}`);
    },
  });

  const activateRevision = (cell, checked) => {
    if (checked) {
      mutate({
        entity,
        entityId,
        revisionId: cell.row.original.id,
      });
    }
  };

  const onRowSelectionChange = rows => {
    if (!_isEqual(rows.map(row => row.id), selectedRevisions)) {
      setSelectedRevisions(rows.map(row => row.id));
    }
  };

  return (
    <SLTable
      rowSelector
      columns={columns(activateRevision)}
      data={data}
      dataCy={`${entity}-revisions`}
      onRowSelectionChange={onRowSelectionChange}
    />
  );
};

EntityRevisions.defaultProps = {
  data: [],
};

EntityRevisions.propTypes = {
  refetch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default EntityRevisions;
