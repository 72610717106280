import { call, put } from 'redux-saga/effects';

import { accountAPI, configCatAPI, entitiesAPI, errorAPI, promptsAPI } from '../../api';
import { Entity, UserRole } from '../../constants';
import history from '../../history';
import * as actions from './actionCreators';
import * as actionTypes from './actionTypes';

function* updateTaxonomySaga({ payload: { payload } }) {
  try {
    yield call(entitiesAPI.update, { entity: Entity.TAXONOMY, entityId: payload.id, payload });
    const updatedTaxonomy = yield call(entitiesAPI.fetchAll, Entity.TAXONOMY);
    yield put(actions.setTaxonomy(updatedTaxonomy));
    history.push(`/${Entity.TAXONOMY}`);
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

function* fetchTaxonomySaga() {
  try {
    const response = yield call(entitiesAPI.fetchAll, Entity.TAXONOMY);
    yield put(actions.setTaxonomy(response));
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

function* fetchEnumsSaga() {
  try {
    const response = yield call(entitiesAPI.fetchAll, 'enums');
    yield put(actions.setEnums(response));
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

function* fetchUserInfo({ payload: { role } }) {
  try {
    const userPrompts = yield call(promptsAPI.fetchUserPrompts);
    yield put(actions.setUserPrompts(userPrompts));
    const userInfo = role === UserRole.AUDITOR
      ? yield call(accountAPI.fetchAuditorAccount)
      : yield call(accountAPI.fetchUserAccount);
    yield put(actions.setUserInfo(userInfo));
    yield put(actions.setUserRole(role));
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

function* fetchConfigCat() {
  try {
    const response = yield call(configCatAPI.fetchConfigCatVars);
    yield put(actions.setConfigCat(response));
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

export default {
  [actionTypes.UPDATE_TAXONOMY]: updateTaxonomySaga,
  [actionTypes.FETCH_TAXONOMY]: fetchTaxonomySaga,
  [actionTypes.FETCH_ENUMS]: fetchEnumsSaga,
  [actionTypes.FETCH_USER_INFO]: fetchUserInfo,
  [actionTypes.FETCH_CONFIG_CAT]: fetchConfigCat,
};
