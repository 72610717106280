import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchReport = () => axios.get(
  '/ui/powerbi/reports/embed',
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching embedded report config'))
  .catch(logError);

export const exportReport = async ({ payload }) => {
  const { url } = await getBackendConfig();

  return axios({
    method: 'post',
    url: `${url}/api/v1/powerbi/reports/exports`,
    data: payload,
    headers: { Authorization: getAuthorization() },
  })
    .then(handlingResponse([StatusCodes.OK], 'Error downloading report export'))
    .catch(logError);
};

export const fetchUnassignedReports = async () => axios({
  method: 'get',
  url: '/ui/powerbi/reports',
  headers: { Authorization: getAuthorization() },
})
  .then(handlingResponse([StatusCodes.OK], 'Error getting unassigned powerbi reports'))
  .catch(logError);
