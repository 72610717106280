import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
// todo remove this when we activate termAddressMandatory
export const registerValidationSchemaNoTermAddressMandatory = [
  Yup.object().shape({
    accept_terms: Yup.boolean()
      .oneOf([true], 'You have to accept the Terms and Conditions of Use')
      .required('You have to accept the Terms and Conditions od Use'),
    email: Yup.string()
      .email('Invalid email')
      .required('Please enter your email'),
  }),
  Yup.object({
    date_of_birth: Yup.date()
      .typeError('Invalid date')
      .max(new Date(), 'Date of birth should be earlier than today')
      .required('Plese enter your date of birth'),
    first_name: Yup.string()
      .required('Please enter your first name'),
    last_name: Yup.string()
      .required('Please enter your last name'),
    middle_name: Yup.string(),
  }),
  Yup.object({
    nationality: Yup.string().trim().ensure().min(1, 'Required'),
    country: Yup.string().required('Required'),
    ethnicity: Yup.string(),
    ni_pps: Yup.string()
      .when('country', {
        is: 'Ireland',
        then: Yup.string()
          .matches(/^[0-9]{7}[a-zA-Z]{1}[a-zA-Z]?$/, {
            message: 'Incorrect format - Use a valid PPS Number',
            excludeEmptyString: false,
          })
          .required('Please enter your number'),
        otherwise: Yup.string()
          .matches(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/, {
            message: 'Incorrect format - Use a valid NI Number',
            excludeEmptyString: false,
          })
          .required('Required'),
      }),
    occupation: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({
    gender: Yup.string().trim().ensure().min(1, 'Required'),
    honorific: Yup.string().trim().ensure().min(1, 'Required'),
    marital_status: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({}),
  Yup.object().shape({
    passport_file: Yup.mixed().test('file', 'Please upload your passport', value => value?.name?.length > 0),
  }),
  Yup.object({
    identification_file: Yup.mixed().test('file', 'Please upload your current ID', value => value?.name?.length > 0),
    image_file: Yup.mixed().test('file', 'Please upload your photo', value => value?.name?.length > 0),
  }),
  Yup.object({
    visa_file: Yup.mixed(),
    visa_expiration: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.'),
  }),
  Yup.object({
    home_address_1: Yup.string().required('Please enter your address'),
    home_address_2: Yup.string(),
    home_city: Yup.string().required('Please enter your city'),
    home_county: Yup.string().required('Please enter your county'),
    home_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    term_address_1: Yup.string(),
    term_address_2: Yup.string(),
    term_city: Yup.string(),
    term_county: Yup.string(),
    term_post_code: Yup.string(),
  }),
  Yup.object({
    access_to_car: Yup.string().trim().ensure().min(1, 'Required'),
    email_communication: Yup.boolean(),
    home_number: Yup.string(),
    mobile_number: Yup.string()
      .required('Please enter your mobile number'),
    servelegal_community: Yup.boolean(),
  }),
  Yup.object({
    hear_about_us: Yup.string(),
    notes: Yup.string(),
    recommended_by: Yup.string(),
  }),
  Yup.object({
    about_yourself: Yup.string(),
    travel_outside: Yup.string(),
    why_servelegal: Yup.string(),
  }),
  Yup.object().shape({
    communications_consent: Yup.boolean(),
    facial_images_consent: Yup.boolean(),
    privacy_notice: Yup.boolean()
      .oneOf([true], 'You have to accept the Privacy Notice')
      .required('You have to accept the Privacy Notice'),
    special_category_consent: Yup.boolean(),
  }),
];

export const registerValidationSchema = [
  Yup.object().shape({
    accept_terms: Yup.boolean()
      .oneOf([true], 'You have to accept the Terms and Conditions of Use')
      .required('You have to accept the Terms and Conditions od Use'),
    email: Yup.string()
      .email('Invalid email')
      .required('Please enter your email'),
  }),
  Yup.object({
    date_of_birth: Yup.date()
      .typeError('Invalid date')
      .max(new Date(), 'Date of birth should be earlier than today')
      .required('Plese enter your date of birth'),
    first_name: Yup.string()
      .required('Please enter your first name'),
    last_name: Yup.string()
      .required('Please enter your last name'),
    middle_name: Yup.string(),
  }),
  Yup.object({
    nationality: Yup.string().trim().ensure().min(1, 'Required'),
    country: Yup.string().required('Required'),
    ethnicity: Yup.string(),
    ni_pps: Yup.string()
      .when('country', {
        is: 'Ireland',
        then: Yup.string()
          .matches(/^[0-9]{7}[a-zA-Z]{1}[a-zA-Z]?$/, {
            message: 'Incorrect format - Use a valid PPS Number',
            excludeEmptyString: false,
          })
          .required('Please enter your number'),
        otherwise: Yup.string()
          .matches(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/, {
            message: 'Incorrect format - Use a valid NI Number',
            excludeEmptyString: false,
          })
          .required('Required'),
      }),
    occupation: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({
    gender: Yup.string().trim().ensure().min(1, 'Required'),
    honorific: Yup.string().trim().ensure().min(1, 'Required'),
    marital_status: Yup.string().trim().ensure().min(1, 'Required'),
  }),
  Yup.object({}),
  Yup.object().shape({
    passport_file: Yup.mixed().test('file', 'Please upload your passport', value => value?.name?.length > 0),
  }),
  Yup.object({
    identification_file: Yup.mixed().test('file', 'Please upload your current ID', value => value?.name?.length > 0),
    image_file: Yup.mixed().test('file', 'Please upload your photo', value => value?.name?.length > 0),
  }),
  Yup.object({
    visa_file: Yup.mixed(),
    visa_expiration: Yup.date().typeError('Invalid date').min(new Date(), 'Visa cannot be expired.'),
  }),
  Yup.object({
    home_address_1: Yup.string().required('Please enter your address'),
    home_address_2: Yup.string(),
    home_city: Yup.string().required('Please enter your city'),
    home_county: Yup.string().required('Please enter your county'),
    home_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    term_address_1: Yup.string().required('Please enter your secondary address.  If you do not have a secondary address, please re-enter your primary address'),
    term_address_2: Yup.string(),
    term_city: Yup.string().required('Please enter your city'),
    term_county: Yup.string().required('Please enter your county'),
    term_post_code: Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Required'),
      }),
  }),
  Yup.object({
    access_to_car: Yup.string().trim().ensure().min(1, 'Required'),
    email_communication: Yup.boolean(),
    home_number: Yup.string(),
    mobile_number: Yup.string()
      .required('Please enter your mobile number'),
    servelegal_community: Yup.boolean(),
  }),
  Yup.object({
    hear_about_us: Yup.string(),
    notes: Yup.string(),
    recommended_by: Yup.string(),
  }),
  Yup.object({
    about_yourself: Yup.string(),
    travel_outside: Yup.string(),
    why_servelegal: Yup.string(),
  }),
  Yup.object().shape({
    communications_consent: Yup.boolean(),
    facial_images_consent: Yup.boolean(),
    privacy_notice: Yup.boolean()
      .oneOf([true], 'You have to accept the Privacy Notice')
      .required('You have to accept the Privacy Notice'),
    special_category_consent: Yup.boolean(),
  }),
];
