import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

// eslint-disable-next-line import/prefer-default-export
export const setStartCoordinatesAndDate = ({ auditId, payload }) => axios.post(
  `/ui/auditing/${auditId}/coordinates`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error sending audit start coordinates'))
  .catch(logError);

export const complete = async ({ auditId, payload, version = 'v4' }) => {
  const { url } = await getBackendConfig();

  return axios.post(
      `${url}/api/${version}/audits/${auditId}/complete`,
      payload,
      {
        headers: {
          Authorization: getAuthorization(),
          'Content-Type': 'multipart/form-data',
        },
      },
  )
    .then(handlingResponse([StatusCodes.OK], 'Error submitting audit'))
    .catch(logError);
};

export const formRecognizer = ({ auditId, questions, matchings, detections, action }) => axios.post(
    `/ui/auditing/${auditId}/receipt/recognizer`,
    { questions, matchings, detections, action },
    { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error sending audit to code validation'))
  .catch(err => logError(err));
