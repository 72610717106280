/* eslint-disable no-bitwise */
import classnames from 'classnames';
import getCurrencySymbol from 'currency-symbols';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { featureFlagsSelector } from '../../../../../state/constants/selectors';
import styles from './AuditsAccordionContent.module.scss';

const FinishedAuditsAccordion = ({ data }) => {
  const { expensesFieldsUpdate } = useSelector(featureFlagsSelector);
  const currency = getCurrencySymbol(data?.order_currency) || data?.order_currency;
  return (
    <>
      <div className={classnames(styles.row, { [styles.hidden]: !data.visit_info })}>
        {data.visit_info}
      </div>

      <Divider className={classnames({ [styles.hidden]: !data.visit_info })}/>
      <div className={classnames(styles.row, { [styles.hidden]: !data.site_post_code })}>
        <span className={styles.locationIcon}/>
        {' Location: '}
        {data.site_post_code}
      </div>

      <Divider className={classnames({ [styles.hidden]: !data.site_post_code })}/>
      <div
        className={classnames(styles.rowDouble, { [styles.row]: !data.deadline_date && !data.mileage })}
      >
        <div>
          <span className={styles.mileageIcon}/>
          {data.mileage}
        </div>
        <div className={classnames(styles.deadline, { [styles.hidden]: !data.date_of_visit })}>
          <span className={styles.deadlineIcon}/>
          {moment(data.date_of_visit).format(DateTimeFormat.DAY_MONTH_YEAR)}
        </div>
      </div>
      <Divider className={classnames({ [styles.hidden]: !data.deadline_date && !data.mileage })}/>

      {expensesFieldsUpdate
        ? (
          <>
            <div className={styles.row}>
              <div>
                <span className={styles.estimateIcon}/>
                {' Pays: '}
                <div className={styles.paysFinishedContainer}>
                  <div>
                    {' Pay: '}
                    {data.auditor_pay_per_audit}
                    {' '}
                    {currency}
                  </div>
                  <div>
                    {' Holiday Pay: '}
                    {data.holiday_pay}
                    {' '}
                    {currency}
                  </div>
                  <div>
                    {' Total Pay: '}
                    {data.total_pay}
                    {' '}
                    {currency}
                  </div>
                </div>
              </div>
              <div>
                <span className={styles.estimateIcon}/>
                {' Entrance Charge: '}
                {data.entrance_charge}
                {' '}
                {currency}
              </div>
            </div>
            <div className={classnames(
              styles.row,
              { [styles.row]: !data.travel_expenses && !data.reclaimable_expenses },
            )}
            >
              <div>
                <span className={styles.estimateIcon} />
                {' Travel Exp.: '}
                {data.travel_expenses}
                {' '}
                {currency}
              </div>
              <div>
                <span className={styles.estimateIcon} />
                {' Reclaimable Exp.: '}
                {
                  data.reclaimable_expenses + data.reclaimable_expenses_2
            + data.reclaimable_expenses_3 + data.reclaimable_expenses_4
                }
                {' '}
                {currency}
              </div>
            </div>
          </>
        )
        : (
          <>
            <div className={classnames(
              styles.rowDouble,
              { [styles.row]: !data.auditor_pay_per_audit && !data.entrance_charge },
            )}
            >
              <div>
                <span className={styles.estimateIcon}/>
                {' Pay: '}
                {data.auditor_pay_per_audit}
              </div>
              <div>
                <span className={styles.estimateIcon}/>
                {' Entrance Charge: '}
                {data.entrance_charge}
              </div>
            </div>
            <div className={classnames(
              styles.rowDouble,
              { [styles.row]: !data.travel_expenses && !data.reclaimable_expenses },
            )}
            >
              <div>
                <span className={styles.estimateIcon}/>
                {' Travel Exp.: '}
                {data.travel_expenses}
              </div>
              <div>
                <span className={styles.estimateIcon}/>
                {' Reclaimable Exp.: '}
                {
                  data.reclaimable_expenses + data.reclaimable_expenses_2
                + data.reclaimable_expenses_3 + data.reclaimable_expenses_4
                }
              </div>
            </div>
          </>
        )
      }
      <Divider />

      <div className={classnames(
        styles.rowDouble,
        { [styles.row]: !data.pay_date && !data.status },
      )}
      >
        <div>
          <span className={styles.deadlineIcon} />
          {' Pay date: '}
          {data.pay_date ? moment(data.pay_date).format(DateTimeFormat.DAY_MONTH_YEAR) : '-'}
        </div>
        <div>
          <span className={styles.iconList} />
          {' Status: '}
          {data.status}
        </div>
      </div>
    </>
  );
};

FinishedAuditsAccordion.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FinishedAuditsAccordion;
