import React, { useRef } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { automatedExportsAPI } from '../../../../api';
import history from '../../../../history';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import { LoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import ClientsAutomatedExportsDetails from './ClientsAutomatedExportsDetails/ClientsAutomatedExportsDetails.component';
import ClientsAutomatedExportsDetailsHeader from './ClientsAutomatedExportsDetailsHeader/ClientsAutomatedExportsDetailsHeader.component';

const DATA_CY = 'clients-automated-exports-details-view';

const ClientsAutomatedExportsDetailsView = () => {
  const { exportId } = useParams();
  const alert = useAlert();

  const submitFormRef = useRef(null);

  const { data } = useQuery(
    [{ exportId }],
    () => automatedExportsAPI.fetchOne({ exportId }),
    { enabled: Boolean(exportId) },
  );

  const { mutate: saveMutate } = useMutation(
    exportId ? automatedExportsAPI.update : automatedExportsAPI.create,
    {
      onSuccess: () => {
        alert.success(`Automated export ${exportId ? 'updated' : 'saved'}`);
        history.push('/clients/automated');
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error saving automated export: ${error.message}`);
      },
    },
  );

  const { mutate: deleteMutate } = useMutation(
    automatedExportsAPI.remove,
    {
      onSuccess: () => {
        alert.success('Automated export deleted');
        history.push('/clients/automated');
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error deleting automated export: ${error.message}`);
      },
    },
  );

  const handleSave = values => {
    saveMutate({
      exportId,
      payload: values,
    });
  };

  const handleDelete = () => {
    deleteMutate({
      exportId,
    });
  };

  return (
    <View
      secondary
      body={exportId && !data ? LoadingPlaceholder : ClientsAutomatedExportsDetails}
      bodyProps={{ data, submitFormRef, onSave: handleSave }}
      dataCy={DATA_CY}
      header={ClientsAutomatedExportsDetailsHeader}
      headerProps={{ submitFormRef, onDelete: handleDelete }}
    />
  );
};

export default ClientsAutomatedExportsDetailsView;
