import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { Entity } from '../../../../../../constants';
import { featureFlagsSelector } from '../../../../../../state/constants/selectors';
import { AuxiliaryPanel } from '../../../../../common';
import styles from './EntityExportDraggable.module.scss';

const EntityExportDraggable = ({ values, entity }) => {
  const { automaticFailLetterNotification } = useSelector(featureFlagsSelector);

  const getExportableColumns = exportValues => { // TODO: remove this when automaticFailLetterNotification feature flag is removed
    if (entity === Entity.ORDERS && !automaticFailLetterNotification) {
      const isExportable = column => ![
        'automatic_fail_letter_notification',
        'automatic_fail_letter_notification_email_body',
        'automatic_fail_letter_notification_email_subject',
      ].includes(column);
      return {
        ...exportValues,
        selected_columns: exportValues.selected_columns.filter(isExportable),
        available_columns: exportValues.available_columns.filter(isExportable),
      };
    }
    if (entity === Entity.AUDITS && !automaticFailLetterNotification) {
      return {
        ...exportValues,
        selected_columns: exportValues.selected_columns.filter(column => column !== 'automatic_fail_letter_notification_email_list'),
        available_columns: exportValues.available_columns.filter(column => column !== 'automatic_fail_letter_notification_email_list'),
      };
    }
    return exportValues;
  };

  const [exportColArr, setExportColArr] = useState(getExportableColumns(values).selected_columns);
  const [availableColArr, setAvailableColArr] = useState(getExportableColumns(values).available_columns
    .filter(item => !getExportableColumns(values).selected_columns.includes(item)));

  const removeFromArray = ({ arr, index }) => [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];

  const addToArray = ({ arr, index, newItem }) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

  const reorderArr = ({ arr, fromIndex, toIndex }) => {
    const newArr = [...arr];
    const element = arr[fromIndex];
    newArr.splice(fromIndex, 1);
    newArr.splice(toIndex, 0, element);
    return newArr;
  };

  const dragEnd = async result => {
    const { source, destination } = result;
    if (!source || !destination) {
      return;
    }
    const arrayFrom = source.droppableId === 'exportCol' ? exportColArr : availableColArr;
    const methodFrom = source.droppableId === 'exportCol' ? setExportColArr : setAvailableColArr;
    const itemToAdd = arrayFrom[source.index];
    const arrayTo = destination.droppableId === 'exportCol' ? exportColArr : availableColArr;
    const methodTo = destination.droppableId === 'exportCol' ? setExportColArr : setAvailableColArr;

    if (arrayFrom === arrayTo) {
      methodTo(reorderArr({
        arr: arrayFrom,
        fromIndex: source.index,
        toIndex: destination.index,
      }));
    } else {
      methodFrom(removeFromArray({
        arr: arrayFrom,
        index: source.index,
      }));
      methodTo(addToArray({
        arr: arrayTo,
        index: destination.index,
        newItem: itemToAdd,
      }));
    }
  };

  useEffect(() => {
    values.selected_columns = exportColArr;
  }, [exportColArr, values]);

  return (
    <>
      <div className={styles.columnsHeader}>
        {'Columns'}
      </div>
      <div className={styles.columns}>
        <DragDropContext onDragEnd={dragEnd}>
          <Card className={styles.columnExport}>
            <Card.Content extra>
              <Card.Header>
                {'Columns to export'}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Droppable droppableId="exportCol">
                {provided => (
                  <div {...provided.draggableProps} ref={provided.innerRef} className={styles.column}>
                    {exportColArr.map((item, index) => (
                      <Draggable
                        key={`key1${index}`}
                        draggableId={`d1${index}`}
                        index={index}
                      >
                        {draggableProvided => {
                          draggableProvided.draggableProps.style = { ...draggableProvided.draggableProps.style, ...{ position: 'relative !important' } };
                          return (
                            <div ref={draggableProvided.innerRef} className={styles.questionType}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <span className={styles.mileageIcon} />
                              {item}
                            </div>
                          );
                        }
                        }
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>)}
              </Droppable>
            </Card.Content>
          </Card>
          <AuxiliaryPanel
            className={styles.columnAvailable}
            content={
              <Droppable droppableId="selectedCol">
                {provided => (
                  <div {...provided.draggableProps} ref={provided.innerRef}>
                    {availableColArr.map((item, index) => (
                      <Draggable
                        key={`key2${index}`}
                        draggableId={`d2${index}`}
                        index={index}
                      >
                        {draggableProvided => {
                          draggableProvided.draggableProps.style = { ...draggableProvided.draggableProps.style, ...{ position: 'relative !important' } };
                          return (
                            <div ref={draggableProvided.innerRef} className={styles.questionType}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                            >
                              <span className={styles.mileageIcon} />
                              {item.split('_').join(' ')}
                            </div>
                          );
                        }
                        }
                      </Draggable>
                    ))
                    }
                    {provided.placeholder}
                  </div>)}
              </Droppable>}
            header={(
              <span>
                {'Available columns'}
              </span>
            )}
            name="available_columns"
          />
        </DragDropContext>
      </div>
    </>
  );
};

EntityExportDraggable.propTypes = {
  entity: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default EntityExportDraggable;
