import { ErrorMessage, FieldArray, Formik } from 'formik';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button, Form } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../api';
import { CustomEventType, Entity } from '../../../../../constants';
import { PatchesContext } from '../../../../../context';
import { useEventListener } from '../../../../../hooks';
import CustomError from '../../../../../utils/customError';
import Sentry from '../../../../../utils/sentry';
import { AuxiliaryPanel, EntitySearchBox, FormFieldWrapper, FormikErrorMessage, LoadingPlaceholder } from '../../../../common';
// import FormikFieldInvisibleWrapper from '../../../../common/FormikFieldInvisibleWrapper/FormikFieldInvisibleWrapper.component';
import { patchInitialValues, patchValidationSchema } from './helpers';
import styles from './PatchEditSidebar.module.scss';

const DATA_CY = 'patch-edit-sidebar';

const PatchEditSidebar = () => {
  const submitFormRef = useRef(null);
  const alert = useAlert();

  const {
    newPatchActive,
    selectedPatch,
    selectedPatchId,
    resetSelectedPatch,
  } = useContext(PatchesContext);

  const [/* coordinates */, setCoordinates] = useState(selectedPatch?.coordinates || []);

  const buildSearchOptions = users => users.map(user => ({
    text: [user.first_name, user.middle_name, user.last_name].join(' ').concat(` (${user.email})`),
    value: user.id,
  }));

  const mapPolygonCompleteListener = useCallback(e => {
    setCoordinates(e.detail.coordinates);
  }, []);

  useEventListener(
    window,
    CustomEventType.MAP_POLYGON_COMPLETE,
    mapPolygonCompleteListener,
  );

  const { mutate: createPatchMutate } = useMutation(entitiesAPI.create, {
    onSuccess: () => {
      alert.info('Patch created');
      resetSelectedPatch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error creating patch: ${error.message}`);
    },
  });

  const { mutate: updatePatchMutate } = useMutation(entitiesAPI.update, {
    onSuccess: () => {
      alert.info('Patch updated');
      resetSelectedPatch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error updating patch: ${error.message}`);
    },
  });

  const handlePatchSubmit = values => {
    const { gis_coordinates, ...payload } = values;
    if (newPatchActive) {
      createPatchMutate({ entity: Entity.PATCHES, payload });
    } else {
      updatePatchMutate({ entity: Entity.PATCHES, entityId: selectedPatchId, payload });
    }
  };

  const deleteSelectedShape = () => {
    const event = new CustomEvent(CustomEventType.MAP_POLYGON_REMOVE, {
      detail: {
        coordinates: [],
      },
    });
    setCoordinates([]);
    window.dispatchEvent(event);
  };

  const onCancel = () => {
    resetSelectedPatch();
    deleteSelectedShape();
  };

  return (
    <AuxiliaryPanel
      content={newPatchActive || selectedPatch
        ? (
          <Formik
            initialValues={newPatchActive ? patchInitialValues : selectedPatch}
            validationSchema={patchValidationSchema}
            onSubmit={handlePatchSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form noValidate data-cy={`${DATA_CY}-form`}>
                <button ref={submitFormRef} hidden type="submit" onClick={handleSubmit} />

                <FormFieldWrapper
                  required
                  className={styles.field}
                  label="Name"
                  name="name"
                />

                {/* TODO: be able to modify patch coordinates */}
                {/* <FormikFieldInvisibleWrapper
                  name="coordinates"
                  newValue={coordinates}
                />

                <Form.Field required>
                  <label>
                    {'Coordinates'}
                  </label>
                  <SLTable
                    columns={[
                      { accessor: 'lat', label: 'Lat' },
                      { accessor: 'lng', label: 'Lng' },
                    ]}
                    data={values.coordinates}
                    noDataMessage="No data"
                  />
                  <ErrorMessage
                    component={FormikErrorMessage}
                    name="coordinates"
                  />
                  <Button
                    fluid
                    className={styles.removeCoordinate}
                    onClick={() => deleteSelectedShape()}
                  >
                    {'Delete shape'}
                  </Button>
                </Form.Field> */}

                <Form.Field required>
                  <label>
                    {'Area Managers list'}
                  </label>
                  <FieldArray name="area_managers_ids">
                    {({ push, remove }) => (
                      <>
                        {values.area_managers_ids ? values.area_managers_ids.map((managerId, index) => {
                          const metadata = values.area_managers_metadata
                            ? values.area_managers_metadata.find(item => item.id === managerId)
                            : null;

                          return (
                            <div key={index} className={styles.row}>
                              <EntitySearchBox
                                buildSearchOptions={buildSearchOptions}
                                className={styles.search}
                                defaultValue={metadata
                                  ? { text: `${metadata.name} (${metadata.email})`, value: metadata.id }
                                  : null
                                }
                                entity={Entity.USERS}
                                extraFilters={{ role: 'area_manager' }}
                                label=""
                                name={`area_managers_ids.${index}`}
                                wildcardAttribute="name"
                              />
                              <span
                                className={styles.remove}
                                onClick={() => remove(index)}
                              />
                            </div>
                          );
                        }) : null}
                        <ErrorMessage
                          component={FormikErrorMessage}
                          name="area_managers_ids"
                        />
                        <br />
                        <Button
                          secondary
                          size="medium"
                          onClick={() => push()}
                        >
                          {'Add new Area Manager'}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Form.Field>
              </Form>
            )}
          </Formik>
        )
        : <LoadingPlaceholder />
      }
      dataCy={DATA_CY}
      footer={
        <div className={styles.button}>
          <Button
            primary
            type="submit"
            onClick={() => submitFormRef?.current?.click()}
          >
            {selectedPatch ? 'Save' : 'Create'}
          </Button>
          <Button onClick={onCancel}>
            {'Cancel'}
          </Button>
        </div>
      }
      header={
        <span>
          {selectedPatch ? 'Edit patch' : 'Create new patch' }
        </span>
      }
      showCollapse={false}
    />
  );
};

export default PatchEditSidebar;
