import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchDeviceToken = ({ id }) => axios.get(
  `/ui/notification/device/${id}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error getting firebase device token'))
  .catch(logError);

export const storeDeviceToken = ({ payload }) => axios.post(
  '/ui/notification/device',
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error storing firebase device token'))
  .catch(logError);

export const sendNotification = ({ payload }) => axios.post(
  '/ui/notification/sendMessage',
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error sending notification'))
  .catch(logError);
