import _pick from 'lodash/pick';
import _snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Dimmer, Dropdown, Icon, Loader, Menu } from 'semantic-ui-react';

import { errorAPI, exportAPI, exportAPIV3 } from '../../../../../../api';
import { featureFlagsSelector } from '../../../../../../state/constants/selectors';
import CustomError from '../../../../../../utils/customError';
import downloadFile from '../../../../../../utils/downloadFile';
import { parse } from '../../../../../../utils/queryString';
import Sentry from '../../../../../../utils/sentry';
import { LoadingPlaceholder } from '../../../../../common';
import EntityExportModal from '../EntityExportModal/EntityExportModal.component';
import styles from './EntityExportMenu.module.scss';

const EntityExportMenu = ({ canAddAndEdit }) => {
  const { entity } = useParams();
  const { search } = useLocation();
  const alert = useAlert();

  const { exportAuditsWithTokens } = useSelector(featureFlagsSelector);

  const [exportTemplatesList, setExportTemplatesList] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const DATA_CY = `${entity}-export-menu`;

  const [modalProps, setModalProps] = useState({ open: false, exportTemplateId: null });

  const isAuditExportWithTokensEnabled = entity === 'audits' && exportAuditsWithTokens;

  const fetchExportContent = async () => {
    const currentQuery = parse(search);
    const { page, sort, order, ...filterValues } = currentQuery;
    const exportFunction = isAuditExportWithTokensEnabled ? exportAPIV3 : exportAPI;
    return exportFunction.fetchContent(entity, currentTemplate.id, filterValues);
  };

  const handleModal = (open, exportTemplateId = null) => {
    setModalProps({ open, exportTemplateId });
  };

  const { data: exportTemplates, refetch: refetchExportTemplates } = useQuery(
    [entity],
    () => exportAPI.fetchAll(entity),
  );

  const { status } = useQuery(
    ['contentToCSV', currentTemplate],
    fetchExportContent,
    {
      enabled: !!currentTemplate,
      onSuccess: content => {
        alert.success(`Export ${currentTemplate?.name} finished`);
        return downloadFile(content, `${entity}_${_snakeCase(currentTemplate?.name)}_export.csv`);
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error exporting: ${error.message}`);
        return errorAPI.sendError(error.message, '');
      },
      onSettled: () => setCurrentTemplate(null),
    },
  );

  const { mutate: updateExportMutate } = useMutation(exportAPI.update, {
    onSuccess: () => {
      alert.success('Template updated');
      refetchExportTemplates();
    },
    onError: error => {
      alert.error(`Error updating template: ${error.message}`);
      errorAPI.sendError(error.message, '');
    },
  });

  const { mutate: copyExportMutate } = useMutation(exportAPI.create, {
    onSuccess: () => {
      alert.success('Template copy created');
      refetchExportTemplates();
    },
    onError: error => {
      alert.error(`Error copying template: ${error.message}`);
      errorAPI.sendError(error.message, '');
    },
  });

  const { mutate: createExportMutate } = useMutation(exportAPI.create, {
    onSuccess: data => {
      alert.success('Template created');
      refetchExportTemplates();
      handleModal(true, data.id);
    },
    onError: error => {
      alert.error(`Error creating template: ${error.message}`);
      errorAPI.sendError(error.message, '');
    },
  });

  const { mutate: deleteExportMutate } = useMutation(exportAPI.remove, {
    onSuccess: () => {
      alert.success('Template deleted');
      refetchExportTemplates();
    },
    onError: error => {
      alert.error(`Error deleting template: ${error.message}`);
      errorAPI.sendError(error.message, '');
    },
  });

  useEffect(() => {
    setExportTemplatesList(exportTemplates?.items || []);
  }, [exportTemplates]);

  const handleUpdateExport = (exportId, values) => {
    handleModal(false, null);
    updateExportMutate({
      entity,
      exportId,
      payload: _pick(values, ['name', 'selected_columns', 'is_editable', 'roles']),
    });
  };

  const handleDeleteExport = exportId => {
    handleModal(false, null);
    deleteExportMutate({ entity, exportId });
  };

  const handleCopyExport = originalExport => {
    handleModal(false, null);

    const payload = {
      name: `${originalExport.name} - copy`,
      selected_columns: originalExport.selected_columns,
      is_editable: originalExport.is_editable,
      roles: originalExport.roles,
    };

    copyExportMutate({ entity, payload });
  };

  const handleCreateExport = () => {
    const payload = {
      name: 'New template',
      selected_columns: ['internal_id'],
      is_editable: true,
      roles: [],
    };

    createExportMutate({ entity, payload });
  };

  return (
    status === 'loading'
      ? (<>
        <Dimmer active inverted >
          <Loader className={styles.exportLoaderContainer} size="large">
            {`Exporting  ${currentTemplate?.name}. This process may take a few minutes.`}
          </Loader>
        </Dimmer>
        <LoadingPlaceholder />
      </>)
      : (
        <>
          <Menu className={styles.exportMenu} data-cy={`${DATA_CY}-export-menu`}>
            <Dropdown
              className="link item"
              direction="left"
              text="Exports"
            >
              <Dropdown.Menu>
                {exportTemplatesList.map((template, index) => (
                  <Dropdown.Item key={template.id}>
                    <div className={styles.menuItem}>
                      <span
                        data-cy={`${DATA_CY}-export-menu-item-${index}-name`}
                        onClick={() => setCurrentTemplate(template)}
                      >
                        {template.name}
                      </span>
                      {canAddAndEdit ? (
                        <Icon
                          className={styles.menuItemIcon}
                          data-cy={`${DATA_CY}-export-menu-item-${index}-icon`}
                          name="edit"
                          onClick={() => handleModal(true, template.id)}
                        />
                      ) : null}
                    </div>
                  </Dropdown.Item>
                ))}
                {canAddAndEdit ? (
                  <Dropdown.Item key="new">
                    <div className={styles.menuItem}>
                      <span
                        data-cy={`${DATA_CY}-export-menu-item-new`}
                        onClick={handleCreateExport}
                      >
                        {'Add template'}
                      </span>
                    </div>
                  </Dropdown.Item>
                ) : null}
                {!canAddAndEdit && exportTemplatesList.length === 0 ? (
                  <Dropdown.Item key="empty">
                    <div className={styles.menuItem}>
                      {'No templates found'}
                    </div>
                  </Dropdown.Item>
                ) : null}
              </Dropdown.Menu>
            </Dropdown>
          </Menu>
          <EntityExportModal
            exportId={modalProps.exportTemplateId}
            open={modalProps.open}
            onCancel={() => handleModal(false, null)}
            onCopy={data => handleCopyExport(data)}
            onDelete={exportId => handleDeleteExport(exportId)}
            onSave={(exportId, values) => handleUpdateExport(exportId, values)}
          />
        </>
      )
  );
};

EntityExportMenu.propTypes = {
  canAddAndEdit: PropTypes.bool.isRequired,
};

export default EntityExportMenu;
