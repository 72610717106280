import { StatusCodes } from 'http-status-codes';

import { stringify } from '../utils/queryString';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

// todo: to be deleted
export const fetchAllv1 = (entity, queryObject = {}) => {
  let route = `/ui/v1/${entity}`;
  const query = stringify(queryObject);
  if (query.length > 0) {
    route = route.concat('?', query);
  }
  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() } },
  )
    .then(handlingResponse([StatusCodes.OK], `Error fetching all ${entity}`))
    .catch(logError);
};

export const fetchAll = async (entity, queryObject = {}) => {
  let route = `/ui/${entity}`;
  const query = stringify(queryObject);
  if (query.length > 0) {
    route = route.concat('?', query);
  }
  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() } },
  )
    .then(handlingResponse([StatusCodes.OK], `Error fetching all ${entity}`))
    .catch(logError);
};

export const fetchOne = ({ entity, entityId }) => axios.get(
  `/ui/${entity}/${entityId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} entity with id ${entityId}`))
  .catch(logError);

export const create = ({ entity, payload }) => axios.post(
  `/ui/${entity}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity} entity`))
  .catch(logError);

export const update = ({ entity, entityId, payload }) => axios.put(
  `/ui/${entity}/${entityId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating ${entity} entity with id ${entityId}`))
  .catch(logError);

export const patch = ({ entity, entityId, payload }) => axios.patch(
  `/ui/${entity}/${entityId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error patching ${entity} entity with id ${entityId}`))
  .catch(logError);

export const upload = (entity, payload) => axios.post(
  `/ui/${entity}/upload`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error importing ${entity}`))
  .catch(logError);

export const fetchTemplate = ({ entity, template }) => axios.get(
  `/ui/${entity}/templates/${template}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} template`))
  .catch(logError);

export const bulk = ({ entity, payload }) => axios.post(
  `/ui/${entity}/bulk`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error bulking ${entity}`))
  .catch(logError);

export const fetchAllRevisions = ({ entity, entityId }) => axios.get(
  `/ui/${entity}/${entityId}/revisions`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} revisions`))
  .catch(logError);

export const fetchOneRevision = ({ entity, entityId, revisionId }) => axios.get(
  `/ui/${entity}/${entityId}/revisions/${revisionId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} entity revision with id ${revisionId}`))
  .catch(logError);

export const activateRevision = ({ entity, entityId, revisionId }) => axios.put(
  `/ui/${entity}/${entityId}/revisions/${revisionId}/activate`,
  {},
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error activate ${entity} entity revision with id ${revisionId}`))
  .catch(logError);

export const clone = ({ entity, entityId, payload }) => axios
  .post(
    `/ui/${entity}/${entityId}/clone`,
    payload,
    { headers: { Authorization: getAuthorization() } },
  )
  .then(handlingResponse([StatusCodes.OK], `Error cloning ${entity} with id ${entityId}`))
  .catch(logError);
