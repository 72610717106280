import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Form, Icon, List, Loader } from 'semantic-ui-react';

import { multipartAPI } from '../../../../api';
import history from '../../../../history';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import { FileUpload, LoadingPlaceholder } from '../../../common';
import styles from './PatchesUpload.module.scss';

const DATA_CY = 'patches-upload';

const PatchesUpload = () => {
  const { entity } = useParams();
  const alert = useAlert();
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const handleFileSelect = file => {
    setFileInfo(file);
  };

  const {
    mutate: uploadMutate,
    status: uploadMutateStatus,
  } = useMutation(multipartAPI.upload, {
    onSuccess: response => {
      if (response.failed.length === 0) {
        alert.info('Patches successfully uploaded!');
        history.push('/patches');
      } else {
        alert.warning('Error uploading some patches!');
        setUploadResponse(response);
      }
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error uploading file: ${error.message}`);
    },
  });

  const handleSubmit = () => {
    setUploadResponse(null);

    if (fileInfo) {
      const formData = new FormData();
      formData.append('file', fileInfo);

      uploadMutate({
        entity,
        payload: formData,
      });
    }
  };

  return (
    uploadMutateStatus === 'loading' ? (
      <>
        <Dimmer active inverted>
          <Loader size="large">
            {`Uploading ${entity}`}
          </Loader>
        </Dimmer>
        <LoadingPlaceholder />
      </>
    ) : (
      <div className={styles.root} data-cy={DATA_CY}>
        <Form>
          <Form.Field width={8}>
            <FileUpload
              required
              accept=".kml"
              dataCy={`${DATA_CY}-file-input`}
              label="KML File"
              onChange={handleFileSelect}
            />
          </Form.Field>

          <div>
            {`Remember that you have to manually assign patches to the geometries by adding an '<ExtendedData>'
            element, as in the example :`}
            <pre>
              {`
                <Placemark>
                  <name>ZE</name>
                  <ExtendedData>
                    <Data name="patch">
                      <value>PATCH NAME</value>
                    </Data>
                  </ExtendedData>
                  <MultiGeometry>
                    ...
                  </MultiGeometry>
                </Placemark>
              `}
            </pre>
          </div>

          {fileInfo ? (
            <>
              <Button type="submit" onClick={handleSubmit}>
                {'Upload'}
              </Button>
              <span className={styles.warning}>
                <Icon name="warning sign"/>
                <span>
                  {'This will remove all the existing patches!'}
                </span>
              </span>
            </>
          ) : null}

          {/* TODO: refactor UploadResult to be able to use it here */}
          {uploadResponse ? (
            <div>
              <>
                {uploadResponse.success?.length === 0 ? null : (
                  <div className={styles.responseFirst}>
                    <Icon className={styles.success} name="checkmark"/>
                    {`${uploadResponse.success?.length} patches imported succesfully.`}
                  </div>
                )}
                {uploadResponse.failed?.length === 0 ? null : (
                  <div className={styles.responseSecond}>
                    <Icon className={styles.error} name="warning" />
                    {`${uploadResponse.failed?.length} patches failed while importing.`}
                    <List bulleted>
                      {uploadResponse.failed.map((row, index) => (
                        <List.Item key={index}>
                          {`Patch ${row.name} - ${row.error}`}
                        </List.Item>
                      ))}
                    </List>
                  </div>
                )}
              </>
            </div>
          ) : null}
        </Form>
      </div>)
  );
};

export default PatchesUpload;
