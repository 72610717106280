import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, Label } from 'semantic-ui-react';

import { AuditStatus, QuestionType, UserRole } from '../../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../../context';
import FieldStatus from '../../../../../../../../fieldLogic/fieldStatus';
import { featureFlagsSelector } from '../../../../../../../../state/constants/selectors';
import { ExternalLink, FormFieldCheckboxWrapper, FormFieldSelectWrapper } from '../../../../../../../common';
import { RoleChecker } from '../../../../../../../layout';
import { questionType } from '../../../../../../../types';
import SurveyQuestionConfigHeader from '../SurveyQuestionConfigHeader/SurveyQuestionConfigHeader.component';
import styles from './SurveyTextQuestion.module.scss';

const DATA_CY = 'survey-text-question';

const SurveyTextQuestion = ({
  answer,
  auditScreen,
  data,
  index,
  onChange,
  status,
  surveyVisibility,
}) => {
  const { type } = data;
  const inputControlType = {
    [QuestionType.TEXTAREA]: 'textarea',
    default: 'input',
  };

  const [isAI, setAI] = useState(false);

  const { chatGptPromptValues } = useSelector(featureFlagsSelector);

  const buildChatGPTIntegrationValues = values => JSON.parse(values).map(({ template }) => ({
    value: template,
    text: template,
  }));

  let state = FieldStatus.EDITABLE;
  if (surveyVisibility) {
    state = surveyVisibility;
  }

  const renderConfiguration = () => (
    <>
      <SurveyQuestionConfigHeader index={index} />
      <Divider />
      <Field name={`questions.${index}.config.defaultValue`}>
        {({
          field: { name, value },
          form: { handleChange },
        }) => (
          <Form.Field
            control={inputControlType[type] || inputControlType.default}
            data-cy={`${DATA_CY}-${index}-default-value-input`}
            id={name}
            label="Default value"
            type={type}
            value={value}
            onChange={handleChange}
          />
        )}
      </Field>
      {type === QuestionType.TEXTAREA && (
        <>
          <Field name={`questions.${index}.config.rows`}>
            {({
              field: { name, value },
              form: { handleChange },
            }) => (
              <Form.Input
                data-cy={`${DATA_CY}-${index}-rows-input`}
                id={name}
                label="Rows"
                min={1}
                type="number"
                value={value}
                onChange={handleChange}
              />
            )}
          </Field>
          { chatGptPromptValues ? (
            <>
              <Field name={`questions.${index}.config.ai`}>
                {(
                  { form: { values } },
                ) => (
                  <>
                    <FormFieldCheckboxWrapper
                      data-cy={`${DATA_CY}-${index}-ai-checkbox`}
                      label="AI Question"
                      name={`questions.${index}.config.ai`}
                      onStateHandler={setAI(values.questions[index].config.ai)}
                    />
                  </>
                )}
              </Field>
              <Field name={`questions.${index}.template`}>
                {() => (<>
                  <FormFieldSelectWrapper
                    data-cy={`${DATA_CY}-${index}-ai-options-select`}
                    disabled={!isAI}
                    label={'Categories'}
                    name={`questions.${index}.config.template`}
                    options={buildChatGPTIntegrationValues(chatGptPromptValues)}
                  />
                </>)}
              </Field>
            </>) : null}
        </>
      )}
      {type !== QuestionType.TEXTAREA && (
        <>
          <Field name={`questions.${index}.config.prefix`}>
            {({
              field: { name, value },
              form: { handleChange },
            }) => (
              <Form.Input
                data-cy={`${DATA_CY}-${index}-prefix-input`}
                id={name}
                label="Prefix"
                value={value}
                onChange={handleChange}
              />
            )}
          </Field>
          <Field name={`questions.${index}.config.suffix`}>
            {({
              field: { name, value },
              form: { handleChange },
            }) => (
              <Form.Input
                data-cy={`${DATA_CY}-${index}-suffix-input`}
                id={name}
                label="Suffix"
                value={value}
                onChange={handleChange}
              />
            )}
          </Field>

        </>
      )}
      {type !== QuestionType.NUMBER && (
        <>
          <Field name={`questions.${index}.config.regexp`}>
            {({
              field: { name, value },
              form: { setFieldValue },
            }) => (
              <Form.Field>
                <label className={styles.regExpLabel}>
                  <span>
                    {'RegExp'}
                  </span>
                  <ExternalLink
                    href="https://regexr.com/"
                    label="Test online"
                  />
                </label>
                <Input
                  data-cy={`${DATA_CY}-${index}-regexp-input`}
                  id={name}
                  labelPosition="right"
                  value={value}
                  onChange={(_event, { value: regExpValue }) => {
                    setFieldValue(name, regExpValue.trim().length > 0 ? regExpValue : null);
                  }}
                >
                  <Label>
                    {'/'}
                  </Label>
                  <input />
                  <Label>
                    {'/'}
                  </Label>
                </Input>
              </Form.Field>
            )}
          </Field>

          <Field name={`questions.${index}.config.regexpHelp`}>
            {({
              field: { name, value },
              form: { handleChange },
            }) => (
              <Form.Input
                data-cy={`${DATA_CY}-${index}-regexp-help-input`}
                id={name}
                label="RegExp help"
                value={value}
                onChange={handleChange}
              />
            )}
          </Field>
        </>
      )}
    </>
  );

  const renderInput = () => {
    const inputComponent = {
      [QuestionType.TEXTAREA]: question => (
        state === FieldStatus.READONLY ? (
          <>
            <label className={styles.label} data-cy={`${DATA_CY}-${index}-title`}>
              {question.title}
            </label>
            <div className={styles.textArea}>
              {answer || question.config.defaultValue}
            </div>
          </>
        ) : (
          <Form.Field required={question.required}>
            <label data-cy={`${DATA_CY}-${index}-title`}>
              {question.title}
            </label>
            {!auditScreen ? (
              <span data-cy={`${DATA_CY}-${index}-auditor-information`}>
                {question.auditor_information}
              </span>
            ) : null}
            <Form.TextArea
              data-cy={`${DATA_CY}-${index}-value`}
              defaultValue={answer || question.config.defaultValue}
              rows={question.config.rows}
              onChange={(_e, { value }) => (onChange ? onChange(value) : null)}
            />
          </Form.Field>
        )
      ),
      default: question => (
        <Form.Field required={question.required}>
          <label data-cy={`${DATA_CY}-${index}-title`}>
            {question.title}
          </label>
          {!auditScreen ? (
            <span data-cy={`${DATA_CY}-${index}-auditor-information`}>
              {question.auditor_information}
            </span>
          ) : null}
          <Input
            fluid
            data-cy={`${DATA_CY}-${index}-value`}
            defaultValue={answer || question.config.defaultValue}
            labelPosition={
              question.config.prefix.trim().length > 0 || question.config.suffix.trim().length > 0 ? 'right' : null
            }
            readOnly={state === FieldStatus.READONLY }
            transparent={state === FieldStatus.READONLY}
            type={type}
            onChange={(_e, { value }) => (onChange
              ? onChange(question.type === QuestionType.NUMBER ? Number.parseFloat(value) : value) : null
            )}
          >
            {question.config.prefix.trim().length > 0 ? (
              <Label>
                {question.config.prefix}
              </Label>
            ) : null}
            <input />
            {question.config.suffix.trim().length > 0 ? (
              <Label>
                {question.config.suffix}
              </Label>
            ) : null}
          </Input>
        </Form.Field>
      ),
    };

    if (state === FieldStatus.HIDDEN) {
      return null;
    }

    return (
      <Field name={`questions.${index}`}>
        {({
          field: { value: question },
        }) => (
          <>
            {(inputComponent[type] || inputComponent.default)(question)}
            {question.help.trim().length > 0 ? (
              <span data-cy={`${DATA_CY}-${index}-help`}>
                {question.help}
              </span>
            ) : null}
            {status === AuditStatus.SUBMITTED || status === AuditStatus.APPROVING_QUERY ? (
              <RoleChecker allowedRoles={[UserRole.DATA, UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER]} >
                <p data-cy={`${DATA_CY}-${index}-approving-information`}>
                  {question.approving_information}
                </p>
              </RoleChecker>
            ) : null}
          </>
        )}
      </Field>
    );
  };

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (
        selectedQuestionIndex === index ? renderConfiguration() : renderInput()
      )}
    </SurveyQuestionsContext.Consumer>
  );
};

SurveyTextQuestion.defaultProps = {
  answer: null,
  auditScreen: null,
  onChange: null,
  status: null,
  surveyVisibility: null,
};

SurveyTextQuestion.propTypes = {
  data: questionType.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.any,
  auditScreen: PropTypes.bool,
  status: PropTypes.string,
  surveyVisibility: PropTypes.string,
  onChange: PropTypes.func,
};

export default SurveyTextQuestion;
