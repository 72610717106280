import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, Divider, Form, List, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import {
  entitiesAPI,
  legalAPI,
  settingsAPI,
} from '../../../../api';
import {
  Entity,
  UserRole,
} from '../../../../constants';
import history from '../../../../history';
import { useUserRole } from '../../../../hooks';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import { FormFieldFileWrapper, FormFieldWrapper } from '../../../common';
import WaitPlaceholder from '../../../common/WaitPlaceholder/WaitPlaceholder.component';
import {
  newsfeedDefaultValues,
  newsfeedValidationSchema,
  privacyNoticeDefaultValues, termsAndConditionsDefaultValues,
  termsAndConditionsValidationSchema } from '../helpers';
import styles from './UserSettings.module.scss';
import UserSettingsEmailTemplates from './UserSettingsEmailTemplates/UserSettingsEmailTemplates.component';
import UserSettingsTokens from './UserSettingsTokens/UserSettingsTokens.component';

const DATA_CY = 'user-settings';

const UserSettings = () => {
  const role = useUserRole();
  const alert = useAlert();
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);
  const [loadedLink, setLoadedLink] = useState(null);

  const loadedLinkRef = useRef(null);

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
  };

  const cancel = useCallback(() => {
    history.push('/users');
  }, []);

  const { data: legalData } = useQuery(
    'legal',
    () => legalAPI.fetch(),
  );
  const { data: privacyNoticeData, refetch: refetchActivePrivacy } = useQuery(
    'privacyNotice',
    () => legalAPI.fetchActivePrivacy(),
  );

  const { data: emailData } = useQuery(
    'settings',
    () => settingsAPI.fetchEmailSettings(),
  );

  const { data: newsfeed } = useQuery(
    'newsfeed',
    () => entitiesAPI.fetchAll(Entity.NEWSFEED),
  );

  const { mutate: updateEmailSettings } = useMutation(settingsAPI.updateEmailSettings, {
    onSuccess: () => alert.info('Email settings correctly updated!'),
    onError: error => {
      alert.error(`Error updating email settings: ${error.message}`);
      Sentry.captureException(new CustomError(error));
    },
  });

  const { mutate: updateNewsfeed } = useMutation(entitiesAPI.update, {
    onSuccess: () => alert.info('Newsfeed correctly updated!'),
    onError: error => {
      alert.error(`Error updating newsfeed: ${error.message}`);
      Sentry.captureException(new CustomError(error));
    },
  });

  const { mutate: createNewTermsAndConditions } = useMutation(legalAPI.create, {
    onSuccess: () => alert.info('New Terms & Conditions version created!'),
    onError: error => {
      alert.error(`Error creating a new version of Terms & Conditions: ${error.message}`);
      Sentry.captureException(new CustomError(error));
    },
  });

  const displayPrivacyNoticeName = () => {
    if (privacyNoticeData?.location) {
      const description = privacyNoticeData?.location;
      const descriptionSplit = description.split('/');
      const descriptionName = descriptionSplit[descriptionSplit.length - 1];
      return descriptionName;
    }
    return 'No privacy notice uploaded';
  };

  const {
    mutate: downloadPrivacyNotice,
  } = useMutation(
    legalAPI.fetchActivePrivacy,
    {
      onSuccess: ({ url }) => {
        if (window.navigator.userAgent.match(/iPad|iPhone|iPod/)) {
          setLoadedLink((
            <a ref={loadedLinkRef} className={styles.linkButton} href={url} rel="noreferrer" target="_blank">
            CLICK HERE TO OPEN
            </a>
          ));
          loadedLinkRef.current.scrollIntoView();
        } else {
          window.open(url);
        }
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error getting privacy notice: ${error.message}`);
      },
    },
  );

  const {
    mutate: uploadNewPrivacy,
    isLoading: isPrivacyNoticeLoading,
  } = useMutation(
    legalAPI.uploadPrivacy,
    {
      onSuccess: () => {
        alert.success('Privacy notice uploaded!');
        refetchActivePrivacy();
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error uploading privacy notice: ${error.message}`);
      },
    },
  );

  // TODO: control this from routes and hidding link for not allowed roles
  if (role !== UserRole.DATA) {
    return null;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={emailData || []}
        onSubmit={values => {
          const adminCreationCheck = values.find(item => item.name === 'welcome_admin_created').body.includes('[user-temp-password]');
          const auditorApprovalCheck = values.find(item => item.name === 'account_activation').body.includes('[user-temp-password]');
          if (adminCreationCheck && auditorApprovalCheck) {
            updateEmailSettings(values);
          } else {
            alert.error('"Account Activation" body and "Welcome New User: Created By Admin" must include the user password token.');
          }
        }}
      >
        {({ handleSubmit }) => (
          <Form className={styles.root} data-cy={`${DATA_CY}-form`}>
            <Card fluid>
              <Card.Content extra className={styles.cardHeader}>
                <Card.Header className={styles.title}>
                  <div data-cy={`${DATA_CY}-title`}>
                    {'Account Settings'}
                  </div>
                </Card.Header>
                <Button
                  primary
                  className={styles.save}
                  data-cy={`${DATA_CY}-header-save-button`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {'Save'}
                </Button>
                <Button
                  data-cy={`${DATA_CY}-header-cancel-button`}
                  onClick={cancel}
                >
                  {'Cancel'}
                </Button>
              </Card.Content>
              <Card.Content>
                <Form.Group>
                  <Form.Field width={2}>
                    <label>
                      {'E-mails'}
                    </label>
                  </Form.Field>
                  <UserSettingsEmailTemplates dataCy={DATA_CY} />

                  <Form.Field width={4}>
                    <UserSettingsTokens />
                  </Form.Field>
                </Form.Group>

                <Divider />
              </Card.Content>
            </Card>
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize={true}
        initialValues={legalData || termsAndConditionsDefaultValues}
        validationSchema={termsAndConditionsValidationSchema}
        onSubmit={values => createNewTermsAndConditions({ description: values.description })}
      >
        {({ handleSubmit }) => (
          <Form className={styles.termsBox} data-cy={`${DATA_CY}-form`}>
            <Card fluid>
              <Card.Content extra className={styles.cardHeader}>
                <Card.Header className={styles.title}>
                  <div data-cy={`${DATA_CY}-title`}>
                    {'Terms & Conditions'}
                  </div>
                </Card.Header>
                <Button
                  primary
                  className={styles.save}
                  data-cy={`${DATA_CY}-header-activate-button`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {'Activate'}
                </Button>
              </Card.Content>
              <Card.Content>
                <Form.Group>
                  <div className={styles.subFields}>
                    <FormFieldWrapper
                      help={`Last version created: ${moment(legalData?.created_at).format('dddd, MMMM Do YYYY')}`}
                      name="description"
                      type="textarea"
                    />
                  </div>
                </Form.Group>
              </Card.Content>
            </Card>
          </Form>
        )}
      </Formik>

      <Card fluid>
        <Card.Content extra className={styles.cardHeader}>
          <Card.Header className={styles.title}>
            <div data-cy={`${DATA_CY}-title`}>
              {'Privacy Notice'}
            </div>
          </Card.Header>
          <Button
            primary
            className={styles.save}
            data-cy={`${DATA_CY}-header-activate-button`}
            onClick={openPrivacyNoticeModal}
          >
            {'Upload new version'}
          </Button>
        </Card.Content>
        <Card.Content>
          <div className={styles.subFields}>
            <List>
              <List.Item>
                { privacyNoticeData?.location
                  ? <>
                    {isPrivacyNoticeLoading && <WaitPlaceholder />}
                    <List.Icon name={'file'} />
                    <List.Content className={styles.linkButton} title="Download privacy notice" onClick={downloadPrivacyNotice}>
                      {displayPrivacyNoticeName()}
                    </List.Content>
                    <Divider/>
                    {`Last version created: ${moment(privacyNoticeData?.created_at).format('dddd, MMMM Do YYYY')}`}
                  </>
                  : <>
                    {'No privacy notice uploaded'}
                  </>
                }
              </List.Item>
            </List>
            {loadedLink || null}
          </div>
        </Card.Content>
      </Card>

      <Modal open={privacyNoticeModalOpen}>
        <Formik
          enableReinitialize
          initialValues={privacyNoticeDefaultValues}
          validationSchema={Yup.object({
            file: Yup.mixed().test('file', 'Required', value => value?.name?.length > 0),
          })}
          onSubmit={values => {
            setPrivacyNoticeModalOpen(false);
            const formData = new FormData();
            formData.append('file', values.file);
            uploadNewPrivacy({ payload: formData });
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {'Upload new version of Privacy Notice '}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldFileWrapper
                    customIcon
                    required
                    accept=".pdf"
                    icon="upload"
                    label="PDF File"
                    name="file"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => {
                  setPrivacyNoticeModalOpen(false);
                }}
                >
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Upload'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>

      <Formik
        enableReinitialize={true}
        initialValues={newsfeed?.[0] || newsfeedDefaultValues}
        validationSchema={newsfeedValidationSchema}
        onSubmit={values => updateNewsfeed({
          entity: Entity.NEWSFEED,
          entityId: values.id,
          payload: { content: values.content },
        })}
      >
        {({ handleSubmit }) => (
          <Form className={styles.termsBox} data-cy={`${DATA_CY}-form`}>
            <Card fluid>
              <Card.Content extra className={styles.cardHeader}>
                <Card.Header className={styles.title}>
                  <div data-cy={`${DATA_CY}-title`}>
                    {'Newsfeed'}
                  </div>
                </Card.Header>
                <Button
                  primary
                  className={styles.save}
                  data-cy={`${DATA_CY}-header-post-button`}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {'Post'}
                </Button>
              </Card.Content>
              <Card.Content>
                <Form.Group>
                  <div className={styles.subFields}>
                    <FormFieldWrapper
                      name="content"
                      type="textarea"
                    />
                  </div>
                </Form.Group>
              </Card.Content>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserSettings;
