import classnames from 'classnames';
import getCurrencySymbol from 'currency-symbols';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { featureFlagsSelector, taxonomyTermSelector } from '../../../../../state/constants/selectors';
import { calculatePays } from '../../AuditDetails/helpers';
import { StartAuditButtonCellFormatter } from '../../AuditsTable/CellFormatters';
import styles from './AuditsAccordionContent.module.scss';

const payTaxonomySelector = state => taxonomyTermSelector(state, 'pay_rate');

const AssignedAuditsAccordion = ({ data }) => {
  const { expensesFieldsUpdate } = useSelector(featureFlagsSelector);
  const currency = getCurrencySymbol(data?.order_currency) || data?.order_currency;
  const payTaxonomyTerms = useSelector(payTaxonomySelector);

  const { holidayPay, totalPay } = calculatePays({
    pay: data.auditor_pay_per_audit,
    payRateTaxonomy: payTaxonomyTerms,
    auditorBankCountry: data.auditor_bank_country,
    date: new Date().toISOString(),
  });

  return (
    <>
      <div className={classnames(styles.row, { [styles.hidden]: !data.visit_info })}>
        {data.visit_info}
      </div>
      <Divider className={classnames({ [styles.hidden]: !data.visit_info })}/>
      <div className={classnames(styles.row, { [styles.hidden]: !data.client_name })}>
        <span className={styles.siteIcon}/>
        {' '}
        {data.client_name}
      </div>
      <Divider className={classnames({ [styles.hidden]: !data.client_name })}/>
      <div className={classnames(styles.row, { [styles.hidden]: !data.item_to_order })}>
        <span className={styles.iconList}/>
        {' '}
        {data.item_to_order}
      </div>
      <Divider className={classnames({ [styles.hidden]: !data.item_to_order })}/>
      <div className={classnames(styles.row, { [styles.hidden]: !data.site_post_code })}>
        <span className={styles.locationIcon}/>
        {' Location: '}
        {data.site_post_code}
      </div>
      <Divider className={classnames({ [styles.hidden]: !data.site_post_code })}/>
      {expensesFieldsUpdate
        ? (
          <div
            className={styles.row}
          >
            <div className={styles.paysAssignedContainer}>
              <div>
                <span className={styles.currencyIcon}/>
                {' Pay: '}
                {(data.auditor_pay_per_audit || 0).toFixed(2)}
                {' '}
                {currency}
              </div>
              <div>
                <span className={styles.currencyIcon}/>
                {' Holiday Pay: '}
                {holidayPay}
                {' '}
                {currency}
              </div>
              <div>
                <span className={styles.currencyIcon}/>
                {' Total Pay: '}
                {totalPay}
                {' '}
                {currency}
              </div>
            </div>
            <div className={classnames(styles.deadline, { [styles.hidden]: !data.deadline_date })}>
              {'Deadline: '}
              <span className={styles.deadlineIcon}/>
              {moment(data.deadline_date).format(DateTimeFormat.DAY_MONTH_YEAR)}
            </div>
          </div>
        )
        : (
          <div
            className={classnames(styles.rowDouble, { [styles.row]: !data.deadline_date && !data.auditor_pay_per_audit })}
          >
            <div>
              <span className={styles.currencyIcon}/>
              {' Pay: '}
              {data.auditor_pay_per_audit}
            </div>
            <div className={classnames(styles.deadline, { [styles.hidden]: !data.deadline_date })}>
              {'Deadline: '}
              <span className={styles.deadlineIcon}/>
              {moment(data.deadline_date).format(DateTimeFormat.DAY_MONTH_YEAR)}
            </div>
          </div>
        )
      }
      <Divider/>
      <div className={styles.containerButton}>
        <StartAuditButtonCellFormatter cell={{ value: data.id }}/>
      </div>
    </>
  );
};

AssignedAuditsAccordion.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AssignedAuditsAccordion;
