/* eslint-disable import/prefer-default-export */

import { StatusCodes } from 'http-status-codes';

import { blob as BLOB } from '../constants/responseTypes';
import { removeLimitFromQuery, stringify } from '../utils/queryString';
import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchContent = async (entity, exportId, queryObject) => {
  const { url } = await getBackendConfig();
  let route = `${url}/api/v3/${entity}/exports/${exportId}/content`;
  let query = removeLimitFromQuery(queryObject);
  query = stringify(query);
  if (query.length > 0) {
    route = route.concat('?', query);
  }

  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() }, responseType: BLOB },
  )
    .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} export content`))
    .catch(logError);
};
