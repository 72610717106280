import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const updateStatus = ({ entityId, status }) => axios.put(
  `/ui/auditors/${entityId}/status`,
  { auditor_status: status },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating auditor ${entityId} status`))
  .catch(logError);

export const updateGeolocation = current_coordinates => axios.put(
  '/ui/auditors/me/coordinates',
  { current_coordinates },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating geolocation'))
  .catch(logError);

export const enableNotifications = notification => axios.put(
  '/ui/auditors/me/notifications',
  { enable_notifications: notification },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating notifications setting'))
  .catch(logError);

export const tooltip = ({ entityId }) => axios
  .get(`/ui/auditors/${entityId}/tooltip`, {
    headers: { Authorization: getAuthorization() },
  })
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error fetching auditor's ${entityId} tooltip`,
    ),
  )
  .catch(logError);
