import React, { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import history from '../../../../../history';
import { useUpdateSurveyV2 } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import { flattenConditions, rebuildConditions } from './helpers';
import SurveyMatchingEditor from './SurveyMatchingEditor/SurveyMatchingEditor.component';

const SurveyMatching = () => {
  const { entityId, revisionId } = useParams();
  const submitFormRef = useRef(null);

  const { data } = useQuery(
    [entityId, revisionId],
    () => surveyRevisionsAPI.fetchOne(entityId, revisionId),
  );

  const { mutate: updateSurvey } = useUpdateSurveyV2({
    currentStep: SurveyEntity.MATCHING,
  });

  const back = useCallback(() => {
    history.push(`/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.LOGIC}`);
  }, [entityId, revisionId]);

  const addConditionIds = (matchings = []) => matchings?.map(matching => ({
    ...matching,
    conditions: matching.conditions?.map(condition => ({
      ...condition,
      id: uuidv4(),
    })),
  }));

  const handleSubmit = useCallback(values => {
    // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
    // if (data?.blocked) {
    //   next();
    // } else {
    updateSurvey({
      surveyId: entityId,
      revisionId,
      payload: {
        [SurveyEntity.MATCHING]: rebuildConditions(addConditionIds(values.matching)),
      },
    });
    // }
  }, [entityId, revisionId, updateSurvey]);

  return (
    <SurveyStep
      back={back}
      backContent="Back to Logic"
      editor={data === undefined ? LoadingPlaceholder : SurveyMatchingEditor}
      editorProps={{
        matching: flattenConditions(data?.matching),
        completeQuestions: data?.questions,
        submitFormRef,
        onSubmit: handleSubmit }}
      headerProps={{ blocked: data?.blocked }}
      next={() => submitFormRef?.current?.click()}
      nextContent={/* data?.blocked ? 'Go to Surveys list' : */'Save & Continue'}
    />
  );
};

export default SurveyMatching;
