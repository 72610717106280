import { StatusCodes } from 'http-status-codes';

import { blob as BLOB } from '../constants/responseTypes';
import { removeLimitFromQuery, stringify } from '../utils/queryString';
import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchAll = entity => axios.get(
  `/ui/${entity}/exports`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} exports`))
  .catch(logError);

export const fetch = (entity, exportId) => axios.get(
  `/ui/${entity}/exports/${exportId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} export details`))
  .catch(logError);

export const fetchContent = async (entity, exportId, queryObject) => {
  const { url } = await getBackendConfig();
  let route = `${url}/api/v1/${entity}/exports/${exportId}/content`;
  let query = removeLimitFromQuery(queryObject);
  query = stringify(query);
  if (query.length > 0) {
    route = route.concat('?', query);
  }

  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() }, responseType: BLOB },
  )
    .then(handlingResponse([StatusCodes.OK], `Error fetching ${entity} export content`))
    .catch(logError);
};

export const create = ({ entity, payload }) => axios.post(
  `/ui/${entity}/exports`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity} export`))
  .catch(logError);

export const update = ({ entity, exportId, payload }) => axios.put(
  `/ui/${entity}/exports/${exportId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating ${entity} export with id ${exportId}`))
  .catch(logError);

export const remove = ({ entity, exportId }) => axios.delete(
  `/ui/${entity}/exports/${exportId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error deleting ${entity} export with id ${exportId}`))
  .catch(logError);
