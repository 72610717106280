/* eslint-disable react/sort-prop-types */
import * as Yup from 'yup';

import { COMPARATORS, ENTITIES, ENTITY_OPTS, INTERVAL_DATE_TIME_TYPES, INTERVAL_TYPES, OPERATORS, SHOW_TIME_RANGE, TYPES, VALUE_SELECTABLE_TYPES } from './constants/constants';

export const isImageQuestion = ({ detectionRuleQuestions, questionId }) => detectionRuleQuestions.find(q => q.value === questionId)?.type === 'image';

export const isValueSelectableQuestion = (
  { detectionRuleQuestions, questionId },
) => VALUE_SELECTABLE_TYPES.includes(detectionRuleQuestions.find(q => q.value === questionId)?.type);

export const isEntityValueAndQuestionNotSelectable = ({ entity, questionId, detectionRuleQuestions }) => {
  if (entity === 'value') {
    return !isValueSelectableQuestion({ detectionRuleQuestions, questionId });
  }
  return false;
};

export const isAIEnabled = ({ questionId, detectionRuleQuestions }) => detectionRuleQuestions
  .find(q => q.value === questionId)?.ai_enabled;

const questionTypesOperations = {
  image: ({ type }) => SHOW_TIME_RANGE.image[type],
  datetime: ({ referenceType }) => SHOW_TIME_RANGE.datetime[referenceType],
  default: ({ questionType }) => SHOW_TIME_RANGE[questionType],
};

const _getQuestionType = ({ questionType, referenceType, type }) => {
  const getOperation = questionTypesOperations[questionType] || questionTypesOperations.default;
  const result = getOperation({ questionType, referenceType, type });
  return result || SHOW_TIME_RANGE.default;
};

export const isTimeQuestion = ({ detectionRuleQuestions, decoratedQuestions, question, reference, type }) => {
  const questionType = detectionRuleQuestions.find(q => q.value === question)?.type;
  const referenceType = decoratedQuestions.find(q => q.value === reference)?.type;

  return _getQuestionType({ questionType, referenceType, type });
};

// todo: refactor to avoid object mutation
export const emptyOptionalFields = ({
  detectionRuleQuestions,
  decoratedQuestions,
  condition,

}) => {
  const questionType = detectionRuleQuestions.find(q => q.value === condition.question)?.type;
  const referenceType = decoratedQuestions.find(q => q.value === condition.reference)?.type;

  if ((questionType && !INTERVAL_TYPES.has(questionType))
    || (condition.type && condition.type !== 'time')
    || ((questionType && questionType === 'datetime') && !INTERVAL_DATE_TIME_TYPES.has(referenceType))) {
    delete condition.interval;
    delete condition.intervalUnit;
  }
  if (questionType && questionType !== 'image') {
    delete condition.format;
    delete condition.type;
    delete condition.detection_rule_name;
  }
  if (questionType && !isAIEnabled({ questionId: condition.question, detectionRuleQuestions })) {
    delete condition.template;
    delete condition.template_prompt_value;
    delete condition.ai_enabled;
  }
};

export const getReferenceOptions = ({
  entity,
  questionId,
  completeQuestions,
  detectionRuleQuestions,
  decoratedQuestions,
}) => {
  if (entity === 'value' && isValueSelectableQuestion({ detectionRuleQuestions, questionId })) {
    const radioOptions = completeQuestions.find(question => question.id === questionId).config.options;
    return radioOptions.map(option => ({ value: option.value, text: option.label }));
  }
  if (entity === 'question') {
    return decoratedQuestions;
  }
  return ENTITY_OPTS;
};

const isOperation = node => (!!((node?.AND || node?.OR)));

const operator = node => (node.AND ? 'AND' : 'OR');

const hasNestedOperation = node => (
  !!(isOperation(node) && (node[operator(node)] && isOperation(node[operator(node)][1])))
);

const flattenConditionsRecursive = (node, arr) => {
  if (hasNestedOperation(node)) {
    flattenConditionsRecursive(node[operator(node)][1], arr);
    arr.push(...[
      { condition: operator(node) },
      { ...node[operator(node)][0] },
    ]);
  } else if (isOperation(node)) {
    arr.push(...[
      { ...node[operator(node)][1] },
      { condition: operator(node) },
      { ...node[operator(node)][0] },
    ]);
  } else {
    // for simple rules with only one condition
    arr.push(node);
  }
};

export const flattenConditions = (matchings = []) => matchings?.map(matching => {
  const arr = [];
  flattenConditionsRecursive(matching.conditions, arr);
  return {
    id: matching.id,
    rule_name: matching.rule_name,
    conditions: arr,
  };
});

const rebuild = flattenedConditions => {
  let nestedConditions = {};
  let lastCondition;
  flattenedConditions.forEach(c => {
    if (c.condition === 'AND' || c.condition === 'OR') {
      nestedConditions = {
        [c.condition]: [{ ...nestedConditions }],
      };
      lastCondition = c.condition;
    } else if (lastCondition) {
      nestedConditions[lastCondition] = [
        c,
        { ...nestedConditions[lastCondition][0] },
      ];
    } else {
      nestedConditions = (c);
    }
  });
  return nestedConditions;
};

export const rebuildConditions = (matchings = []) => matchings?.map(matching => {
  const nestedConditions = rebuild(matching.conditions);
  return {
    id: matching.id,
    rule_name: matching.rule_name,
    conditions: nestedConditions,
  };
});

const conditionSchema = Yup.object().shape({
  operator: Yup.string().oneOf(OPERATORS).required('Operator is required'),
  entity: Yup.string().oneOf(ENTITIES).required('Entity is required'),
  question: Yup.string().required('Question is required'),
  reference: Yup.string().required('Reference is required'),
  detection_rule_name: Yup.string(),
  type: Yup.string().oneOf(TYPES),
  ai_enabled: Yup.boolean(),
  format: Yup.string().when('type', {
    is: type => ['time', 'date', 'day'].includes(type),
    then: Yup.string().required('Format is required'),
  }),
  interval: Yup.string()
    .when('type', {
      is: 'time',
      then: Yup.string().required('Interval unit is required'),
      otherwise: Yup.string(),
    }),
  intervalUnit: Yup.string()
    .when('type', {
      is: 'time',
      then: Yup.string().required('Interval unit is required'),
      otherwise: Yup.string(),
    }),
  template: Yup.string()
    .when('ai_enabled', {
      is: true,
      then: Yup.string().required('Template is required'),
      otherwise: Yup.string(),
    }),
  template_prompt_value: Yup.string()
    .when('ai_enabled', {
      is: true,
      then: Yup.string().required('Prompt value is required'),
      otherwise: Yup.string(),
    }),
});

const matchingComparator = Yup.object().shape({
  condition: Yup.string().required('Condition is required').oneOf(COMPARATORS),
});

const operatorSchema = item => {
  if (Object.hasOwn(item, 'condition')) {
    return matchingComparator;
  }
  return conditionSchema;
};

export const matchingValidationSchema = Yup.object().shape({
  matching: Yup.array().of(Yup.object().shape({
    rule_name: Yup.string().required('Rule name is required'),
    conditions: Yup.array().of(Yup.lazy(operatorSchema)).required('Conditions are required'),
  })).required('Matching is required'),
});
