import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

// eslint-disable-next-line import/prefer-default-export
export const tooltip = ({ entityId }) => axios
  .get(`/ui/sites/${entityId}/tooltip`, {
    headers: { Authorization: getAuthorization() },
  })
  .then(
    handlingResponse(
      [StatusCodes.OK],
      `Error fetching site's ${entityId} tooltip`,
    ),
  )
  .catch(logError);
