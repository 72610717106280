import { handleActions } from 'redux-actions';

import * as actions from './actionCreators';
import initialState from './initialState';

export default {
  constants: handleActions(
    {
      [actions.setTaxonomy]: (state, action) => ({
        ...state,
        taxonomy: action.payload,
      }),
      [actions.setEnums]: (state, action) => ({
        ...state,
        enums: action.payload,
      }),
    },
    initialState.constants,
  ),
  featureFlags: handleActions(
    {
      [actions.setConfigCat]: (state, action) => ({
        ...state,
        ...action.payload,
      }),
    },
    initialState.featureFlags,
  ),
  auth: handleActions(
    {
      [actions.setUserPrompts]: (state, action) => ({
        ...state,
        userPrompts: action.payload,
      }),
      [actions.setUserInfo]: (state, action) => ({
        ...state,
        userInfo: action.payload,
      }),
      [actions.setUserRole]: (state, action) => ({
        ...state,
        userRole: action.payload,
      }),
      [actions.clearAuth]: () => ({
        userInfo: {},
        userRole: {},
      }),
    },
    initialState.auth,
  ),
};
