import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Divider, Form, Loader } from 'semantic-ui-react';

import { clientsAPI, entitiesAPI, errorAPI, multipartAPI, powerBIAPI } from '../../../../api';
import { DateTimeFormat, Entity } from '../../../../constants';
import history from '../../../../history';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import downloadFile from '../../../../utils/downloadFile';
import Sentry from '../../../../utils/sentry';
import {
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldFileWrapper,
  FormFieldImageUploadWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  HierarchicalTree,
  TaxonomyTermOptions,
} from '../../../common';
import GenericModal from '../../../common/GernericModal/GenericModal.component';
import { clientType } from '../../../types';
import { addReportValidationSchema, clientValidationSchema } from '../helpers';
import styles from './ClientDetails.module.scss';

const DATA_CY = 'client-details';

const clientTypeTaxonomySelector = state => taxonomyTermSelector(state, 'client_type');
const statusTypeOptionsSelector = state => enumOptionsSelector(state, 'status_type');

const ClientDetails = ({
  data,
  submitFormRef,
}) => {
  const { entity, entityId } = useParams();
  const alert = useAlert();
  const clientTypeTaxonomy = useSelector(clientTypeTaxonomySelector);
  const statusTypeOptions = useSelector(statusTypeOptionsSelector);
  const [addReportModalOpen, setAddReportModalOpen] = useState(false);

  const buildUserSearchOptions = users => users.map(user => ({
    text: [user.first_name, user.middle_name, user.last_name].join(' ').concat(` (${user.email})`),
    value: user.id,
  }));

  const [filteredUsers, setFilteredUsers] = useState(entityId
    ? [{
      text: `${data.manager_name} (${data.manager_email})`,
      value: data.manager_id,
    }]
    : []);

  const [reports, setReports] = useState((entityId && data.report_id && data.report_name)
    ? [{ text: data.report_name, value: data.report_id }]
    : []);

  const searchUnassignedReports = () => {
    powerBIAPI.fetchUnassignedReports()
      .then(unassignedReports => {
        const unassignedReportOptions = unassignedReports
          .map(unassignedReportOption => ({
            text: unassignedReportOption.report_name,
            value: unassignedReportOption.report_id,
          }));
        setReports(unassignedReportOptions);
      })
      .catch(error => {
        alert.error(`Error fetching users: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const searchUsers = wildcard => {
    entitiesAPI.fetchAll(Entity.USERS, { name: wildcard })
      .then(users => {
        const cleanUsersArray = users.items.filter(item => item.role !== 'auditor' || item.role !== 'client');
        setFilteredUsers(buildUserSearchOptions(cleanUsersArray));
      })
      .catch(error => {
        alert.error(`Error fetching users: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const downloadTemplate = () => {
    entitiesAPI.fetchTemplate({ entity: Entity.CLIENTS, template: 'org-level' })
      .then(file => downloadFile(file, 'organisation_level_code_template.csv'))
      .catch(error => {
        alert.error(`Error downloading client organisation level file: ${error.message}`);
        errorAPI.sendError(error.message, '');
      });
  };

  const {
    mutate: clientMutate,
    status: clientMutateStatus } = useMutation(entityId ? multipartAPI.update : multipartAPI.create, {
    onSuccess: () => {
      alert.success(`Client ${entityId ? 'updated' : 'created'}`);
      history.push('/clients');
    },

    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error ${entityId ? 'updating' : 'creating'} creating client: ${error.message}`);
    },
  });

  const {
    mutate: addReportMutate,
    status: addReportMutateStatus,
  } = useMutation(clientsAPI.addReport, {
    onSuccess: () => {
      alert.success('Report added');
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error adding report: ${error.message}`);
    },
  });

  const onSubmitAddReport = values => {
    addReportMutate({
      clientId: entityId,
      payload: values,
    });
    setAddReportModalOpen(false);
  };

  const onSubmit = values => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    clientMutate({
      entity,
      entityId,
      payload: formData,
    });
  };

  return clientMutateStatus === 'loading' ? (
    <>
      <Dimmer active inverted>
        <Loader size="large">
          {'Uploading client'}
        </Loader>
      </Dimmer>
    </>
  ) : (
    <Formik
      initialValues={entityId !== undefined ? data : {}}
      validationSchema={clientValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isValid }) => {
        const submit = () => {
          if (!isValid) {
            Object.entries(errors).forEach(([key, value]) => {
              alert.error(`${key}: ${value}`);
            });
          } else {
            handleSubmit();
          }
        };

        return (
          <Form noValidate data-cy={`${DATA_CY}-form`}>
            <button ref={submitFormRef} hidden type="submit" onClick={submit} />

            <FormFieldWrapper
              inline
              required
              label="Client Name"
              name="name"
            />

            <FormFieldImageUploadWrapper
              inline
              label="Logo"
              name="logo"
            />

            <FormFieldImageUploadWrapper
              inline
              label="Footer"
              name="footer"
            />

            <FormFieldSelectWrapper
              inline
              required
              label="Operator Type"
              name="client_type"
              options={TaxonomyTermOptions(clientTypeTaxonomy)}
              placeholder="Select a value"
            />

            <FormFieldWrapper
              inline
              label="Sage Reference"
              name="sage_reference"
            />

            <FormFieldDynamicSearchWrapper
              inline
              required
              label="Manager"
              name="manager_id"
              options={filteredUsers}
              onSearchChange={searchUsers}
            />

            <Divider />

            <FormFieldDateTimeWrapper
              inline
              format={DateTimeFormat.DAY_MONTH_YEAR}
              label="Contract Expiry"
              name="contract_expiry"
              time={false}
            />

            <Divider />

            <FormFieldWrapper
              inline
              label="Store Locator"
              name="store_locator"
            />

            <Divider />

            <FormFieldSelectWrapper
              inline
              required
              label="Status"
              name="status"
              options={statusTypeOptions}
            />

            <Divider />

            <FormFieldDynamicSearchWrapper
              inline
              label="Report"
              name="report_id"
              options={reports}
              onSearchChange={searchUnassignedReports}
            />
            {entityId && (
              <>
                <Form.Group>
                  <Form.Field width={3}></Form.Field>
                  <Form.Field>
                    <Button onClick={() => setAddReportModalOpen(true)}>
                      {'Add Report'}
                    </Button>
                  </Form.Field>
                </Form.Group>
                <Formik
                  enableReinitialize
                  initialValues={{
                    report_name: '',
                    report_id: '',
                  }}
                  validationSchema={addReportValidationSchema}
                  onSubmit={(values, { resetForm }) => {
                    const valuesCopy = JSON.parse(JSON.stringify(values));
                    onSubmitAddReport(valuesCopy);
                    resetForm();
                  }}
                >
                  {({ handleSubmit: handleAddReportSubmit, errors: addReportErrors, resetForm }) => (
                    <GenericModal
                      buttons={[
                        {
                          label: 'Cancel',
                          func: () => {
                            setAddReportModalOpen(false);
                            resetForm();
                          },
                        },
                        {
                          label: 'Save',
                          loading: addReportMutateStatus === 'loading',
                          disabled: Object.keys(addReportErrors).length > 0,
                          func: () => handleAddReportSubmit(),
                        },
                      ]}
                      headerText={'Add Report'}
                      open={addReportModalOpen}
                      onDismiss={() => setAddReportModalOpen(false)}
                    >
                      <Form className={styles.modalWrapper}>
                        <FormFieldWrapper
                          inline
                          label={'Report name'}
                          name={'report_name'}
                          placeholder={'Enter report name'}
                        />
                        <FormFieldWrapper
                          inline
                          label={'Report id'}
                          name={'report_id'}
                          placeholder={'Enter report id'}
                        />
                      </Form>
                    </GenericModal>
                  )}
                </Formik>
              </>
            )}

            <Divider />

            <FormFieldFileWrapper
              inline
              accept=".csv"
              help={'Remember that making changes can modify related sites and users.'}
              label="Hierarchical Levels"
              name="organisation_level_code_file"
            />
            <div className={styles.downloadContainer}>
              {'Download the '}
              <span className={styles.download} onClick={downloadTemplate}>
                {'example CSV file'}
              </span>
            </div>

            <HierarchicalTree data={data?.org_levels} />

          </Form>
        );
      }}
    </Formik>
  );
};

ClientDetails.defaultProps = {
  data: null,
};

ClientDetails.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  data: clientType,
};

export default ClientDetails;
