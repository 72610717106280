import React from 'react';

import { Register } from '../../layout';
import RegisterAuditorFormWrapper from './RegisterAuditorFormWrapper/RegisterAuditorFormWrapper.component';

const RegisterAuditor = () => (
  <Register
    form={RegisterAuditorFormWrapper}
    title="Create new account"
  />
);

export default RegisterAuditor;
