import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Divider, Form, Grid } from 'semantic-ui-react';

import { surveyRevisionsAPI } from '../../../../../../api';
import { SurveyEntity } from '../../../../../../constants';
import { featureFlagsSelector } from '../../../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../../../common';
import { CONDITIONS, DEFAULT_CONDITION_VALUES_V2, DEFAULT_MATCHING_ROW_VALUES_V2, FORMAT_OPTIONS, INTERVAL_OPTIONS, MATCHING_FORMATS, NESTING_LIMIT, OPERATORS_OPTS, QUESTION_FIELD_OPTS } from '../constants/constants';
import { emptyOptionalFields, getReferenceOptions, isAIEnabled, isEntityValueAndQuestionNotSelectable, isImageQuestion, isTimeQuestion, matchingValidationSchema } from '../helpers';
import styles from './SurveyMatchingEditor.module.scss';

const DATA_CY = 'survey-matching';

const initialFormValues = {
  matching: [
    DEFAULT_MATCHING_ROW_VALUES_V2,
  ],
};

const checkConditionsLimit = conditions => (conditions ? conditions?.filter(condition => condition.question || condition.question === '').length >= NESTING_LIMIT : false);

const SurveyMatchingEditor = ({ matching, completeQuestions, submitFormRef, onSubmit }) => {
  const { entityId, revisionId } = useParams();

  const [detectionRuleQuestions, setDetectionRuleQuestions] = useState([]);
  const [decoratedQuestions, setDecoratedQuestions] = useState([]);

  const { chatGptPromptValues } = useSelector(featureFlagsSelector);

  const buildMatchingSelectOptions = questions => questions?.map((question, index) => ({
    text: `${question?.internal_id} - ${question?.title}`,
    value: question?.id,
    key: index,
    type: question.type,
    ai_enabled: question.config?.ai?.toString(),
  }));

  const { data } = useQuery(
    [entityId, revisionId, { include: [SurveyEntity.QUESTIONS] }],
    () => surveyRevisionsAPI.fetchOne(
      entityId,
      revisionId,
      {
        include: [SurveyEntity.QUESTIONS],
      },
    ),
    {
      onSuccess: ({ questions }) => {
        setDetectionRuleQuestions(buildMatchingSelectOptions(questions));
        setDecoratedQuestions(buildMatchingSelectOptions(questions));
      },
    },
  );

  const getDRFromQuestion = ({ id, surveyData }) => {
    const options = surveyData?.questions
      .find(el => el.id === id)?.config.rules.map(({ label }) => ({
        text: label,
        value: label,
      }));
    return options || [];
  };

  const getChatGptTemplateFromQuestion = ({ id, surveyData }) => {
    const { template } = surveyData?.questions.find(el => el.id === id)?.config || {};
    return [{ text: template, value: template }];
  };

  const getChatGptPromptValues = ({ id, surveyData, chatGptPrompts = [] }) => {
    const { template } = surveyData?.questions.find(el => el.id === id)?.config || {};
    const options = chatGptPrompts
      .find(el => el.template === template).prompts
      .map(prompt => ({ text: prompt.fieldName, value: prompt.fieldName, type: prompt.type }));

    return options || [];
  };

  const setAIType = ({ match, questionId, questions }) => {
    match.ai_enabled = questions.some(q => q.value === questionId && q.ai_enabled);
  };

  const setMatchType = format => {
    const dateTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'date').map(option => option.value));
    const timeTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'time').map(option => option.value));
    const dayTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'day').map(option => option.value));

    if (dateTypes.has(format)) {
      return MATCHING_FORMATS.date;
    }
    if (timeTypes.has(format)) {
      return MATCHING_FORMATS.time;
    }
    if (dayTypes.has(format)) {
      return MATCHING_FORMATS.day;
    }

    return MATCHING_FORMATS.text;
  };

  const setMatchingType = ({ match, format }) => {
    match.type = setMatchType(format);
  };

  return (
    <>
      {Boolean(data)
        && <Formik
          data-cy={DATA_CY}
          enableReinitialize={true}
          initialValues={{ matching } || initialFormValues}
          validateOnChange={false}
          validationSchema={matchingValidationSchema}
          onSubmit={onSubmit}

        // eslint-disable-next-line react/jsx-closing-bracket-location
        >
          {({ values, handleSubmit }) => (<Form noValidate>
            <button ref={submitFormRef} hidden type="submit" onClick={handleSubmit} />
            <FieldArray name="matching">
              {({ push, remove }) => (
                <>
                  {values.matching.map((_mr, i) => (
                    <div key={`mr-${i}`}>
                      <Card fluid>
                        <Card.Content>
                          <Grid>
                            <div className={styles.header}>
                              <span
                                className={styles.remove}
                                onClick={() => remove(i)}
                              />
                            </div>
                            <Grid.Row columns={16}>
                              <Grid.Column width={2}>
                                <p>
                                  Rule name
                                </p>
                              </Grid.Column>
                              <Grid.Column width={14}>
                                <FormFieldWrapper
                                  name={`matching.${i}.rule_name`}
                                />
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={16}>
                              <Grid.Column width={2}>
                                <p>
                                  Rules
                                </p>
                              </Grid.Column>
                              <Grid.Column width={14}>
                                <FieldArray name={`matching[${i}].conditions`}>
                                  {({ push: pushCondition, remove: removeCondition }) => (
                                    <React.Fragment>
                                      {_mr.conditions?.map((condition, j) => (
                                        <div key={`mr-${i}-c-${j}`}>
                                          { Object.hasOwn(condition, 'condition')
                                            ? (
                                              <Grid>
                                                <Grid.Row columns={16}>
                                                  <Grid.Column width={4}>
                                                    <FormFieldSelectWrapper
                                                      className={styles.box}
                                                      name={`matching.[${i}].conditions.[${j}].condition`}
                                                      options={CONDITIONS}
                                                      placeholder="Conditional"
                                                    />
                                                  </Grid.Column>
                                                  <Grid.Column width={12} />
                                                </Grid.Row>
                                              </Grid>
                                            ) : (
                                              <Card fluid className={styles.cardMargin}>
                                                <Card.Content>
                                                  <div className={styles.header}>
                                                    <span
                                                      className={styles.remove}
                                                      hidden={_mr.conditions.length <= 1}
                                                      onClick={() => {
                                                        removeCondition(j);
                                                        removeCondition(j - 1);
                                                      }}
                                                    />
                                                  </div>
                                                  <Grid>
                                                    <Grid.Row columns={16}>
                                                      <Grid.Column width={4}>
                                                        <FormFieldSelectWrapper
                                                          className={styles.box}
                                                          name={`matching.[${i}].conditions.[${j}].question`}
                                                          options={detectionRuleQuestions}
                                                          placeholder="Question"
                                                          onChange={
                                                            emptyOptionalFields({
                                                              detectionRuleQuestions,
                                                              decoratedQuestions,
                                                              condition,
                                                            })
                                                          }
                                                        />
                                                      </Grid.Column>
                                                      <Grid.Column width={3}>
                                                        <FormFieldSelectWrapper
                                                          className={styles.box}
                                                          disabled={isImageQuestion({
                                                            detectionRuleQuestions,
                                                            questionId: condition?.question,
                                                          })
                                                            ? Boolean(!condition?.detection_rule_name)
                                                            : Boolean(!condition?.question)}
                                                          name={`matching.[${i}].conditions.[${j}].operator`}
                                                          options={OPERATORS_OPTS}
                                                          placeholder="Operator"
                                                        />
                                                      </Grid.Column>
                                                      <Grid.Column width={3}>
                                                        <FormFieldSelectWrapper
                                                          className={styles.box}
                                                          disabled={isImageQuestion({
                                                            detectionRuleQuestions,
                                                            questionId: condition?.question,
                                                          })
                                                            ? Boolean(!condition?.detection_rule_name)
                                                            : Boolean(!condition?.question)}
                                                          name={`matching.[${i}].conditions.[${j}].entity`}
                                                          options={QUESTION_FIELD_OPTS}
                                                          placeholder="Entity"
                                                        />
                                                      </Grid.Column>
                                                      <Grid.Column width={4}>
                                                        {
                                                          isEntityValueAndQuestionNotSelectable({
                                                            entity: condition?.entity,
                                                            questionId: condition?.question,
                                                            detectionRuleQuestions,
                                                          }) ? (
                                                              <FormFieldWrapper
                                                                fluid
                                                                className={styles.box}
                                                                disabled={Boolean(!condition?.entity)}
                                                                name={`matching.[${i}].conditions.[${j}].reference`}
                                                                placeholder="Reference"
                                                              />)
                                                            : (
                                                              <FormFieldSelectWrapper
                                                                className={styles.box}
                                                                disabled={Boolean(!condition?.entity)}
                                                                name={`matching.[${i}].conditions.[${j}].reference`}
                                                                options={getReferenceOptions({
                                                                  entity: condition?.entity,
                                                                  questionId: condition?.question,
                                                                  completeQuestions,
                                                                  detectionRuleQuestions,
                                                                  decoratedQuestions,
                                                                })}
                                                                placeholder="Reference"
                                                                onChange={
                                                                  emptyOptionalFields({
                                                                    detectionRuleQuestions,
                                                                    decoratedQuestions,
                                                                    condition,
                                                                  })
                                                                }
                                                              />)

                                                        }

                                                      </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row columns={16}>
                                                      {
                                                        isImageQuestion({
                                                          detectionRuleQuestions,
                                                          questionId: condition?.question,
                                                        }) || isTimeQuestion({
                                                          detectionRuleQuestions,
                                                          decoratedQuestions,
                                                          question: condition?.question,
                                                          reference: condition?.reference,
                                                          type: condition?.type,
                                                        })
                                                          ? <Grid.Column width={2}>
                                                            {'Detection options'}
                                                          </Grid.Column> : null
                                                      }
                                                      {
                                                        isAIEnabled(
                                                          { questionId: condition.question, detectionRuleQuestions },
                                                        )
                                                          ? (<>
                                                            <Grid.Column width={4}>
                                                              <FormFieldSelectWrapper
                                                                className={styles.box}
                                                                name={`matching.[${i}].conditions.[${j}].template`}
                                                                options={getChatGptTemplateFromQuestion({
                                                                  surveyData: data,
                                                                  id: condition?.question,
                                                                })}
                                                                placeholder="Template"
                                                                onChange={setAIType({
                                                                  match: condition,
                                                                  questionId: values.matching[i].conditions[j].question,
                                                                  questions: detectionRuleQuestions,
                                                                })}
                                                              />
                                                            </Grid.Column>
                                                            <Grid.Column width={4}>
                                                              <FormFieldSelectWrapper
                                                                className={styles.box}
                                                                name={`matching.[${i}].conditions.[${j}].template_prompt_value`}
                                                                options={getChatGptPromptValues({
                                                                  surveyData: data,
                                                                  id: condition?.question,
                                                                  chatGptPrompts: JSON.parse(chatGptPromptValues),
                                                                })}
                                                                placeholder="Extractions"
                                                                onChange={setAIType({
                                                                  match: condition,
                                                                  questionId: values.matching[i].conditions[j].question,
                                                                  questions: detectionRuleQuestions,
                                                                })}
                                                              />
                                                            </Grid.Column>
                                                          </>

                                                          ) : null
                                                      }
                                                      {
                                                        isImageQuestion({
                                                          detectionRuleQuestions,
                                                          questionId: condition?.question,
                                                        })
                                                          ? (<Grid.Column width={4}>
                                                            <FormFieldSelectWrapper
                                                              className={styles.box}
                                                              name={`matching.[${i}].conditions.[${j}].detection_rule_name`}
                                                              options={getDRFromQuestion({
                                                                surveyData: data,
                                                                id: condition?.question,
                                                              })}
                                                              placeholder="Detection Rule"
                                                            />
                                                          </Grid.Column>) : null
                                                      }
                                                      {
                                                        isImageQuestion({
                                                          detectionRuleQuestions,
                                                          questionId: condition?.question,
                                                        })
                                                          ? (<Grid.Column width={5}>
                                                            <FormFieldSelectWrapper
                                                              className={styles.box}
                                                              name={`matching.[${i}].conditions.[${j}].format`}
                                                              options={FORMAT_OPTIONS}
                                                              placeholder="Detection Format"
                                                              onChange={setMatchingType({
                                                                match: condition,
                                                                format: condition?.format,
                                                              })}
                                                            />
                                                          </Grid.Column>) : null
                                                      }
                                                      {
                                                        isTimeQuestion({
                                                          detectionRuleQuestions,
                                                          decoratedQuestions,
                                                          question: condition?.question,
                                                          reference: condition?.reference,
                                                          type: condition?.type,
                                                        })
                                                          ? (<Grid.Column className={styles.flex} width={1}>
                                                            <FormFieldWrapper
                                                              className={`${styles.box} ${styles.noBorderRadiusRight}`}
                                                              name={`matching.[${i}].conditions.[${j}].interval`}
                                                              placeholder="interval"
                                                              type="number"
                                                            />
                                                            <FormFieldSelectWrapper
                                                              className={`${styles.box} ${styles.noBorderRadiusLeft} ${styles.overflowSelect}`}
                                                              name={`matching.[${i}].conditions.[${j}].intervalUnit`}
                                                              options={INTERVAL_OPTIONS}
                                                              placeholder="unit"
                                                            />
                                                          </Grid.Column>) : null
                                                      }
                                                    </Grid.Row>
                                                  </Grid>
                                                </Card.Content>
                                              </Card>

                                            )}
                                          {' '}
                                        </div>
                                      ))}

                                      <Button
                                        disabled={checkConditionsLimit(values?.matching[i]?.conditions)}
                                        onClick={() => {
                                          pushCondition({ condition: '' });
                                          pushCondition(...DEFAULT_CONDITION_VALUES_V2);
                                        }}
                                      >
                                        Add Condition
                                      </Button>
                                    </React.Fragment>
                                  )}
                                </FieldArray>
                              </Grid.Column>
                            </Grid.Row>

                          </Grid>
                        </Card.Content>
                      </Card>
                      <Divider />
                    </div>
                  ))
                  }
                  <Button
                    onClick={() => push(DEFAULT_MATCHING_ROW_VALUES_V2)}
                  >
                    Add Rule
                  </Button>

                </>

              )}
            </FieldArray>
          </Form>)}
        </Formik>
      }
    </>
  );
};

SurveyMatchingEditor.defaultProps = {
  completeQuestions: [],
  matching: [],
};

SurveyMatchingEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  completeQuestions: PropTypes.arrayOf(PropTypes.object),
  matching: PropTypes.arrayOf(PropTypes.object),
};

export default SurveyMatchingEditor;
