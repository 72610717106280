import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { Country, DateTimeFormat, UserRole } from '../../../constants';
import { GeographicCoordinates } from '../../common';

export const auditorFilterValidationSchema = Yup.object().shape({
  home_post_code: Yup.string(),
  name: Yup.string(),
  number_of_audits: Yup.number().min(0),
  patch: Yup.string(),
  status: Yup.string(),
  term_post_code: Yup.string(),
});

export const auditorFilterDefaultValues = {
  home_post_code: '',
  name: '',
  number_of_audits: 0,
  patch: '',
  status: '',
  term_post_code: '',
};

const dateFormatter = (value, data) => (
  data[value]
    ? moment(data[value]).format(DateTimeFormat.DAY_MONTH_YEAR)
    : ''
);

export const auditorInformation = [
  [
    {
      label: 'Home address',
      value: 'home_address_1',
    },
    {
      label: '',
      value: 'home_address_2',
    },
    {
      label: '',
      value: 'home_city',
    },
    {
      label: '',
      value: 'home_post_code',
    },
    {
      label: '',
      value: 'home_county',
    },
    {
      label: '',
      value: 'home_coordinates',
      formatter: (value, data) => (
        <GeographicCoordinates
          coordinates={data[value]}
          patch={data.home_patch_name}
        />
      ),
    },
    {
      label: 'Term address',
      value: 'term_address_1',
    },
    {
      label: '',
      value: 'term_address_2',
    },
    {
      label: '',
      value: 'term_city',
    },
    {
      label: '',
      value: 'term_post_code',
    },
    {
      label: '',
      value: 'term_county',
    },
    {
      label: '',
      value: 'term_coordinates',
      formatter: (value, data) => (
        <GeographicCoordinates
          coordinates={data[value]}
          patch={data.term_patch_name}
        />
      ),
    },
    { label: 'Current Location Interval',
      value: 'auto_geolocation_interval',
    },
    {
      label: 'Current Location',
      value: 'current_coordinates',
      formatter: (value, data) => (
        <GeographicCoordinates
          coordinates={data[value]}
          patch={data.current_patch_name}
        />
      ),
    },
    {
      label: 'Access to car?',
      value: 'access_to_car',
    },
  ],
  [
    {
      label: 'Date of birth',
      value: 'date_of_birth',
      formatter: dateFormatter,
    },
    {
      label: 'Nationality',
      value: 'nationality',
    },
    {
      label: 'Visa Expiration',
      value: 'visa_expiration',
      formatter: (value, data) => (['GB', 'IE'].includes(data?.nationality) ? 'N/A' : moment(data[value]).format(DateTimeFormat.DAY_MONTH_YEAR)),
    },
    {
      label: 'Title',
      value: 'honorific',
    },
    {
      label: 'Marital status',
      value: 'marital_status',
    },
    {
      label: 'Occupation',
      value: 'occupation',
    },
  ],
  [
    {
      label: 'Notes',
      value: 'notes',
    },
    {
      label: 'Last logged in',
      value: 'last_access',
      formatter: dateFormatter,
    },
    {
      label: 'Application date',
      value: 'application_date',
      formatter: dateFormatter,
    },
    {
      label: 'Inactivation date',
      value: 'inactivation_date',
      formatter: dateFormatter,
    },
    {
      label: 'NARV audit count',
      value: 'auditor_narv_visit_count',
    },
    {
      label: 'First NARV audit date',
      value: 'auditor_first_narv_visit_date',
      formatter: dateFormatter,
    },
    {
      label: 'Last NARV audit date',
      value: 'auditor_last_narv_visit_date',
      formatter: dateFormatter,
    },
    {
      label: 'SL audit count',
      value: 'auditor_sl_visit_count',
    },
    {
      label: 'First SL audit date',
      value: 'auditor_first_sl_visit_date',
      formatter: dateFormatter,
    },
    {
      label: 'Last SL audit date',
      value: 'auditor_last_sl_visit_date',
      formatter: dateFormatter,
    },
    {
      label: 'Auditor pass rate',
      value: 'auditor_pass_rate',
      formatter: (value, data) => (data[value] ? `${data[value]} %` : 'N/A'),
    },
  ],
];

export const bankInformation = [
  [
    {
      label: 'Name on Card / Bank Account Holder',
      value: 'card_name',
      defaultValue: 'N/A',
    },
    {
      label: 'Bank account number',
      value: 'account_number',
      defaultValue: 'N/A',
    },
    {
      label: 'SWIFT code',
      value: 'swift_code',
      defaultValue: 'N/A',
    },
    {
      label: 'What circumstances of work you have?',
      value: 'circumstances',
      defaultValue: 'N/A',
    },
  ],
  [
    {
      label: 'Bank Country',
      value: 'bank_country',
      defaultValue: 'N/A',
    },
    {
      label: 'Bank Sort Code',
      value: 'sort_code',
      defaultValue: 'N/A',
    },
    {
      label: 'IBAN Number',
      value: 'iban_number',
      defaultValue: 'N/A',
    },
    {
      label: 'What kind of student loan do you have?',
      value: 'student_loans',
      defaultValue: 'N/A',
    },
  ],
  [],
];

export const serveLegalAnswersInformation = [
  [
    {
      label: 'If recommended, who recommended you?',
      value: 'recommended_by',
      default: 'N/A',
    },
    {
      label: 'Please give details',
      value: 'hear_about_us_details',
      default: 'N/A',
    },
    {
      label: 'Why do you want to work with ServeLegal?',
      value: 'why_servelegal',
      default: 'N/A',
    },
    {
      label: 'Tell us a bit about yourself',
      value: 'about_yourself',
      default: 'N/A',
    },
    {
      label: 'Can you travel outside your local area?',
      value: 'travel_outside',
      default: 'N/A',
    },
  ],
];

export const auditorEditValidationSchemaWithoutMandatoryTermAddress = role => Yup.object().shape({
  about_yourself: Yup.string().nullable(),
  access_to_car: Yup.string(),
  account_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`).matches(/^[0-9]{8}?$/, {
        message: 'Account numbers must be only numbers and 8 characters long',
      })
      : Yup.mixed().notRequired())) // For Ireland
    : Yup.string().matches(/^[0-9]{8}?$/, {
      message: 'Account numbers must be 8 characters long',
    }),
  auto_geolocation_interval: Yup.number().nullable(),
  bank_country: role === UserRole.AUDITOR
    ? Yup.string().required('Country required')
    : Yup.string().nullable(),
  card_name: role === UserRole.AUDITOR
    ? Yup.string().required('Field required')
    : Yup.string().nullable(),
  circumstances: role === UserRole.AUDITOR
    ? Yup.string().required().typeError('Field required')
    : Yup.string().nullable(),
  current_coordinates: Yup.object().nullable(),
  date_of_birth: Yup.string(),
  email: Yup.string(),
  first_name: Yup.string(),
  gender: Yup.string(),
  hear_about_us_details: Yup.string().nullable(),
  home_address_1: role === UserRole.AUDITOR
    ? Yup.string().required('Home address is required')
    : Yup.string().nullable(),
  home_address_2: Yup.string().nullable(),
  home_county: role === UserRole.AUDITOR
    ? Yup.string().required('Home country is required')
    : Yup.string().nullable(),
  home_number: Yup.string().nullable(),
  home_post_code: role === UserRole.AUDITOR
    ? Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Home post code is required'),
      })
    : Yup.string().nullable(),
  honorific: Yup.string(),
  iban_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  last_name: Yup.string(),
  marital_status: Yup.string(),
  middle_name: Yup.string().nullable(),
  mobile_number: Yup.string(),
  ni_pps: Yup.string()
    .when('country', {
      is: 'Ireland',
      then: Yup.string()
        .matches(/^[0-9]{7}[a-zA-Z]{1}[a-zA-Z]?$/, {
          message: 'Incorrect format - Use a valid PPS Number',
          excludeEmptyString: false,
        })
        .required('Please enter your number'),
      otherwise: Yup.string()
        .matches(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/, {
          message: 'Incorrect format - Use a valid NI Number',
          excludeEmptyString: false,
        })
        .required('Required'),
    }),
  notes: Yup.string().nullable(),
  occupation: Yup.string().nullable(),
  recommended_by: Yup.string().nullable(),
  sort_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`).matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
        message: 'The correct format is 00-00-00',
      })
      : Yup.mixed().notRequired()))
    : Yup.string().matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
      message: 'The correct format is 00-00-00',
    }),
  student_loans: role === UserRole.AUDITOR ? Yup.string().required().typeError('Field required') : Yup.string().nullable(),
  swift_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  term_address_1: Yup.string().nullable(),
  term_address_2: Yup.string().nullable(),
  term_county: Yup.string().nullable(),
  term_post_code: Yup.string().nullable(),
  travel_outside: Yup.string().nullable(),
  why_servelegal: Yup.string().nullable(),
});

export const auditorEditValidationSchema = role => Yup.object().shape({
  about_yourself: Yup.string().nullable(),
  access_to_car: Yup.string(),
  account_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`).matches(/^[0-9]{8}?$/, {
        message: 'Account numbers must be only numbers and 8 characters long',
      })
      : Yup.mixed().notRequired())) // For Ireland
    : Yup.string().matches(/^[0-9]{8}?$/, {
      message: 'Account numbers must be 8 characters long',
    }),
  auto_geolocation_interval: Yup.number().nullable(),
  bank_country: role === UserRole.AUDITOR
    ? Yup.string().required('Country required')
    : Yup.string().nullable(),
  card_name: role === UserRole.AUDITOR
    ? Yup.string().required('Field required')
    : Yup.string().nullable(),
  circumstances: role === UserRole.AUDITOR
    ? Yup.string().required().typeError('Field required')
    : Yup.string().nullable(),
  current_coordinates: Yup.object().nullable(),
  date_of_birth: Yup.string(),
  email: Yup.string(),
  first_name: Yup.string(),
  gender: Yup.string(),
  hear_about_us_details: Yup.string().nullable(),
  home_address_1: role === UserRole.AUDITOR
    ? Yup.string().required('Home address is required')
    : Yup.string().nullable(),
  home_address_2: Yup.string().nullable(),
  home_county: role === UserRole.AUDITOR
    ? Yup.string().required('Home country is required')
    : Yup.string().nullable(),
  home_number: Yup.string().nullable(),
  home_post_code: role === UserRole.AUDITOR
    ? Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Home post code is required'),
      })
    : Yup.string().nullable(),
  honorific: Yup.string(),
  iban_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  last_name: Yup.string(),
  marital_status: Yup.string(),
  middle_name: Yup.string().nullable(),
  mobile_number: Yup.string(),
  ni_pps: Yup.string()
    .when('country', {
      is: 'Ireland',
      then: Yup.string()
        .matches(/^[0-9]{7}[a-zA-Z]{1}[a-zA-Z]?$/, {
          message: 'Incorrect format - Use a valid PPS Number',
          excludeEmptyString: false,
        })
        .required('Please enter your number'),
      otherwise: Yup.string()
        .matches(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/, {
          message: 'Incorrect format - Use a valid NI Number',
          excludeEmptyString: false,
        })
        .required('Required'),
    }),
  notes: Yup.string().nullable(),
  occupation: Yup.string().nullable(),
  recommended_by: Yup.string().nullable(),
  sort_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`).matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
        message: 'The correct format is 00-00-00',
      })
      : Yup.mixed().notRequired()))
    : Yup.string().matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
      message: 'The correct format is 00-00-00',
    }),
  student_loans: role === UserRole.AUDITOR ? Yup.string().required().typeError('Field required') : Yup.string().nullable(),
  swift_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  term_address_1: role === UserRole.AUDITOR
    ? Yup.string().required('Term address is required')
    : Yup.string().nullable(),
  term_address_2: Yup.string().nullable(),
  term_county: role === UserRole.AUDITOR
    ? Yup.string().required('Term country is required')
    : Yup.string().nullable(),
  term_post_code: role === UserRole.AUDITOR
    ? Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Term postcode is required'),
      })
    : Yup.string().nullable(),
  travel_outside: Yup.string().nullable(),
  why_servelegal: Yup.string().nullable(),
});
