/* eslint-disable no-console */
import classnames from 'classnames';
import { Formik } from 'formik';
import localforage from 'localforage';
import _pick from 'lodash/pick';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Grid, Icon } from 'semantic-ui-react';

import { DateTimeFormat, QuestionType } from '../../../../constants';
import { updateAuditExtraDetails } from '../../../../state/audits/actionCreators';
import { auditExtraDetailsSelector } from '../../../../state/audits/selectors';
import {
  enumOptionsSelector,
  featureFlagsSelector,
  taxonomyTermSelector,
} from '../../../../state/constants/selectors';
import isMobile from '../../../../utils/isMobile';
import {
  FormFieldCurrencyWrapper,
  FormFieldDatePickerWrapper,
  FormFieldDateTimeWrapper,
  FormFieldDropzoneWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  ReclaimableExpensesAccordion,
} from '../../../common';
import { calculatePays } from '../../Audits/AuditDetails/helpers';
import { editableExtraDetailsFields, getThumbnailURL as getImageUrl } from '../helpers';
import styles from './AuditingExtraDetails.module.scss';
import { extraDetailsValidationSchema, extraDetailsValidationSchemaV2 } from './helpers';

const DATA_CY = 'auditing-extra-details';
const DEFAULT_MILAGE_TYPE = 'car_or_motorbike';

const travelExpensesOptionsSelector = state => enumOptionsSelector(state, 'travel_expenses_type');
const reclaimableExpensesOptionsSelector = state => enumOptionsSelector(state, 'reclaimable_expenses_type');
const mileageTypeOptionsSelector = state => enumOptionsSelector(state, 'mileage_type');
const payTaxonomySelector = state => taxonomyTermSelector(state, 'pay_rate');

const AuditingExtraDetails = ({ data, next, previous }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const LOCALFORAGE_EXPENSES_IMAGES_KEY = `${data.id}:expenses_receipts`;
  const LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY = `${data.id}:travel_expenses_images`;

  const travelExpensesOptions = useSelector(travelExpensesOptionsSelector);
  const reclaimableExpensesOptions = useSelector(reclaimableExpensesOptionsSelector);
  const mileageTypeOptions = useSelector(mileageTypeOptionsSelector);
  const payTaxonomyTerms = useSelector(payTaxonomySelector);
  const auditExtraDetails = state => auditExtraDetailsSelector(state, data.id);
  const extraDetails = useSelector(auditExtraDetails);
  const { newDateTimePicker, expensesFieldsUpdate } = useSelector(featureFlagsSelector);
  const [initialExpensesImageUrls, setInitialExpensesImageUrls] = useState([]);
  const [initialTravelExpensesImageUrls, setInitialTravelExpensesImageUrls] = useState([]);

  const getCurrentImages = async key => {
    const imagesFromLocalForage = (await localforage.getItem(key)) || [];
    return Promise.all(imagesFromLocalForage.map(getImageUrl));
  };

  useEffect(() => {
    const getCurrentExpensesImages = async () => {
      const imageUrls = await getCurrentImages(LOCALFORAGE_EXPENSES_IMAGES_KEY);
      setInitialExpensesImageUrls(imageUrls);
    };

    const getCurrentTravelExpensesImages = async () => {
      const imageUrls = await getCurrentImages(LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY);
      setInitialTravelExpensesImageUrls(imageUrls);
    };

    getCurrentExpensesImages();
    getCurrentTravelExpensesImages();
  }, []);

  const submitDetails = useCallback(async values => {
    const pickedValues = _pick(values, editableExtraDetailsFields);
    dispatch(updateAuditExtraDetails({
      id: data.id,
      ...pickedValues,
      expenses_receipts: LOCALFORAGE_EXPENSES_IMAGES_KEY,
      travel_expenses_images: LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY,
    }));
    next();
  }, [data.id, LOCALFORAGE_EXPENSES_IMAGES_KEY, LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY, dispatch, next]);

  const _validateSubmissionDate = values => moment().utc().isBefore(moment.utc(values.order_start_date), 'day');

  const _validateDateOfVisit = values => moment
    .utc(values.date_of_visit)
    .isBefore(moment.utc(values.order_start_date), 'day');

  const _validateReclaimableExpenses = values => {
    const reclaimableExpenses = Object.keys(values).filter(key => key.match(/^reclaimable_expenses(_\d+)?$/));
    const reclaimableExpensesErrors = [];

    reclaimableExpenses.forEach((reclaimableExpense, index) => {
      if (values[reclaimableExpense] && (!values[`${reclaimableExpense}_type`] || !values[`${reclaimableExpense}_description`])) {
        if (reclaimableExpense === 'reclaimable_expenses') {
          reclaimableExpensesErrors.push('reclaimable expenses 1');
        } else {
          reclaimableExpensesErrors.push(`reclaimable expenses ${index + 1}`);
        }
      }
    });

    return reclaimableExpensesErrors;
  };

  const validate = values => {
    const isSubmitDateBeforeOrderStartDate = _validateSubmissionDate(values);

    if (isSubmitDateBeforeOrderStartDate) {
      return alert.error('You’ve attempted to submit your audit before the order starts. Please contact your area manager');
    }

    const isVisitDateBeforeOrderStartDate = _validateDateOfVisit(values);

    if (isVisitDateBeforeOrderStartDate) {
      return alert.error('You’ve attempted to submit your audit with a date before the order starts. Please contact your area manager');
    }

    if (expensesFieldsUpdate) { // TODO: remove this condition when the flag is removed
      const reclaimableExpensesErrors = _validateReclaimableExpenses(values);
      if (reclaimableExpensesErrors.length > 0) {
        return alert.error(`Please, check the reclaimable expenses ${reclaimableExpensesErrors.join(', ')} fields. They are required.`);
      }
    }
    return submitDetails(values);
  };

  const handleOnDropFiles = localForageKey => async filesData => {
    const currentFiles = (await localforage.getItem(localForageKey)) || [];
    const dropFiles = filesData.map(({ file }) => file);
    await localforage.setItem(localForageKey, [...currentFiles, ...dropFiles]);
  };

  const handleDeleteFile = localForageKey => async fileData => {
    const currentFiles = await localforage.getItem(localForageKey);
    currentFiles.splice(fileData.index, 1);
    await localforage.setItem(localForageKey, currentFiles);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...extraDetails,
        order_start_date: data.order_start_date,
        mileage_type: DEFAULT_MILAGE_TYPE,
        expenses_receipts: initialExpensesImageUrls,
        travel_expenses_images: initialTravelExpensesImageUrls,
        auditor_pay_per_audit: data.auditor_pay_per_audit,
        holiday_pay: data.holiday_pay,
        total_pay: data.total_pay,
      }}
      validationSchema={expensesFieldsUpdate ? extraDetailsValidationSchemaV2 : extraDetailsValidationSchema} // TODO: remove this condition when the flag is removed
      onSubmit={values => validate(values)}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const firstColumnContent = <Grid.Column className={classnames({ [styles.column]: isMobile })}>
          {newDateTimePicker ? (
            <FormFieldDatePickerWrapper
              required
              label="Date & Time - this is the time of the transaction on the receipt (where applicable)"
              labelStyle={styles.labelStyle}
              labelTooltip="Where applicable, this is the time of the transaction."
              max={moment().toDate()}
              min={moment().subtract(72, 'hours').toDate()}
              name="date_of_visit"
              type={QuestionType.DATETIME}
              onChange={date => {
                setFieldValue('date_of_visit', date);
                const { holidayPay, totalPay } = calculatePays({
                  pay: data.auditor_pay_per_audit,
                  payRateTaxonomy: payTaxonomyTerms,
                  date,
                  auditorBankCountry: data.auditor_bank_country });
                setFieldValue('holiday_pay', holidayPay);
                setFieldValue('total_pay', totalPay);
              }}
            />
          ) : (
            <FormFieldDateTimeWrapper
              required
              format={DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H}
              label="Date & Time - this is the time of the transaction on the receipt (where applicable)"
              labelStyle={styles.labelStyle}
              labelTooltip="Where applicable, this is the time of the transaction."
              max={moment().toDate()}
              min={moment().subtract(72, 'hours').toDate()}
              name="date_of_visit"
              onChange={date => {
                setFieldValue('date_of_visit', date);
                const { holidayPay, totalPay } = calculatePays({
                  pay: values.audit_pay_per_audit,
                  payRateTaxonomy: payTaxonomyTerms,
                  date,
                  auditorBankCountry: data.auditor_bank_country });
                setFieldValue('holiday_pay', holidayPay);
                setFieldValue('total_pay', totalPay);
              }}
            />
          )}

          {!expensesFieldsUpdate && (
            <>
              <FormFieldCurrencyWrapper
                fluid
                currency={data.order_currency}
                label="Purchase cost - age-restricted item only (where applicable)"
                labelTooltip="This is the amount spent on the age-restricted item(s) only, if you had to purchase one. Put 0 if not applicable."
                name="purchase_cost"
              />
              <FormFieldCurrencyWrapper
                readOnly
                currency={data?.order_currency}
                label={'Pay & purchase allowance'}
                name="auditor_pay_per_audit"
              />
            </>
          )}

          {expensesFieldsUpdate && (
            <Card className={styles.card}>
              <FormFieldCurrencyWrapper
                fluid
                currency={data.order_currency}
                label="Purchase cost - age-restricted item only (where applicable)"
                labelTooltip="This is the amount spent on the age-restricted item(s) only, if you had to purchase one. Put 0 if not applicable."
                name="purchase_cost"
              />

              <Grid stackable columns={3} padded={'vertically'} verticalAlign="middle">
                <FormFieldCurrencyWrapper
                  readOnly
                  currency={data?.order_currency}
                  label={'Pay'}
                  name="auditor_pay_per_audit"
                />

                <FormFieldCurrencyWrapper
                  readOnly
                  currency={data?.order_currency}
                  label={'Holiday Pay'}
                  name="holiday_pay"
                />

                <FormFieldCurrencyWrapper
                  readOnly
                  currency={data?.order_currency}
                  label={'Total Pay'}
                  name="total_pay"
                />
              </Grid>
            </Card>
          )}

          {!expensesFieldsUpdate && (
            <Grid stackable columns={2} padded={'vertically'} verticalAlign="middle">
              <Grid.Column >
                <FormFieldCurrencyWrapper
                  fluid
                  currency="miles"
                  label="Mileage"
                  labelTooltip="This is the number of miles you have travelled to this audit, from either your starting location, or your previous audit. Please select the appropriate drop down for driving miles, or walking/cycling miles, and the locations you have travelled between. Your expenses will then be calculated automatically."
                  name="mileage"
                />
              </Grid.Column>
              <Grid.Column>
                <FormFieldSelectWrapper
                  fluid
                  label="Mileage type"
                  labelTooltip="Select the category for your mileage type."
                  name="mileage_type"
                  options={mileageTypeOptions}
                  // value={mileageType.value}
                  // onChange={setMilageType}
                />
              </Grid.Column>
            </Grid>
          )}

          {expensesFieldsUpdate && (
            <Card className={styles.card}>
              <Grid stackable columns={2} padded={'vertically'} verticalAlign="middle">
                <Grid.Column >
                  <FormFieldCurrencyWrapper
                    fluid
                    currency="miles"
                    label="Mileage"
                    labelTooltip="This is the number of miles you have travelled to this audit, from either your starting location, or your previous audit. Please select the appropriate drop down for driving miles, or walking/cycling miles, and the locations you have travelled between. Your expenses will then be calculated automatically."
                    name="mileage"
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormFieldSelectWrapper
                    fluid
                    label="Mileage type"
                    labelTooltip="Select the category for your mileage type."
                    name="mileage_type"
                    options={mileageTypeOptions}
                    // value={mileageType.value}
                    // onChange={setMilageType}
                  />
                </Grid.Column>
              </Grid>
              <FormFieldWrapper
                label="Mileage description"
                labelTooltip="Please detail where you have travelled to and from for your mileage claim. Leave blank if you are not claiming any mileage."
                name="mileage_description"
                type="textarea"
              />
            </Card>
          )}

          {!expensesFieldsUpdate && (
            <FormFieldCurrencyWrapper
              currency={data.order_currency}
              label="Entrance charge"
              name="entrance_charge"
            />)}

          {!expensesFieldsUpdate && (
            <FormFieldDropzoneWrapper
              label="Expenses receipts"
              name="expenses_receipts"
              onDropFiles={handleOnDropFiles(LOCALFORAGE_EXPENSES_IMAGES_KEY)}
              onRemoveFile={handleDeleteFile(LOCALFORAGE_EXPENSES_IMAGES_KEY)}
            />
          )}

          {expensesFieldsUpdate && (
            <>
              <Card className={styles.card}>
                <FormFieldCurrencyWrapper
                  fluid
                  currency={data.order_currency}
                  label="Travel expenses"
                  labelTooltip="For public transport, or any additional costs of driving (e.g. car park/bridge tolls). Ensure you choose the appropriate drop-down below."
                  name="travel_expenses"
                />

                <FormFieldSelectWrapper
                  label="Travel expenses type"
                  labelTooltip="Select the category for your travel expense type."
                  name="travel_expenses_type"
                  options={travelExpensesOptions}
                />

                <FormFieldWrapper
                  label="Travel expenses description"
                  labelTooltip="Travel expenses will not be paid if this is not completed."
                  name="travel_expenses_description"
                  type="textarea"
                />
                <FormFieldDropzoneWrapper
                  label="Travel expense image (s) - please upload multiple photos if needed"
                  name="travel_expenses_images"
                  onDropFiles={handleOnDropFiles(LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY)}
                  onRemoveFile={handleDeleteFile(LOCALFORAGE_TRAVEL_IMAGES_EXPENSES_KEY)}
                />
              </Card>

            </>
          )}
        </Grid.Column>;

        const secondColumnContent = <Grid.Column className={classnames({ [styles.column]: isMobile })}>
          {!expensesFieldsUpdate && (
            <>
              <FormFieldCurrencyWrapper
                currency={data.order_currency}
                label="Travel expenses"
                labelTooltip="For public transport, or any additional costs of driving (e.g. car park/bridge tolls). Ensure you choose the appropriate drop-down below."
                name="travel_expenses"
              />

              <FormFieldSelectWrapper
                label="Travel expenses type"
                labelTooltip="Select the category for your travel expense type."
                name="travel_expenses_type"
                options={travelExpensesOptions}
              />

              <FormFieldWrapper
                label="Travel expenses description"
                labelTooltip="Travel expenses will not be paid if this is not completed."
                name="travel_expenses_description"
                type="textarea"
              />

            </>
          )}

          {expensesFieldsUpdate
            ? (
              <Card className={styles.card}>
                <ReclaimableExpensesAccordion
                  tooltips
                  data={data}
                  openAccordions={1}
                  reclaimableExpensesOptions={reclaimableExpensesOptions?.filter(option => !option.deprecated)}
                />
                <FormFieldDropzoneWrapper
                  label="Reclaimable expense image (s) - please upload multiple photos if needed"
                  name="expenses_receipts"
                  onDropFiles={handleOnDropFiles(LOCALFORAGE_EXPENSES_IMAGES_KEY)}
                  onRemoveFile={handleDeleteFile(LOCALFORAGE_EXPENSES_IMAGES_KEY)}
                />
              </Card>
            )
            : (
              <>
                <FormFieldCurrencyWrapper
                  currency={data.order_currency}
                  label="Reclaimable expenses"
                  name="reclaimable_expenses"
                />

                <FormFieldSelectWrapper
                  label="Reclaimable expenses type"
                  labelTooltip="Select the category for your reclaimable expense."
                  name="reclaimable_expenses_type"
                  options={reclaimableExpensesOptions.filter(({ value }) => ![
                    'delivery_fee',
                    'service_and_small_basket_fee',
                    'specific_purchase_requirement',
                    'entrance_charge',
                    'low_cost_item',
                  ].includes(value))}
                />

                <FormFieldWrapper
                  label="Reclaimable expenses description"
                  labelTooltip="Refer to the briefing document for what you can claim."
                  name="reclaimable_expenses_description"
                  type="textarea"
                />
              </>
            )
          }

          <div className={styles.reviewButton}>
            <Button
              icon
              data-cy={`${DATA_CY}-back-button`}
              onClick={previous}
            >
              {'Back'}
            </Button>

            <Button
              icon
              primary
              data-cy={`${DATA_CY}-submit-audit-button`}
              labelPosition="right"
              onClick={handleSubmit}
            >
              {'Submit Audit'}
              <Icon className={styles.sendIcon} />
            </Button>
          </div>

        </Grid.Column>;

        return (
          <Form data-cy={DATA_CY}>
            <Grid stackable className={classnames({ [styles.gridWrapper]: isMobile })} columns={2}>
              {isMobile
                ? (
                  <>
                    <div
                      className={styles.columnWrapper}
                    >
                      {firstColumnContent}
                    </div>
                    <div
                      className={styles.columnWrapper}
                    >
                      {secondColumnContent}
                    </div>
                  </>
                )
                : (
                  <>
                    {firstColumnContent}
                    {secondColumnContent}
                  </>
                )
              }
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AuditingExtraDetails.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
};

export default AuditingExtraDetails;
