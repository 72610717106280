import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

// eslint-disable-next-line import/prefer-default-export
export const geocode = ({ entity }) => axios.post(
  `/ui/developer/${entity}/geocode`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error geocoding ${entity}`))
  .catch(logError);
