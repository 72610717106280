import { countries } from 'countries-list';
import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'semantic-ui-react';

import { auditorsAPI } from '../../../../../api';
import getFieldAuditorStatus from '../../../../../fieldLogic/auditor';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { getGeolocationForAuditors } from '../../../../../utils/auto-geolocation';
import checkHonorific from '../../../../../utils/filterOutHonorific';
import {
  FormFieldDateTimeWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  GeographicCoordinates,
} from '../../../../common';
import styles from './EditAuditorInfoTab.module.scss';

const accessToCarOptionsSelector = state => enumOptionsSelector(state, 'access_to_car_type');
const honorificOptionsSelector = state => enumOptionsSelector(state, 'honorific_type').filter(item => checkHonorific(item.value));
const maritalStatusOptionsSelector = state => enumOptionsSelector(state, 'marital_status_type');
const occupationOptionsSelector = state => enumOptionsSelector(state, 'occupation_type');
const countryOptionsSelector = state => enumOptionsSelector(state, 'country_type');

const EditAuditorInfoTab = ({ role, mode }) => {
  const { values, setFieldValue } = useFormikContext();
  const accessToCarOptions = useSelector(accessToCarOptionsSelector);
  const honorificOptions = useSelector(honorificOptionsSelector);
  const maritalStatusOptions = useSelector(maritalStatusOptionsSelector);
  const occupationOptions = useSelector(occupationOptionsSelector);
  const countryOptions = useSelector(countryOptionsSelector);
  const autoGeolocationIntervalOptions = [{
    value: 15,
    text: '15 min',
  }, {
    value: 30,
    text: '30 min',
  }, {
    value: 45,
    text: '45 min',
  }, {
    value: 60,
    text: '1 hour',
  }];

  const handleAutoLocationToggle = async checked => {
    if (checked) {
      setFieldValue('auto_geolocation_interval', autoGeolocationIntervalOptions[0].value);
      await getGeolocationForAuditors();
    } else if (!checked) {
      setFieldValue('auto_geolocation_interval', null);
      auditorsAPI.updateGeolocation({
        lat: null,
        lng: null,
      });
    }
  };

  const allCountries = Object.keys(countries)
    .map(item => ({ ...countries[item], iso: item }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const nameCountryOptions = allCountries.map(item => ({
    text: `${item.name} ${item.emoji}`,
    value: item.iso,
  }));

  return (
    <>
      <FormFieldSelectWrapper
        inline
        label="Nationality"
        name="nationality"
        options={nameCountryOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {(['GB', 'IE'].includes(values?.nationality) ? null : (
        <FormFieldDateTimeWrapper
          inline
          label="Visa expiration"
          name="visa_expiration"
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />
      ))}

      <FormFieldSelectWrapper
        inline
        label="Country"
        name="country"
        options={countryOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldSelectWrapper
        inline
        label="Title"
        name="honorific"
        options={honorificOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Marital status"
        name="marital_status"
        options={maritalStatusOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Occupation"
        name="occupation"
        options={occupationOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="NI or PPS Number"
        name="ni_pps"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <h4>
        {'Home Address'}
      </h4>
      <FormFieldWrapper
        inline
        label="Address 1"
        name="home_address_1"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Address 2"
        name="home_address_2"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="County"
        name="home_county"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Post Code"
        name="home_post_code"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="City"
        name="home_city"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldSelectWrapper
        inline
        readOnly
        label="Country"
        name="country"
        options={countryOptions}
      />
      <Form.Group>
        <Form.Field width={3}>
          <label className={styles.label}>
            {'Coordinates'}
          </label>
        </Form.Field>
        <Form.Field width={9}>
          <GeographicCoordinates
            coordinates={values.home_coordinates}
            patch={values.home_patch_name}
          />
        </Form.Field>
      </Form.Group>

      <Divider />

      <h4>
        {'Term Address'}
      </h4>
      <FormFieldWrapper
        inline
        label="Address 1"
        name="term_address_1"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Address 2"
        name="term_address_2"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="County"
        name="term_county"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Post Code"
        name="term_post_code"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="City"
        name="term_city"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldSelectWrapper
        inline
        readOnly
        label="Country"
        name="country"
        options={countryOptions}
      />
      <Form.Group>
        <Form.Field width={3}>
          <label className={styles.label}>
            {'Coordinates'}
          </label>
        </Form.Field>
        <Form.Field width={9}>
          <GeographicCoordinates
            coordinates={values.term_coordinates}
            patch={values.term_patch_name}
          />
        </Form.Field>
      </Form.Group>

      <Divider />
      <h4>
        {'Current Location'}
      </h4>
      {getFieldAuditorStatus(role, mode)('auto_geolocation_interval') !== FieldStatus.HIDDEN
      && <Form.Group>
        <Form.Field width={3}>
          <label
            className={styles.label}
            data-cy={'auto_geolocation_interval-toggle-label'}
          >
            Enable auto-geolocation
          </label>
        </Form.Field>
        <Form.Field width={9}>
          <Field
            toggle
            as={Form.Checkbox}
            checked={Boolean(values.auto_geolocation_interval)}
            data-cy={'auto_geolocation_interval-toggle-field'}
            onChange={(ev, { checked }) => handleAutoLocationToggle(checked)}
          />
        </Form.Field>
      </Form.Group>
      }

      { values.auto_geolocation_interval
        && <FormFieldSelectWrapper
          clearable
          inline
          label="Geolocation interval"
          name="auto_geolocation_interval"
          options={autoGeolocationIntervalOptions}
          placeholder="Select an interval"
          onStateHandler={getFieldAuditorStatus(role, mode)}
          // eslint-disable-next-line react/jsx-closing-bracket-location
        />
      }

      {values.current_coordinates
        && <Form.Group>
          <Form.Field width={3}>
            <label className={styles.label}>
              {'Last coordinates'}
            </label>
          </Form.Field>
          <Form.Field width={9}>
            <GeographicCoordinates
              coordinates={values.term_coordinates}
              patch={values.term_patch_name}
            />
          </Form.Field>
        </Form.Group>
      }
      <Divider />

      <FormFieldSelectWrapper
        inline
        label="Access to car?"
        name="access_to_car"
        options={accessToCarOptions}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="Notes"
        name="notes"
        type="textarea"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
    </>
  );
};

EditAuditorInfoTab.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default EditAuditorInfoTab;
