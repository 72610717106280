import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const downloadAllDocs = async () => {
  const { url } = await getBackendConfig();
  return axios({
    method: 'get',
    url: `${url}/api/v1/taxonomy/documents/download`,
    responseType: 'blob',
    headers: { Authorization: getAuthorization() },
  })
    .then(handlingResponse([StatusCodes.OK], 'Error downloading all documents'))
    .catch(logError);
};

export const uploadDocument = async ({ payload }) => {
  const { url } = await getBackendConfig();
  return axios.post(
    `${url}/api/v1/taxonomy/documents`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], 'Error uploading new document'))
    .catch(logError);
};

export const replaceDocument = async ({ payload, documentId }) => {
  const { url } = await getBackendConfig();
  return axios.put(
    `${url}/api/v1/taxonomy/documents/${documentId}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], `Error replacing document ${documentId}`))
    .catch(logError);
};

export const fetchAllDocuments = () => axios.get(
  '/ui/taxonomy/documents',
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching all documents'))
  .catch(logError);

export const getDocumentDetails = documentId => axios.get(
  `/ui/taxonomy/documents/${documentId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error getting document ${documentId} details`))
  .catch(logError);

export const removeDocument = documentId => axios.delete(
  `/ui/taxonomy/documents/${documentId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error removing document ${documentId}`))
  .catch(logError);

export const renameDocument = ({ documentId, payload }) => axios.patch(
  `/ui/taxonomy/documents/${documentId}/move`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error renaming document ${documentId}`))
  .catch(logError);

/// ////

export const fetchAllAuditTokens = () => axios.get(
  '/ui/taxonomy/tokens',
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching all tokens'))
  .catch(logError);

export const fetchAuditTokensByType = type => axios.get(
  `/ui/taxonomy/tokens/type/${type}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching all tokens'))
  .catch(logError);

export const createAuditToken = ({ payload }) => axios.post(
  '/ui/taxonomy/tokens',
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error creating a token'))
  .catch(logError);

export const removeAuditToken = tokenId => axios.delete(
  `/ui/taxonomy/tokens/${tokenId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error removing token ${tokenId} details`))
  .catch(logError);

export const updateAuditToken = ({ tokenId, payload }) => axios.put(
  `/ui/taxonomy/tokens/${tokenId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error renaming token ${tokenId}`))
  .catch(logError);
