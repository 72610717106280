import { Formik } from 'formik';
import _xor from 'lodash/xor';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Grid, Icon } from 'semantic-ui-react';

import { initAudit } from '../../../../state/audits/actionCreators';
import { auditAnswersSelector } from '../../../../state/audits/selectors';
import { featureFlagsSelector, taxonomyTermSelector } from '../../../../state/constants/selectors';
import {
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../common';
import DynamicForm from '../../../common/DynamicForm/DynamicForm.component';
import AuditingLocationModal from '../AuditingLocation/AuditingLocationModal/AuditingLocationModal.component';
import { initAuditing } from '../helpers';
import styles from './AuditingWelcome.module.scss';

const DATA_CY = 'auditing-welcome';

const itemToOrderTaxonomyTermSelector = state => taxonomyTermSelector(state, 'order_item');

const AuditingWelcome = ({ data, next }) => {
  const { entityId: auditId } = useParams();
  const dispatch = useDispatch();
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomyTermSelector);
  const [modalOpen, setModalOpen] = useState(false);
  const [failLetterNotificationModalOpen, setFailLetterNotificationModalOpen] = useState(false);
  const { automaticFailLetterNotification } = useSelector(featureFlagsSelector);
  const [isFailLetterRequestSent, setFailLetterRequestSent] = useState(false);

  const answersSelector = state => auditAnswersSelector(state, data.id);
  const auditAnswers = useSelector(answersSelector);

  const isFailLetterEnabled = () => {
    if (!automaticFailLetterNotification) return false; // TODO: remove this condition once the feature flag is removed
    const { automatic_fail_letter_notification, automatic_fail_letter_notification_email_list } = data || {};

    if (!automatic_fail_letter_notification || !automatic_fail_letter_notification_email_list
      || data.automatic_fail_letter_notification_sent || isFailLetterRequestSent) return false;
    return true;
  };

  useEffect(() => {
    if (_xor(data.revision_questions.map(q => q.id), auditAnswers?.map(a => a.id)).length > 0) {
      const initialValues = initAuditing(data);

      dispatch(initAudit({
        id: data.id,
        answers: initialValues.initialAnswers,
        currentQuestionId: data.revision_questions[0].id,
        extraDetails: initialValues.initialExtraDetails,
      }));
    }
  }, [auditAnswers, data, dispatch]);

  return (
    <div className={styles.root}>
      <div className={styles.mainContainer}>
        <div className={styles.title}>
          {data?.internal_id}
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={data}
        >
          <Grid doubling columns={2}>
            <Grid.Row>
              <Grid.Column width={7}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Client Name"
                    name="client_name"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={9}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Audit info"
                    name="visit_info"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <div className={styles.formField}>
                  <FormFieldSelectWrapper
                    readOnly
                    label="Item to order"
                    name="item_to_order"
                    options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={9}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Auditor Instructions"
                    name="auditor_instructions"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Site"
                    name="site_name"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={9}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Site Code"
                    name="site_code"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Site Address"
                    name="site_address_1"
                  />
                  {data.site_address_2 && (
                    <FormFieldWrapper
                      readOnly
                      label=""
                      name="site_address_2"
                    />
                  )}
                  {data.site_address_3 && (
                    <FormFieldWrapper
                      readOnly
                      label=""
                      name="site_address_3"
                    />
                  )}

                  <FormFieldWrapper
                    readOnly
                    label=""
                    name="site_city"
                  />
                  <FormFieldWrapper
                    readOnly
                    label=""
                    name="site_post_code"
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={9}>
                <div className={styles.formField}>
                  <FormFieldWrapper
                    readOnly
                    label="Site Directions"
                    name="site_directions"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <p className={styles.label}>
                  {'Briefing documents'}
                </p>
                {data?.order_briefing_documents_metadata?.map((item, index) => {
                  const filePath = item.text.split('/');
                  const fileName = filePath.pop();
                  return (
                    <div key={index} className={styles.contentDoc}>
                      <span className={styles.iconPdf}></span>
                      <a className={styles.textPdf} href={item.value} rel="noopener noreferrer" target="_blank">
                        {fileName}
                      </a>
                    </div>
                  );
                })}
              </Grid.Column>
              <Grid.Column width={9}>
                {isFailLetterEnabled() && (
                  <>
                    <p className={styles.label}>
                      {'Fail Letter Notification'}
                    </p>
                    <Button
                      primary
                      data-cy={`${DATA_CY}-start-button`}
                      onClick={() => setFailLetterNotificationModalOpen(true)}
                    >
                      {'Send notification'}
                    </Button>

                    <DynamicForm
                      auditId= {auditId}
                      auditorId={data.auditor_id}
                      emailBody={data.automatic_fail_letter_notification_email_body}
                      emaiList={data.automatic_fail_letter_notification_email_list}
                      emailSubject={data.automatic_fail_letter_notification_email_subject}
                      name="automatic_fail_letter_notification_pdf"
                      open={failLetterNotificationModalOpen}
                      onClose={() => setFailLetterNotificationModalOpen(false)}
                      onNotificationSent={setFailLetterRequestSent}
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Formik>
      </div>
      <div className={styles.content}>
        <p className={styles.subtitle}>
          {'We want you to tell us about your experience at'}
          <span className={styles.client}>
            {` ${data?.client_name} `}
          </span>
          {'and send us your comments with all the details.'}
        </p>
        <div className={styles.buttons}>
          <Button
            data-cy={`${DATA_CY}-send-location-audit-button`}
            onClick={() => setModalOpen(true)}
          >
            {<Icon className="icon-location" />}
            {'Send location'}
          </Button>
          <Button
            data-cy={`${DATA_CY}-survey-questions-button`}
            onClick={() => next({ jumpQuestions: false })}
          >
            {'View Questions'}
          </Button>
          <Button
            primary
            data-cy={`${DATA_CY}-start-button`}
            onClick={() => next({ jumpQuestions: true })}
          >
            {'Start Audit'}
          </Button>
        </div>
      </div>
      <AuditingLocationModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
      />
    </div>
  );
};

AuditingWelcome.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
};

export default AuditingWelcome;
