import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { Button, Divider, Form, Header, Icon, Input, Menu, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { taxonomyAPI } from '../../../../../api';
import { DocumentsConst, UserRole } from '../../../../../constants';
import { useUserRole } from '../../../../../hooks';
import buildDocumentsTree from '../../../../../utils/buildDocumentsTree';
import CustomError from '../../../../../utils/customError';
import Sentry from '../../../../../utils/sentry';
import { FormFieldWrapper } from '../../../../common';
import DropDownTable from '../../../../common/DropDownTable/DropDownTable.component';
import GenericModal from '../../../../common/GernericModal/GenericModal.component';
import WaitPlaceholder from '../../../../common/WaitPlaceholder/WaitPlaceholder.component';
import styles from './TaxonomyDetailsAuditTokens.module.scss';

const DATA_CY = 'taxonomy-audit-tokens';

const TaxonomyDetailsAuditTokens = () => {
  const role = useUserRole();
  const alert = useAlert();

  const [documentsTree, setDocumentsTree] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [folderSelected, setFolderSelected] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [documentNameFilter, setDocumentNameFilter] = useState('');
  const [actionRequired, setActionRequired] = useState({
    item: null,
    actionRequired: null,
    confirmationMessage: '',
  });

  const canEdit = (role === UserRole.DATA || role === UserRole.CLIENT_SERVICES);

  const getDocumentById = ({ id, docsArr }) => {
    const output = [];
    docsArr.forEach(d => {
      if (d.id === id) {
        output.push(d);
      }
      if (d.subRows) {
        d.subRows.forEach(sr => {
          if (sr.id === id) {
            output.push(sr);
          }
        });
      }
    });
    return output[0];
  };

  const fileNameValidation = ({ name, tokens }) => {
    if (!name || name.trim().length === 0) return false;

    const sameTokenName = item => item.token.toLowerCase() === name.toLowerCase();
    return !tokens.some(sameTokenName);
  };

  const existInFolder = ({ folder, name }) => folder.filter(sr => (sr.text || sr.token) === name).length;

  const startCreatingAuditToken = (folder = null) => {
    setFolderSelected(folder?.name || null);
    setModalOpen(true);
  };

  const startRemovingToken = item => {
    setActionRequired({
      item,
      action: DocumentsConst.Action.REMOVE,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.REMOVE} this token?`,
    });
  };

  const startRemovingAuditTokenFolder = item => {
    setActionRequired({
      item,
      action: DocumentsConst.Action.REMOVE_FOLDER,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.REMOVE} this folder and all of its content?`,
    });
  };

  const startRenamingToken = item => {
    setActionRequired({
      item,
      action: DocumentsConst.Action.RENAME,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.RENAME} this token?`,
    });
  };

  const resetAction = () => {
    setActionRequired({
      item: null,
      action: null,
      confirmationMessage: '',
    });
  };

  const toggleFolderExpanded = item => {
    const newTree = documentsTree.map(elem => (elem.id === item.id ? { ...elem, expanded: !elem.expanded } : elem));
    setDocumentsTree(newTree);
  };

  const {
    data,
    isLoading: isFetchLoading,
    refetch,
  } = useQuery(
    'get-documents',
    () => taxonomyAPI.fetchAllAuditTokens(),
  );

  useEffect(() => {
    if (data) {
      const tree = buildDocumentsTree({
        docs: data,
        docNameFilter: documentNameFilter,
      });
      setDocumentsTree(oldTree => tree.map(item => {
        if (item.subRows) {
          const oldItem = oldTree?.find(d => d.id === item.id);
          return ({
            ...item,
            expanded: oldItem ? oldItem.expanded : false,
          });
        }

        return item;
      }));
    }
  }, [data, documentNameFilter]);

  const {
    mutate: createAuditToken,
    isLoading: isCreatingTokenLoading,
  } = useMutation(
    taxonomyAPI.createAuditToken,
    {
      onSuccess: () => {
        alert.success('Token uploaded!');
        refetch();
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error creating token: ${error.message}`);
      },
    },
  );

  const {
    mutate: removeAuditToken,
    isLoading: isRemoveTokenLoading,
  } = useMutation(
    taxonomyAPI.removeAuditToken,
    {
      onSuccess: () => {
        alert.success('Token removed!');
        refetch();
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error removing token: ${error.message}`);
      },
      onSettled: () => resetAction(),
    },
  );

  const {
    mutate: updateAuditToken,
    isLoading: isRenameTokenLoading,
  } = useMutation(
    taxonomyAPI.updateAuditToken,
    {
      onSuccess: () => {
        alert.success('Token modified!');
        refetch();
      },
      onError: error => {
        Sentry.captureException(new CustomError(error));
        alert.error(`Error modifying token: ${error.message}`);
      },
      onSettled: () => resetAction(),
    },
  );

  useEffect(() => {
    setLoading(
      isFetchLoading
      || isCreatingTokenLoading
      || isRemoveTokenLoading
      || isRenameTokenLoading,
    );
  }, [isFetchLoading,
    isCreatingTokenLoading,
    isRemoveTokenLoading,
    isRenameTokenLoading]);

  const dragEnd = ({ destination, source }) => {
    if (!canEdit) {
      alert.error('You don\'t have permissions to move documents.');
    }
    if (!destination) {
      return;
    }
    const sourceItem = getDocumentById({
      id: source?.droppableId,
      docsArr: documentsTree,
    });
    if (destination.droppableId === 'root') {
      const tokenAtRoot = data.filter(item => (!item.type && item.is_token));
      if (existInFolder({ folder: tokenAtRoot, name: sourceItem.name })) {
        alert.error('This token name already exist in the root');
        return;
      }
      updateAuditToken({
        tokenId: sourceItem.id,
        payload: {
          token: sourceItem.name,
          is_token: true,
          type: null,
        },
      });
    } else {
      const targetFolder = getDocumentById({
        id: destination?.droppableId,
        docsArr: documentsTree,
      });
      if (existInFolder({ folder: targetFolder.subRows, name: sourceItem.name })) {
        alert.error('This token name already exist in the target folder');
        return;
      }
      updateAuditToken({
        tokenId: sourceItem.id,
        payload: {
          token: sourceItem.name,
          is_token: true,
          type: targetFolder.name,
        },
      });
    }
  };

  const handleListItemClick = item => {
    if (item.subRows) {
      toggleFolderExpanded(item);
    }
  };

  return (
    <>
      {isLoading && <WaitPlaceholder />}
      {canEdit && (
        <>
          <Menu secondary className={styles.menuContainer}>
            <Menu.Item>
              <Header as="h4">
                {'Audit tokens'}
              </Header>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Button secondary data-cy="add-doc-item-list" onClick={() => startCreatingAuditToken()}>
                  <Icon name="add" />
                  {'Create new token'}
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Divider />
        </>
      )}
      {!canEdit && (
        <div className={styles.containerSearch}>
          <Input
            fluid
            defaultValue={documentNameFilter}
            icon="search"
            iconPosition="left"
            placeholder="Search"
            type="text"
            onChange={e => setDocumentNameFilter(e.target.value)}
          />
        </div>
      )}
      { documentsTree
      && <DropDownTable
        canEdit={canEdit}
        dataCy={DATA_CY}
        defaultItemIcon="tag"
        dragEnd={dragEnd}
        handleListItemClick={handleListItemClick}
        itemsTree={documentsTree}
        openModalForFolder={startCreatingAuditToken}
        startRemovingFolder={startRemovingAuditTokenFolder}
        startRemovingItem={startRemovingToken}
        startRenamingItem={startRenamingToken}
        // eslint-disable-next-line react/jsx-closing-bracket-location
      />
      }

      <Modal open={isModalOpen}>
        <Formik
          enableReinitialize
          initialValues={{
            type: folderSelected,
          }}
          validationSchema={Yup.object({
            token: Yup.mixed().test(
              'test',
              'Required and unique',
              value => fileNameValidation({ name: value, tokens: data }),
            ),
          })}
          onSubmit={({ token, type }) => {
            setModalOpen(false);
            createAuditToken({ payload: {
              token,
              type,
              is_token: true,
            } });
            setFolderSelected(null);
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {'Create new token'}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldWrapper
                    required
                    label="Token"
                    name="token"
                  />
                  <FormFieldWrapper
                    label={`Folder${!folderSelected ? ' (optional)' : ''}`}
                    name="type"
                    readOnly={Boolean(folderSelected)}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => {
                  setFolderSelected(null);
                  setModalOpen(false);
                }}
                >
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Upload'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>

      <GenericModal
        buttons={[
          { label: 'Cancel', func: resetAction },
          { label: 'Accept',
            func: () => removeAuditToken(actionRequired.item.is_token
              ? actionRequired.item.id
              : actionRequired.item.folderId),
          },
        ]}
        open={actionRequired.action === (DocumentsConst.Action.REMOVE || DocumentsConst.Action.REMOVE_FOLDER)}
        onDismiss={resetAction}
      >
        <h4>
          {actionRequired.confirmationMessage}
        </h4>
      </GenericModal>

      <Modal open={actionRequired.action === DocumentsConst.Action.RENAME}>
        <Formik
          initialValues={{
            token: actionRequired?.doc?.text,
          }}
          validationSchema={Yup.object({
            token: Yup.mixed().test(
              'token',
              'Required and not the same',
              value => fileNameValidation({ name: value, tokens: data }),
            ),
          })}
          onSubmit={({ token }) => {
            const { type, is_token } = actionRequired.item;
            const payload = {
              token,
              is_token,
            };
            if (type) {
              payload.type = type.token;
            }
            updateAuditToken({
              tokenId: actionRequired.item.id,
              payload,
            });
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {actionRequired.confirmationMessage}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldWrapper
                    required
                    label="New token name"
                    name="token"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => resetAction()}>
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Accept'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default TaxonomyDetailsAuditTokens;
