import { Auth as AuthAmplify } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { usersAPI } from '../../../api';
import { CognitoUserChallengeNames, MfaMethods } from '../../../constants';
import { clearAuth } from '../../../state/constants/actionCreators';
import Sentry from '../../../utils/sentry';
import { LoadingPlaceholder } from '../../common';
import { Auth } from '../../layout';
import VerifyLoginForm from '../VerifyLogin/VerifyLoginForm/VerifyLoginForm.component';
import LogInForm from './LogInForm/LogInForm.component';

const LogIn = () => {
  const { push } = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [mfaMethod, setMfaMethod] = useState(CognitoUserChallengeNames.NOMFA);
  const [validatingMFA, setValidatingMFA] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const { mutate: userLogin } = useMutation(usersAPI.login, {
    onSuccess: () => push('/'),
  });

  const login = async ({ email, password }) => {
    try {
      const user = await AuthAmplify.signIn(email, password);
      if (user) await setCognitoUser(user);
      Sentry.setUser({ id: user?.username });
      if (user.challengeName === CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA) {
        setMfaMethod(CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA);
      } else if (user.challengeName === CognitoUserChallengeNames.NEW_PASSWORD_REQUIRED) {
        push('/changePassword?firstLogin=true');
      } else {
        userLogin();
      }
    } catch (error) {
      alert.error(`Error on login process: ${error.message}`);
    }
  };

  const verifyLogin = async code => {
    try {
      if (code) {
        setValidatingMFA(true);
        await AuthAmplify.confirmSignIn(cognitoUser, code, CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA);
        await AuthAmplify.setPreferredMFA(cognitoUser, MfaMethods.TOTP);
        setCognitoUser(null);
        userLogin();
      }
    } catch (error) {
      alert.error(`Error validating login: ${error}`);
    } finally {
      setValidatingMFA(false);
    }
  };

  const AuthProps = {
    [CognitoUserChallengeNames.NOMFA]: {
      component: LogInForm,
      title: 'Log In',
      subtitle: () => (
        <>
          <div>
            {'Don\'t you have an account?'}
          </div>
          <Link to="/register">
            {'Register'}
          </Link>
        </>
      ) },
    [CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA]: {
      component: validatingMFA ? LoadingPlaceholder : VerifyLoginForm,
      title: 'Verify Login',
      subtitle: () => (
        <>
          {'Check your authenticator app and insert the code received to verify your login'}
        </>
      ) },
  };

  useEffect(() => {
    dispatch(clearAuth());
  }, [dispatch]);

  return (
    <Auth
      form={AuthProps[mfaMethod].component}
      login={({ email, password }) => login({ email, password })}
      subtitle={AuthProps[mfaMethod].subtitle}
      title={AuthProps[mfaMethod].title}
      verifyLogin={code => verifyLogin(code)}
    />
  );
};

export default LogIn;
