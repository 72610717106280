export default {
  constants: {
    taxonomy: [],
    enums: {},
  },
  auth: {
    userInfo: {},
    userPrompts: [],
  },
  featureFlags: {},
};
