import React from 'react';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { FormFieldDateTimeWrapper, FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSevenExtra.module.scss';

const RegisterAuditorFormStepSevenExtra = () => (
  <FormLayout subtitle="extra" title="Upload images">
    <span className={styles.titleQuestion}>
      {'A scanned copy of your visa'}
    </span>
    <p className={styles.noteQuestion}>
      {'To accept your registration, we require a copy of your visa.'}
    </p>
    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        name="visa"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png, .gif'}
    </span>
    <span className={styles.titleQuestion}>
      {'Specify the expiration date of your visa'}
    </span>
    <Form.Field width={16}>
      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        name="visa_expiration"
        time={false}
      />
    </Form.Field>
  </FormLayout>
);

export default RegisterAuditorFormStepSevenExtra;
