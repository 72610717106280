import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchConfigCatVars = () => axios.get(
  '/ui/configcat',
  { headers: { Authorization: getAuthorization() }, withCredentials: true },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching feature flags config'))
  .catch(logError);
