import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

import { UserRole } from '../../../constants';
import { useUserRole } from '../../../hooks';
import { userInfoSelector } from '../../../state/constants/selectors';
import isMobile from '../../../utils/isMobile';
import Sentry from '../../../utils/sentry';
import { areaManagerTabs, auditorTabs, clientServicesTabs, clientTabs, dataTabs } from './helpers';
import styles from './Navbar.module.scss';
import NavbarMobile from './NavbarMobile.component';

const DATA_CY = 'navbar';

const isActive = (path, location) => location.pathname.startsWith(path);

const Navbar = () => {
  const location = useLocation();
  const { push } = useHistory();
  const role = useUserRole();
  const userInfo = useSelector(userInfoSelector);

  const tabsToRender = (user = null) => ({
    [UserRole.AUDITOR]: auditorTabs,
    [UserRole.DATA]: dataTabs,
    [UserRole.AREA_MANAGER]: areaManagerTabs,
    [UserRole.CLIENT]: clientTabs(user),
    [UserRole.CLIENT_SERVICES]: clientServicesTabs,
  });

  const signOut = async () => {
    Sentry.setUser({});
    await Auth.signOut();
    push('/login');
  };

  if (isMobile && role === UserRole.AUDITOR) {
    return (<NavbarMobile />);
  }

  return (
    <div className={styles.root} data-cy={DATA_CY}>
      <div className={styles.logo} data-cy={`${DATA_CY}-logo`} />
      <Divider fitted />
      <div className={styles.navbar}>
        {tabsToRender(userInfo)[role] !== undefined
          ? tabsToRender(userInfo)[role].map(({ name, path, icon }, index) => (
            name
              ? (
                <Link
                  key={index}
                  data-cy={`${DATA_CY}-menu-item-${name.toLowerCase().replace(/ /gi, '-')}`}
                  to={path}
                >
                  <div
                    className={classnames(
                      styles.tab,
                      { [styles.activeTab]: isActive(path, location) },
                    )}
                  >
                    {isActive(path, location) && <div className={styles.activeBackground} />}
                    <i className={classnames(styles.tabIcon, `${icon}`)} />
                    <div className={styles.tabLabel}>
                      {name}
                    </div>
                  </div>
                </Link>
              )
              : <Divider key={index} fitted className={styles.divider} />
          )) : null}
        <div
          className={styles.tab}
          data-cy={`${DATA_CY}-menu-item-log-out`}
          onClick={signOut}
        >
          <i className={classnames(styles.tabIcon, 'icon-logout')} />
          <div className={styles.tabLabel}>
            {'Log Out'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
