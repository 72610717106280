/* eslint-disable no-console */
import localforage from 'localforage';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Segment } from 'semantic-ui-react';

import { auditingAPI } from '../../../../api';
import { QuestionType } from '../../../../constants';
import { removeAudit } from '../../../../state/audits/actionCreators';
import { finishedAuditsSelector } from '../../../../state/audits/selectors';
import { featureFlagsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';

const getLocalStorageSpace = () => {
  let data = '';
  Object.keys(window.localStorage).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (window.localStorage.hasOwnProperty(key)) {
      data += window.localStorage[key];
      console.log(`${key} = ${((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2)} KB`);
    }
  });

  console.log(data ? `${'\nTotal space used: '}${((data.length * 16) / (8 * 1024)).toFixed(2)} KB` : 'Empty (0 KB)');
};

const AuditBackgroundSender = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const { groupingMatchingRules } = useSelector(featureFlagsSelector);

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
  }, []);

  const allFinishedAuditsSelector = state => finishedAuditsSelector(state);
  const finishedAudits = useSelector(allFinishedAuditsSelector);

  const { mutate: uploadAudit } = useMutation(auditingAPI.complete, {
    onSuccess: async response => {
      alert.info(`Audit ${response.id} submitted`);

      const finishedAudit = finishedAudits.find(({ original }) => original.id === response.id);
      const { answers } = finishedAudit;
      const imageAnswers = answers.filter(answer => (answer.type === QuestionType.IMAGE) && !!answer.value);

      if (imageAnswers) {
        await Promise.all(imageAnswers.map(async answer => {
          await localforage.removeItem(answer.value);
        }));
      }
      dispatch(removeAudit({ id: response.id }));
    },
    onError: async error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error submitting audit: ${error.message}`);
    },
    retry: !isOnline,
  });

  const handleUploadAudit = useCallback(async audit => {
    const formData = new FormData();

    try {
      formData.append('survey_answers', JSON.stringify(audit.answers));

      // add images
      const imageAnswers = audit.answers.filter(answer => answer.type === QuestionType.IMAGE);
      if (imageAnswers) {
        await Promise.all(imageAnswers.map(async answer => {
          const images = await localforage.getItem(answer.value);
          if (images) {
            images.forEach(value => formData.append('file', value, `${answer.id}`));
          }
        }));
      }

      // add location and location timestamp
      if (audit.location) {
        formData.append('audit_start_coordinates', JSON.stringify(audit.location));
        formData.append('audit_geolocation_date', audit.audit_geolocation_date);
      }

      // add extra details
      Object.entries(audit.extraDetails).forEach(([key, value]) => {
        formData.append(key, value);
      });

      uploadAudit({
        version: groupingMatchingRules ? 'v5' : 'v4',
        auditId: audit.original.id,
        auditorId: audit.auditor_id,
        payload: formData,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Audit ${audit.original.id} assigned to auditor ${audit.auditor_id} is failing. Here is the not sent payload: ${JSON.stringify(formData, null, 2)}`);
      console.log(`The current local storage: ${getLocalStorageSpace()}`);
      console.log(`Error: ${JSON.stringify(error)}`);

      alert.error(`Audit ${audit.original.id} is failing. Error: ${JSON.stringify(error)}`);
    }
    // add answers
  }, [uploadAudit, alert]);

  useEffect(() => {
    if (isOnline) {
      Promise.all(finishedAudits.map(async audit => handleUploadAudit(audit)));
    }
  }, [isOnline, finishedAudits, handleUploadAudit]);

  return (
    <Segment>
      <Icon color={isOnline ? 'green' : 'red'} name="cloud" />
      {`You are ${isOnline ? 'online' : 'offline'}`}
    </Segment>
  );
};

export default AuditBackgroundSender;
