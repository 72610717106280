import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Accordion, AccordionContent, AccordionTitle, Button, Card, Form, Icon, Popup } from 'semantic-ui-react';

import { FieldStatus } from '../../../fieldLogic';
import FormFieldCurrencyWrapper from '../FormFieldWrappers/FormFieldCurrencyWrapper.component';
import FormFieldSelectWrapper from '../FormFieldWrappers/FormFieldSelectWrapper.component';
import FormFieldWrapper from '../FormFieldWrappers/FormFieldWrapper.component';
import FormFieldLabel from '../FormFieldWrappers/Template/FormFieldLabel.component';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage.component';
import styles from './ReclaimableExpensesAccordion.module.scss';

const MAX_RECLAIMABLE_EXPENSES = 4;

const countValidReclaimableExpenses = (data = {}) => {
  const reclaimableExpenses = Object.keys(data).filter(key => key.match(/^reclaimable_expenses(_\d+)?$/));
  const validReclaimableExpenses = [];
  reclaimableExpenses.forEach(reclaimableExpense => {
    if (data[reclaimableExpense]) {
      validReclaimableExpenses.push({
        reclaimable_expense: data[reclaimableExpense],
        reclaimable_expense_type: data[`${reclaimableExpense}_type`],
        reclaimable_expense_description: data[`${reclaimableExpense}_description`],
      });
    }
  });
  return validReclaimableExpenses.length;
};

const ReclaimableExpensesAccordion = ({
  data,
  reclaimableExpensesOptions = [],
  onStateHandler,
  labelStyle,
  openAccordions,
  tooltips,
}) => {
  const { values, errors, setValues } = useFormikContext();
  const [reclaimableExpenses, setReclaimableExpenses] = useState(new Array(
    countValidReclaimableExpenses(data) || openAccordions,
  ).fill(null).map(() => ({ active: true })));

  const alert = useAlert();

  let state = FieldStatus.EDITABLE;
  if (onStateHandler) {
    state = onStateHandler('reclaimable_expenses');
  }
  if (state === FieldStatus.HIDDEN) {
    return null;
  }
  const isReadOnly = state === FieldStatus.READONLY;

  const handleAccordionClick = index => {
    setReclaimableExpenses(prevState => {
      prevState[index].active = !prevState[index].active;
      return [...prevState];
    });
  };

  const handleAddClick = () => {
    setReclaimableExpenses(prevState => {
      if (prevState.length === MAX_RECLAIMABLE_EXPENSES) {
        alert.error(`You can only add up to ${MAX_RECLAIMABLE_EXPENSES} reclaimable expenses`);
        return prevState;
      }
      return [...prevState, { active: true }];
    });
  };

  const handleDeleteClick = index => {
    if (reclaimableExpenses.length === 0) return;

    const newValues = { ...values };
    for (let i = index; i < MAX_RECLAIMABLE_EXPENSES; i += 1) {
      const currentFieldName = i !== 0 ? `reclaimable_expenses_${i + 1}` : 'reclaimable_expenses';
      const nextFieldName = `reclaimable_expenses_${i + 2}`;

      if (i === MAX_RECLAIMABLE_EXPENSES - 1) {
        newValues[currentFieldName] = 0;
        newValues[`${currentFieldName}_type`] = null;
        newValues[`${currentFieldName}_description`] = '';
      } else {
        newValues[currentFieldName] = values[nextFieldName];
        newValues[`${currentFieldName}_type`] = values[`${nextFieldName}_type`];
        newValues[`${currentFieldName}_description`] = values[`${nextFieldName}_description`];
      }
    }

    setValues(newValues);

    setReclaimableExpenses(prevState => {
      for (let i = index; i < prevState.length - 1; i += 1) {
        prevState[i] = prevState[i + 1];
      }
      return prevState.slice(0, prevState.length - 1);
    });
  };

  return (
    <>
      <Form.Field className={styles.reclaimableExpensesTitle}>
        <FormFieldLabel
          label={'Reclaimable expenses'}
          labelStyle={labelStyle}
        />
      </Form.Field>
      <Form.Field>
        <Accordion>
          {reclaimableExpenses.map((expense, index) => {
            const fieldName = index !== 0 ? `reclaimable_expenses_${index + 1}` : 'reclaimable_expenses';
            const typeFieldName = `${fieldName}_type`;
            const descriptionFieldName = `${fieldName}_description`;
            const hasErrors = errors[fieldName] || errors[typeFieldName] || errors[descriptionFieldName];

            return (
              <Card
                key={index}
                className={expense?.active ? styles.cardOpened : styles.cardCollapsed}
              >
                <AccordionTitle
                  active={expense?.active}
                >
                  <div className={styles.accordionTitleWrapper}>
                    <div className={styles.accordionTitle} onClick={() => handleAccordionClick(index)}>
                      {`Reclaimable expense ${index + 1}`}
                      <Icon name="dropdown"/>
                      {hasErrors && (
                        <FormikErrorMessage>
                          {'Invalid values'}
                        </FormikErrorMessage>
                      )}
                    </div>
                    {!isReadOnly && (
                      <Icon
                        className={styles.reclaimableExpensesDeleteIcon}
                        name="trash" onClick={() => handleDeleteClick(index)}
                      />
                    )}
                  </div>
                </AccordionTitle>
                <AccordionContent active={expense?.active}>
                  <FormFieldCurrencyWrapper
                    fluid
                    currency={data.order_currency}
                    label="Reclaimable expense"
                    name={fieldName}
                    readOnly={isReadOnly}
                  />

                  <FormFieldSelectWrapper
                    label="Reclaimable expense type"
                    {...(tooltips && { labelTooltip: 'Select the category for your reclaimable expense. There are up to four different boxes you can use, please do not put more than one expense in this box.' })}
                    name={`${fieldName}_type`}
                    options={reclaimableExpensesOptions}
                    readOnly={isReadOnly}
                  />

                  <FormFieldWrapper
                    label="Reclaimable expense description"
                    {...(tooltips && { labelTooltip: 'Refer to the briefing document for what you can claim. If nothing is stated, then the cost of the purchases is already accounted for in the Pay & Purchase Allowance, so you do not need to claim anything here.' })}
                    name={`${fieldName}_description`}
                    readOnly={isReadOnly}
                    type="textarea"
                  />
                </AccordionContent>
              </Card>
            );
          })}
        </Accordion>
        {(!isReadOnly && reclaimableExpenses.length < MAX_RECLAIMABLE_EXPENSES) && (
          <div className={styles.addButtonWrapper}>
            <Popup
              content={'Click to add a new reclaimable expense'}
              position={'top left'}
              trigger={
                <Button
                  className={styles.reclaimableExpensesAddIcon}
                  onClick={handleAddClick}
                >
                Add new
                </Button>
              }
            />
          </div>
        )}
      </Form.Field>
    </>
  );
};

ReclaimableExpensesAccordion.defaultProps = {
  labelStyle: null,
  onStateHandler: null,
  openAccordions: 0,
  tooltips: false,
};

ReclaimableExpensesAccordion.propTypes = {
  data: PropTypes.object.isRequired,
  reclaimableExpensesOptions: PropTypes.array.isRequired,
  labelStyle: PropTypes.string,
  openAccordions: PropTypes.number,
  tooltips: PropTypes.bool,
  onStateHandler: PropTypes.func,
};

export default ReclaimableExpensesAccordion;
