import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

const autometedExportsBasePath = '/ui/automatedExports';

export const fetchAll = () => axios.get(
  autometedExportsBasePath,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching all automated exports'))
  .catch(logError);

export const fetchOne = ({ exportId }) => axios.get(
  `${autometedExportsBasePath}/${exportId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error fetching automated export with id ${exportId}`))
  .catch(logError);

export const create = ({ payload }) => axios.post(
  autometedExportsBasePath,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error creating new automated export'))
  .catch(logError);

export const update = ({ exportId, payload }) => axios.put(
  `${autometedExportsBasePath}/${exportId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating automated export with id ${exportId}`))
  .catch(logError);

export const remove = ({ exportId }) => axios.delete(
  `${autometedExportsBasePath}/${exportId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error deleting automated export with id ${exportId}`))
  .catch(logError);

export const download = async entityId => {
  const { url } = await getBackendConfig();
  window.open(
    `${url}/api/v1/visits/client/export/csv?id=${entityId}`,
  );
};
