import { Field, useFormikContext } from 'formik';
import localforage from 'localforage';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';

import { featureFlagsSelector } from '../../../../../state/constants/selectors';
import { convertImages } from '../../../../../utils/imageConversion';
import DropzoneComponent from '../../../../common/Dropzone/Dropzone.component';
import { getAnswerImages, getThumbnailURL } from '../../helpers';
import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';
import styles from './AuditingImageQuestion.module.scss';

const DATA_CY = 'auditing-image-question';

const AuditingImageQuestion = () => {
  const { imageQuestionsDropzone } = useSelector(featureFlagsSelector);
  const { values: { answer, answer_original_urls } } = useFormikContext();

  const [thumbs, setThumbs] = useState([]);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [, setImageFiles] = useState([]);

  useEffect(() => {
    async function fetchThumbs(currentAnswer, urls) {
      if (Array.isArray(currentAnswer) && urls) {
        setThumbs(urls);
      }
      const t = await getAnswerImages(currentAnswer, urls);
      setThumbs(t || []);
    }
    if (!imageQuestionsDropzone) fetchThumbs(answer, answer_original_urls);
    return () => setThumbs([]);
  }, [answer, answer_original_urls]);

  useEffect(() => {
    async function fetchInitialImages() {
      if (!answer) setImagesUrls([]);
      const images = await localforage.getItem(answer) || [];
      setImageFiles(images);
      const updatedImagesUrls = await Promise.all(images.map(image => getThumbnailURL(image)));
      setImagesUrls(updatedImagesUrls);
    }
    if (imageQuestionsDropzone) fetchInitialImages();
  }, []);

  return (
    <div className={commonStyles.question} data-cy={DATA_CY}>
      <Field name="question">
        {({ field: { value: question } }) => (
          <>
            <AuditingQuestionHeader question={question} />

            <Field name="answer">
              {({
                form: { setFieldValue },
                meta: { error },
              }) => (imageQuestionsDropzone
                ? (
                  <>
                    <DropzoneComponent
                      accept={'image/*'}
                      initialImages={imagesUrls}
                      maxFiles={4}
                      onDropFiles={async files => {
                        setImageFiles(prevState => {
                          const updatedImages = [...prevState, ...files.map(({ file }) => file)];
                          setFieldValue('answer_files', updatedImages);
                          return updatedImages;
                        });
                      }}
                      onRemoveFile={async file => {
                        setImageFiles(prevState => {
                          const updatedImages = prevState.filter((_, i) => i !== file.index);
                          setFieldValue('answer_files', updatedImages);
                          return updatedImages;
                        });
                      }}
                    />

                    {error ? (
                      <div className={commonStyles.error}>
                        {error}
                      </div>
                    ) : null}
                  </>
                )
                : (
                  <>
                    <Dropzone
                      multiple
                      accept="image/*"
                      maxFiles={4}
                      onDrop={async acceptedFiles => {
                        setThumbs(await Promise.all(acceptedFiles.map(file => getThumbnailURL(file))));
                        const compressedFiles = await Promise.all(acceptedFiles.map(convertImages));
                        setFieldValue('answer_files', compressedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: styles.dropZone })}>
                          <input {...getInputProps()} />
                          <span className={styles.imageIcon} />
                          <p>
                            {'Drop your images here, or '}
                            <span>
                              {'browse.'}
                            </span>
                          </p>
                        </div>

                      )}
                    </Dropzone>

                    <div className={styles.thumb}>
                      {thumbs.map((thumb, thumbIndex) => (
                        <div
                          key={thumbIndex}
                          className={styles.thumbContainer}
                        >
                          <Image
                            className={styles.img}
                            size="small"
                            src={thumb}
                          />
                        </div>
                      ))}
                    </div>

                    {error ? (
                      <div className={commonStyles.error}>
                        {error}
                      </div>
                    ) : null}
                  </>
                )
              )}
            </Field>
          </>
        )}
      </Field>
    </div>
  );
};

export default AuditingImageQuestion;
