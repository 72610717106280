import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const updateFormRecognitionRules = ({ clientId, payload }) => axios.put(
  `/ui/clients/${clientId}/form-recognition`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error updating client ${clientId} form recognition rules.`))
  .catch(logError);

export const addReport = ({ clientId, payload }) => axios.post(
  `/ui/clients/${clientId}/report`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error adding report to client ${clientId}.`))
  .catch(logError);
