import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const fetchUserAccount = () => axios
  .get('/ui/users/me', { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching user'))
  .catch(logError);

export const fetchAuditorAccount = () => axios
  .get('/ui/auditors/me', { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching auditor'))
  .catch(logError);

export const editAuditorAccount = ({ payload }) => axios
  .put('/ui/auditors/me', payload, {
    headers: { Authorization: getAuthorization() },
  })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching auditor'))
  .catch(logError);
