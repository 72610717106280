/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import localforage from 'localforage';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { auditingAPI } from '../../../../api';
import { AuditingSteps, QuestionType } from '../../../../constants';
import { removeAudit } from '../../../../state/audits/actionCreators';
import { auditSelector } from '../../../../state/audits/selectors';
import { featureFlagsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import AuditingError from '../AuditingError/AuditingError.component';
import AuditingLoading from '../AuditingLoading/AuditingLoading.component';
import AuditingSuccess from '../AuditingSuccess/AuditingSuccess.component';

const getLocalStorageSpace = () => {
  let data = '';
  Object.keys(window.localStorage).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (window.localStorage.hasOwnProperty(key)) {
      data += window.localStorage[key];
      console.log(`${key} = ${((window.localStorage[key].length * 16) / (8 * 1024)).toFixed(2)} KB`);
    }
  });
  console.log(data ? `${'\nTotal space used: '}${((data.length * 16) / (8 * 1024)).toFixed(2)} KB` : 'Empty (0 KB)');
};

const statusComponent = {
  loading: <AuditingLoading />,
  success: <AuditingSuccess />,
  error: <AuditingError />,
};

const AuditingOnlineSender = ({ data }) => {
  const dispatch = useDispatch();
  const auditSelected = state => auditSelector(state, data.id);
  const auditToSend = useSelector(auditSelected);
  const { groupingMatchingRules } = useSelector(featureFlagsSelector);

  const [status, setStatus] = useState('loading');

  const { mutate: uploadAudit } = useMutation(
    auditingAPI.complete,
    {
      onSuccess: async response => {
        const { answers, extraDetails } = auditToSend;
        const imageAnswers = answers.filter(answer => (answer.type === QuestionType.IMAGE) && !!answer.value);

        if (imageAnswers) {
          await Promise.all(imageAnswers.map(async answer => {
            await localforage.removeItem(answer.value);
          }));
        }

        if (extraDetails.expenses_receipts) {
          await localforage.removeItem(extraDetails.expenses_receipts);
        }
        if (extraDetails.travel_expenses_images) {
          await localforage.removeItem(extraDetails.travel_expenses_images);
        }

        dispatch(removeAudit({ id: response.id }));
        setStatus('success');
      },
      onError: async error => {
        Sentry.captureException(new CustomError(error));
        setStatus('error');
      },
      retry: false,
    },
  );

  const handleUploadAudit = useCallback(async audit => {
    const formData = new FormData();

    try {
      formData.append('survey_answers', JSON.stringify(audit.answers));

      // add images
      const imageAnswers = audit.answers.filter(answer => answer.type === QuestionType.IMAGE);
      if (imageAnswers) {
        await Promise.all(imageAnswers.map(async answer => {
          const images = await localforage.getItem(answer.value);
          if (images) {
            images.forEach(value => formData.append('file', value, `${answer.id}`));
          }
        }));
      }

      // add location and location timestamp
      if (audit.location) {
        formData.append('audit_start_coordinates', JSON.stringify(audit.location));
        formData.append('audit_geolocation_date', audit.audit_geolocation_date);
      }

      const EXPENSES_RECEIPTS_FIELD_NAME = 'expenses_receipts';
      const TRAVEL_IMAGES_EXPENSES_FIELD_NAME = 'travel_expenses_images';

      Object.entries(audit.extraDetails).forEach(([key, value]) => {
        if (![EXPENSES_RECEIPTS_FIELD_NAME, TRAVEL_IMAGES_EXPENSES_FIELD_NAME].includes(key)) {
          formData.append(key, value);
        }
      });

      const expensesReceiptImages = await localforage.getItem(audit.extraDetails.expenses_receipts);
      const travelExpensesImages = await localforage.getItem(audit.extraDetails.travel_expenses_images);

      const expensesReceiptField = [];
      const travelExpensesField = [];
      if (expensesReceiptImages?.length) {
        expensesReceiptImages.forEach((value, i) => {
          const expensesReceiptFileName = `${EXPENSES_RECEIPTS_FIELD_NAME}_${i}`;
          formData.append('file', value, expensesReceiptFileName);
          expensesReceiptField.push(expensesReceiptFileName);
        });
      }

      if (travelExpensesImages?.length) {
        travelExpensesImages.forEach((value, i) => {
          const travelExpensesImagesFileName = `${TRAVEL_IMAGES_EXPENSES_FIELD_NAME}_${i}`;
          formData.append('file', value, travelExpensesImagesFileName);
          travelExpensesImages.push(travelExpensesImagesFileName);
        });
      }

      formData.append(EXPENSES_RECEIPTS_FIELD_NAME, JSON.stringify(expensesReceiptField));
      formData.append(TRAVEL_IMAGES_EXPENSES_FIELD_NAME, JSON.stringify(travelExpensesField));

      uploadAudit({
        version: groupingMatchingRules ? 'v5' : 'v4',
        auditId: audit.original.id,
        auditorId: audit.auditor_id,
        payload: formData,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(`Audit ${audit.original.id} assigned to auditor ${audit.auditor_id} is failing. Here is the not sent payload: ${JSON.stringify(formData, null, 2)}`);
      console.log(`The current local storage: ${getLocalStorageSpace()}`);
      console.log(`Error: ${JSON.stringify(error)}`);
    }
    // add answers
  }, [uploadAudit]);

  useEffect(() => {
    if (auditToSend?.step === AuditingSteps.ONLINE_SENDER) {
      handleUploadAudit(auditToSend);
    }
  }, [auditToSend]);

  return statusComponent[status];
};

AuditingOnlineSender.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AuditingOnlineSender;
