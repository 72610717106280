import { alertsConfig, notificationAlertsConfig } from './configureAlerts';
import configureAmplify from './configureAmplify';
import configureGoogleMaps from './configureGoogleMaps';
import configureMoment from './configureMoment';
import queryConfig from './configureQueries';

const configureSystem = async () => {
  configureMoment();
  await configureAmplify();
  await configureGoogleMaps();
};

export default configureSystem;

export {
  alertsConfig,
  notificationAlertsConfig,
  queryConfig,
};
