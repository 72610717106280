import React from 'react';
import { useSelector } from 'react-redux';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { FormFieldCheckboxWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepTen.module.scss';

const accessToCarOptionsSelector = state => enumOptionsSelector(state, 'access_to_car_type');

const RegisterAuditorFormStepTen = () => {
  const accessToCarOptions = useSelector(accessToCarOptionsSelector);

  return (
    <FormLayout subtitle="3 of 3" title="Extra information">
      <span className={styles.titleQuestion}>
        {'What\'s your number?'}
      </span>
      <FormFieldWrapper
        required
        name="mobile_number"
        placeholder="Mobile number"
      />
      <FormFieldWrapper
        required
        name="home_number"
        placeholder="Home number"
      />
      <span className={styles.titleQuestion}>
        {'Do you have access to car'}
      </span>
      <FormFieldSelectWrapper
        required
        name="access_to_car"
        options={accessToCarOptions}
        placeholder="Select an option"
      />
      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I would like to receive email communication from Serve Legal outside of essential employee information."
        name="email_communication"
      />
      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I would like to continue being part of the Serve Legal community and to be contacted with new types of work once my employment has ended."
        name="servelegal_community"
      />
    </FormLayout>
  );
};

export default RegisterAuditorFormStepTen;
