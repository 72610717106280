import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const validateEmail = user_name => axios.post(
  '/ui/users/validateCognitoEmail',
  { user_name },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error validating email'))
  .catch(logError);

export const resetPassword = entityId => axios.post(
  `/ui/users/${entityId}/reset-password`,
  {},
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error reseting password'))
  .catch(logError);

export const login = () => axios.put(
  '/ui/users/login',
  {},
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating login access date'))
  .catch(logError);
