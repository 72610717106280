import React from 'react';
import { Card, Table } from 'semantic-ui-react';

import styles from './UserSettingsTokens.module.scss';

const DATA_CY = 'user-settings-tokens-table';

const UserSettingsTokens = () => (
  <Card className={styles.containerTable} data-cy={DATA_CY}>
    <Card.Header className={styles.header} textAlign="center">
      {'Tokens'}
    </Card.Header>
    <Card.Content extra className={styles.bodyTable}>
      <Table celled basic="very" size="small">
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Current Date'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[current-date]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Time'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[current-time]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Website Login Address'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[site-url]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'User Email Address'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[user-email]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Temporal Password'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[user-temp-password]'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Card.Content>
  </Card>
);

export default UserSettingsTokens;
