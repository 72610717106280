import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const fetchUserPrompts = () => axios
  .get('/ui/prompts/me', { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching prompts'))
  .catch(logError);

export const storePromptRead = promptId => axios.post(
  `/ui/users/prompts/${promptId}`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error storing prompt as read'))
  .catch(logError);
