import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { handlingResponse, logError } from './utils';

export const getAmplifyConfig = () => axios.get('/ui/config/amplify')
  .then(handlingResponse([StatusCodes.OK], 'Error trying to get Amplify configuration parameters'))
  .catch(logError);

export const getBackendConfig = () => axios.get('/ui/config/backend')
  .then(handlingResponse([StatusCodes.OK], 'Error trying to get back-end configuration parameters'))
  .catch(logError);
