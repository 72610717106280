import PropTypes from 'prop-types';
import React from 'react';

import { automatedExportsAPI } from '../../../../../api';
import { getBackendConfig } from '../../../../../api/configAPI';
import { SLTable, SLTableCellFormatter } from '../../../../common';
import styles from './ClientsAutomatedExportsTable.module.scss';

const DATA_CY = 'clients-automated-exports-table';

const columns = [
  {
    accessor: 'name',
    label: 'Title',
    Cell: SLTableCellFormatter({
      linkFormat: '/clients/automated/#id',
    }),
    sortable: false,
  },
  {
    accessor: 'client_name',
    label: 'Client',
    Cell: SLTableCellFormatter(),
    sortable: false,
  },
  {
    accessor: 'username',
    label: 'Username',
    Cell: SLTableCellFormatter(),
    sortable: false,
  },
  {
    accessor: 'url',
    label: 'Premise',
    /* eslint-disable react/prop-types */
    Cell: ({ cell, userData }) => (
      userData.backendUrl
        ? (
          <span
            className={styles.link}
            onClick={() => automatedExportsAPI.download(cell.row.original.id)}
          >
            {`${userData.backendUrl}/api/v1/visits/client/export/csv?id=${cell.row.original.id}`}
          </span>) : <span />
    ),
    /* eslint-enable react/prop-types */
    sortable: false,
  },
  {
    accessor: 'upload_date',
    label: 'Generated',
    Cell: SLTableCellFormatter(),
    sortable: false,
  },
];

const ClientsAutomatedExportsTable = ({ data }) => {
  const [backendUrl, setBackendUrl] = React.useState(null);
  React.useEffect(() => {
    const getCloudUrl = async () => {
      const { url } = await getBackendConfig();
      setBackendUrl(url);
    };
    getCloudUrl();
  }, []);

  return (
    <SLTable
      columns={columns}
      data={data}
      dataCy={DATA_CY}
      userData={{ backendUrl }}
    />
  );
};

ClientsAutomatedExportsTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ClientsAutomatedExportsTable;
