import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const create = async ({ entity, payload }) => {
  const { url } = await getBackendConfig();

  return axios.post(
    `${url}/api/v1/${entity}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity}`))
    .catch(logError);
};

export const upload = async ({ entity, payload }) => {
  const { url } = await getBackendConfig();

  return axios.post(
    `${url}/api/v1/${entity}/upload`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], `Error uploading ${entity}`))
    .catch(logError);
};

export const update = async ({ entity, entityId, payload }) => {
  const { url } = await getBackendConfig();

  return axios.put(
    `${url}/api/v1/${entity}/${entityId}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], `Error updating ${entity} with id ${entityId}`))
    .catch(logError);
};

export const uploadTaxonomyDocument = async ({ entityId, payload }) => {
  const { url } = await getBackendConfig();
  return axios.put(
    `${url}/api/v1/taxonomy/${entityId}/upload`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], 'Error uploading document'))
    .catch(logError);
};
