import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Divider, Form, Grid, Loader } from 'semantic-ui-react';

import { auditingAPI } from '../../../../../../../../api';
import { AuditStatus, UserRole } from '../../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../../context';
import { featureFlagsSelector } from '../../../../../../../../state/constants/selectors';
import CustomError from '../../../../../../../../utils/customError';
import Sentry from '../../../../../../../../utils/sentry';
import { FullScreenImageModal, LoadingPlaceholder } from '../../../../../../../common';
import { RoleChecker } from '../../../../../../../layout';
import SurveyQuestionConfigHeader from '../SurveyQuestionConfigHeader/SurveyQuestionConfigHeader.component';
import styles from '../SurveySelectorQuestion/SurveySelectorQuestion.module.scss';
import { imageTypeOptions } from './helpers';
import SurveyFileQuestion from './SurveyFileQuestion.component';

const DATA_CY = 'survey-file-question';

const SurveyMultipleFileQuestion = ({
  answer,
  formRecognitionValues,
  auditScreen,
  index,
  onChange,
  status,
  surveyVisibility,
}) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const { entityId } = useParams();
  const alert = useAlert();
  const { imageQuestionsDropzone } = useSelector(featureFlagsSelector);

  const handleOnChange = (i = 0) => newValue => {
    if (answer === null) {
      return onChange([newValue], i, newValue);
    }

    // FIXME: we shouldn't modify an input parameter
    answer[i] = newValue;
    return onChange(answer, i, newValue);
  };

  const handleOnChangeDropzone = (newValue, i) => {
    if (answer === null) {
      return onChange(Array.isArray(newValue) ? newValue : [newValue], i, newValue);
    }

    const isDeletion = !newValue;
    if (isDeletion) {
      answer[i] = newValue;
      return onChange(answer, i, newValue);
    }
    // eslint-disable-next-line no-param-reassign
    answer = [...answer, ...newValue];
    return onChange(answer, i, newValue);
  };

  const { mutate, isLoading } = useMutation(auditingAPI.formRecognizer, {
    onSuccess: () => {
      alert.success('Detection process is progress. Refresh the page in a few seconds');
    },

    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error retrying detection: ${error.message}`);
    },
  });

  const updateFormRecognizer = ({ auditId, questions }) => {
    mutate({
      auditId, questions, action: 'detection',
    });
  };

  const _generateLabel = ({ name, ruleIndex }) => `${name}.${ruleIndex}.label`;

  function _getDataCy({ ruleIndex }) {
    return `${DATA_CY}-${index}-options-${ruleIndex}-label-input`;
  }

  function _getFieldName({
    name,
    ruleIndex,
  }) {
    return `${name}.${ruleIndex}.value`;
  }

  function _getDataCyValue({ ruleIndex }) {
    return `${DATA_CY}-${index}-rules-${ruleIndex}-value-input`;
  }

  const retryButton = (auditId, question) => {
    const itHasImages = !!answer?.filter(image => image !== null).length;
    const isContent = question?.config?.type === 'content';

    return (itHasImages && isContent) && (
      <Button
        secondary
        size="mini"
        onClick={() => updateFormRecognizer({ auditId, questions: [question] })}
      >
        Retry detection
      </Button>
    );
  };

  const displayRetryButton = (auditStatus, auditId, question) => {
    const renderRoleChecker = roles => (
      <RoleChecker allowedRoles={roles}>
        {retryButton(auditId, question)}
      </RoleChecker>
    );
    const statusMapper = {
      [AuditStatus.SUBMITTED]: () => renderRoleChecker([
        UserRole.AREA_MANAGER,
        UserRole.DATA,
        UserRole.CLIENT_SERVICES,
      ]),
      [AuditStatus.APPROVING_QUERY]: () => renderRoleChecker([
        UserRole.AREA_MANAGER,
        UserRole.DATA, UserRole.CLIENT_SERVICES,
      ]),
      [AuditStatus.CLIENT_QUERY]: () => renderRoleChecker([UserRole.DATA, UserRole.CLIENT_SERVICES]),
      [AuditStatus.APPROVED]: () => renderRoleChecker([UserRole.DATA]),
      default: () => null,
    };
    return statusMapper[auditStatus] ? statusMapper[auditStatus]() : statusMapper.default;
  };

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (selectedQuestionIndex === index ? (
        <>
          <SurveyQuestionConfigHeader index={index} />
          <Divider />
          <Grid.Row>
            {'Image Type'}
            <Field name={`questions.${index}.config.type`}>
              {({
                field: {
                  name: resultName,
                  value: resultValue,
                },
                form: {
                  errors,
                  setFieldValue,
                },
              }) => (
                <Form.Select
                  data-cy={`${DATA_CY}-image-type`}
                  error={errors || null}
                  id={resultName}
                  options={imageTypeOptions}
                  value={resultValue}
                  onChange={(_e, { value }) => setFieldValue(resultName, value)}
                />
              )}
            </Field>

          </Grid.Row>
          <Divider />

          <Grid.Row>
            {'Form Recognition Rules'}
            <FieldArray name={`questions.${index}.config.rules`}>
              {({
                name = '',
                push,
                remove,
              }) => (
                <Field name={name}>
                  {({
                    field: { value: rules } = [],
                    form: { handleChange },
                  }) => (
                    <Grid className={styles.grid} columns={2} verticalAlign="middle">
                      <Grid.Row>
                        <Grid.Column width={6}>
                          {'Rule Name'}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          {'Rule Value'}
                        </Grid.Column>

                      </Grid.Row>
                      {rules ? rules?.map((_rule, ruleIndex) => (
                        <Grid.Row key={ruleIndex}>
                          <Grid.Column width={6}>
                            <Field name={_generateLabel({ name, ruleIndex })}>
                              {({
                                field: {
                                  name: fieldName,
                                  value: fieldValue,
                                } = {},
                                meta: { error },
                              }) => (
                                <Form.Input
                                  data-cy={_getDataCy(ruleIndex)}
                                  error={error}
                                  id={fieldName}
                                  value={fieldValue}
                                  onChange={handleChange}
                                />
                              )}
                            </Field>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <Field
                              name={_getFieldName({
                                name,
                                ruleIndex,
                              })}
                            >
                              {({
                                field: {
                                  name: fieldName,
                                  value: fieldValue,
                                },
                                meta: { error },
                              }) => (
                                <Form.Input
                                  data-cy={_getDataCyValue({ ruleIndex })}
                                  error={error}
                                  id={fieldName}
                                  value={fieldValue}
                                  onChange={handleChange}
                                />
                              )}
                            </Field>
                          </Grid.Column>

                          <Grid.Column width={2}>
                            {rules?.length > 0 ? (
                              <span
                                className={styles.delete}
                                onClick={() => remove(ruleIndex)}
                              />) : null}
                          </Grid.Column>
                        </Grid.Row>
                      )) : null}

                      < Grid.Row >
                        <Grid.Column width={2}>
                          <span
                            className={styles.add}
                            onClick={() => push({
                              label: '',
                              value: '',
                            })}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} />
                        <Grid.Column width={4} />

                      </Grid.Row>
                    </Grid>
                  )}
                </Field>
              )}
            </FieldArray>
          </Grid.Row>
        </>
      ) : (

        isLoading
          ? (<>
            <Dimmer active inverted >
              <Loader size="large">
                {'Detection is in progress'}
              </Loader>
            </Dimmer>
            <LoadingPlaceholder />
          </>)
          : (<>
            <Field name={`questions.${index}`}>
              {({ field: { value: question } }) => (
                <Form.Field required={question.required}>
                  <label data-cy={`${DATA_CY}-${index}-title`}>
                    {question.title}
                  </label>

                  <SurveyFileQuestion
                    answers={answer}
                    auditScreen={auditScreen}
                    formRecognitionValue={formRecognitionValues}
                    index={index}
                    question={question}
                    setModalImgSrc={setModalImgSrc}
                    surveyVisibility={surveyVisibility}
                    onChange={imageQuestionsDropzone ? handleOnChangeDropzone : handleOnChange()}
                  />
                  {displayRetryButton(status, entityId, question)}

                  <span data-cy={`${DATA_CY}-${index}-help`}>
                    {question.help}
                  </span>
                  {
                    [AuditStatus.SUBMITTED, AuditStatus.APPROVING_QUERY].includes(status)
                      ? (
                        <RoleChecker
                          allowedRoles={[
                            UserRole.DATA,
                            UserRole.CLIENT_SERVICES,
                            UserRole.AREA_MANAGER,
                          ]}
                        >
                          <p data-cy={`${DATA_CY}-${index}-approving-information`}>
                            {question.approving_information}
                          </p>
                        </RoleChecker>
                      ) : null}
                </Form.Field>
              )}
            </Field>
            <FullScreenImageModal
              imgSrc={modalImgSrc}
              onClose={() => setModalImgSrc(null)}
            />
          </>)))
      }
    </SurveyQuestionsContext.Consumer >
  );
};

SurveyMultipleFileQuestion.defaultProps = {
  answer: null,
  auditScreen: null,
  formRecognitionValues: null,
  onChange: null,
  status: null,
  surveyVisibility: null,
};

SurveyMultipleFileQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  answer: PropTypes.any,
  auditScreen: PropTypes.bool,
  formRecognitionValues: PropTypes.object,
  status: PropTypes.string,
  surveyVisibility: PropTypes.string,
  onChange: PropTypes.func,

};

export default SurveyMultipleFileQuestion;
