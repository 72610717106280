import { Field } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import { DateTimeFormat, QuestionType } from '../../../../../constants';
import { featureFlagsSelector } from '../../../../../state/constants/selectors';
import DatePicker from '../../../../common/DatePicker/DatePicker.component';
import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';
import styles from './AuditingDateTimeQuestion.module.scss';

const DATA_CY = 'auditing-date-time-question';

const validate = question => value => {
  let error;

  let currentSchema;
  if (question.type !== QuestionType.INTERVAL) {
    currentSchema = question.required
      ? Yup.date().typeError('Invalid date/time')
      : Yup.date().typeError('Invalid date/time').nullable();
  } else {
    currentSchema = question.required
      ? Yup.object().shape({
        end: Yup.date().typeError('Invalid end time'),
        start: Yup.date().typeError('Invalid start time'),
      })
      : Yup.object().shape({
        end: Yup.date().typeError('Invalid end time').nullable(),
        start: Yup.date().typeError('Invalid start time').nullable(),
      });
  }

  try {
    currentSchema.validateSync(value);
  } catch (err) {
    error = err.message;
  }

  return error;
};

const AuditingDateTimeQuestion = () => {
  const { newDateTimePicker } = useSelector(featureFlagsSelector);

  const generateTimeComponent = (question, answer, setFieldValue, answerName) => {
    const timeComponents = {
      [QuestionType.INTERVAL]: (
        <Form.Group className={styles.intervalWrapper}>
          <DatePicker
            type={QuestionType.TIME}
            value={answer?.start ? new Date(answer.start) : null}
            onChange={value => setFieldValue(`${answerName}.start`, value)}
          />
          <DatePicker
            type={QuestionType.TIME}
            value={answer?.end ? new Date(answer.end) : null}
            onChange={value => setFieldValue(`${answerName}.end`, value)}
          />
        </Form.Group>
      ),
      [QuestionType.TIME]: (
        <div className={styles.pickerWrapper}>
          <DatePicker
            type={QuestionType.TIME}
            value={answer ? new Date(answer) : null}
            onChange={value => setFieldValue(answerName, value)}
          />
        </div>
      ),
      [QuestionType.DATETIME]: (
        <div className={styles.pickerWrapper}>
          <DatePicker
            type={QuestionType.DATETIME}
            value={answer ? new Date(answer) : null}
            onChange={value => setFieldValue(answerName, value)}
          />
        </div>
      ),
      [QuestionType.DATE]: (
        <>
          <div className={styles.pickerWrapper}>
            <DatePicker
              type={QuestionType.DATE}
              value={answer ? new Date(answer) : null}
              onChange={value => setFieldValue(answerName, value)}
            />
          </div>
        </>
      ),
    };

    return timeComponents[question.type];
  };

  return (<div className={commonStyles.question} data-cy={DATA_CY}>
    <Field name="question">
      {({ field: { value: question } }) => (
        <>
          <AuditingQuestionHeader question={question} />

          <Field name="answer" validate={validate(question)}>
            {({
              field: { value: answer, name: answerName },
              form: { setFieldValue },
              meta: { error },
            }) => {
              if (newDateTimePicker) {
                return generateTimeComponent(question, answer, setFieldValue, answerName);
              }
              if (question.type === QuestionType.INTERVAL) {
                return (
                  <>
                    <Form.Group className={styles.interval}>
                      <DateTimePicker
                        date={false}
                        format={DateTimeFormat.HOUR_MINUTES_24H}
                        step={5}
                        time={true}
                        timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
                        value={answer?.start ? new Date(answer.start) : null}
                        onChange={value => setFieldValue(`${answerName}.start`, value)}
                      />
                      <DateTimePicker
                        date={false}
                        format={DateTimeFormat.HOUR_MINUTES_24H}
                        step={5}
                        time={true}
                        timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
                        value={answer?.end ? new Date(answer.end) : null}
                        onChange={value => setFieldValue(`${answerName}.end`, value)}
                      />
                    </Form.Group>
                    {error ? (
                      <span className={commonStyles.error}>
                        {error}
                      </span>
                    ) : null}
                  </>
                );
              }

              return (
                <>
                  <DateTimePicker
                    className={styles.calendarInput}
                    date={question.type === QuestionType.DATETIME || question.type === QuestionType.DATE}
                    format={question.type === QuestionType.DATETIME
                      ? DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H
                      : question.type === QuestionType.DATE
                        ? DateTimeFormat.DAY_MONTH_YEAR
                        : DateTimeFormat.HOUR_MINUTES_24H
                    }
                    step={5}
                    time={question.type === QuestionType.DATETIME || question.type === QuestionType.TIME}
                    timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
                    value={answer ? new Date(answer) : null}
                    onChange={value => setFieldValue(answerName, value)}
                  />
                  {error ? (
                    <span className={commonStyles.error}>
                      {error}
                    </span>
                  ) : null}
                </>
              );
            }}
          </Field>
        </>
      )}
    </Field>

  </div>);
};

export default AuditingDateTimeQuestion;
