import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { auditorsAPI } from '../../../../api';
import { FieldStatus, getAuditorActionStatus } from '../../../../fieldLogic';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import { SLHeader } from '../../../common';
import styles from './AuditorDetailsHeader.module.scss';

const DATA_CY = 'auditor-details-header';

const auditorStatusOptionsSelector = state => enumOptionsSelector(state, 'auditor_status_type');

const AuditorDetailsHeader = ({ data, refetch, onCancel, onEdit }) => {
  const { entityId } = useParams();
  const alert = useAlert();
  const role = useUserRole();

  const auditorStatusOptions = useSelector(auditorStatusOptionsSelector);

  const { mutate } = useMutation(auditorsAPI.updateStatus, {
    onSuccess: () => {
      alert.success('Auditor status updated');
      refetch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error updating auditor's state: ${error.message}`);
    },
  });

  const updateStatus = status => {
    mutate({
      entityId,
      status,
    });
  };

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={`${data?.internal_id || 'Auditor'} profile`}
    >
      {auditorStatusOptions.map(status => (
        getAuditorActionStatus(role, data.auditor_status, `action_${status.value}`) !== FieldStatus.HIDDEN ? (
          <Button
            key={status.value}
            secondary
            onClick={() => updateStatus(status.value)}
          >
            {status.text}
          </Button>
        ) : null))}

      <Button
        className={styles.edit}
        data-cy={`${DATA_CY}-edit-button`}
        onClick={onEdit}
      >
        Edit
      </Button>
      <Button
        data-cy={`${DATA_CY}-back-button`}
        onClick={onCancel}
      >
        Back
      </Button>
    </SLHeader>
  );
};

AuditorDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AuditorDetailsHeader;
